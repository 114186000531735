/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Card, Button, Checkbox, Form, Input } from 'antd';
import { IconFont } from '../../component/icon-font';
import './home.scss';
import koreaairlogo from '../../component/img/koreaair_logo.png'
import slide1 from '../../component/img/slideshowone.jpg'
import slide2 from '../../component/img/slideshowtwo.jpg'
import slide3 from '../../component/img/slideshowthree.jpg'
import koreaFlag from '../../component/img/korea.png'
import { UsergroupAddOutlined, UserAddOutlined, GoogleOutlined, FacebookOutlined, WindowsOutlined, AndroidOutlined } from '@ant-design/icons';

const { Meta } = Card;


interface HomeProps extends RouteComponentProps {
  status: string;
  onLeaveOrJoinSession: () => void;
}

const slides = [slide1, slide2, slide3];
const slideDuration = 5000; // 5초

const Home: React.FunctionComponent<HomeProps> = (props) => {
  const { history, status, onLeaveOrJoinSession } = props;
  const onCardClick = (type: string) => {
    history.push(`/${type}${location.search}`);
    console.log(status)
  };
  //login form
  const onFinish = (values: any) => {
    console.log('Success:', values);
    // 세션 스토리지에 값 저장
    sessionStorage.setItem('name', values.username);
    history.push(`/chat?name=${sessionStorage.getItem('name')}`)
    window.location.reload()
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    alert('Email 또는 Password를 확인해주세요.')
  };



  // const featureList = [
  // {
  //   key: 'video',
  //   icon: 'icon-meeting',
  //   title: 'Audio, video and share',
  //   description: 'Gallery Layout, Start/Stop Audio, Mute/Unmute, Start/Stop Video, Start/Stop Screen Share'
  // },
  // {
  //   key: 'chat',
  //   icon: 'icon-chat',
  //   title: 'Session chat',
  //   description: 'Session Chat, Chat Priviledge'
  // },
  // {
  //   key: 'command',
  //   icon: 'icon-chat',
  //   title: 'Command Channel chat',
  //   description: 'Session Command Channel chat'
  // },
  // {
  //   key: 'Subsession',
  //   icon: 'icon-group',
  //   title: 'Subsession',
  //   description: 'Open/Close Subsession, Assign/Move Participants into Subsession, Join/Leave Subsession'
  // },
  // {
  //   key: 'preview',
  //   icon: 'icon-meeting',
  //   title: 'Local Preview',
  //   description: 'Audio and Video preview'
  // }
  // ];
  let actionText;
  if (status === 'connected') {
    actionText = 'Leave';
  } else if (status === 'closed') {
    actionText = 'Join';
  }
  const handleDownload = (key: string) => {
    let downloadLink = '';
    let link = document.createElement('a');
    if (key === 'windows') {
      downloadLink = 'https://meeting.etech.tech/KoreanAir-Conference-1.5.0.exe'; // 파일의 경로를 지정해주세요.
      link.href = downloadLink;
      link.download = 'KoreanAir-Conference-1.5.0.exe'; // 다운로드할 파일의 이름을 지정해주세요.
      link.click();
    }
    else if (key === 'android') {
      downloadLink = 'https://meeting.etech.tech/korean-air-meet_0.0.0_x64_en-US.msi'; // 파일의 경로를 지정해주세요.
      link.href = downloadLink;
      link.download = 'korean-air-meet_0.0.0_x64_en-US.msi'; // 다운로드할 파일의 이름을 지정해주세요.
      link.click();

    }
  }
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    // Function to handle the slideshow transition
    const transitionSlides = () => {
      setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
    };

    // Start the slideshow timer when the component mounts
    const slideshowTimer = setInterval(transitionSlides, slideDuration);

    // Clear the slideshow timer when the component unmounts
    return () => clearInterval(slideshowTimer);
  }, []);

  return (
    <div className='flex'>

      <div className='log-col entry_box'>
        <div className='card'>
          {/* 로고 */}
          <div className='logo'>
            <a href="/" >
              <img src={koreaairlogo} alt="logo" />
            </a>
          </div>

          {/* 로그인 */}
          <div className='card-body'>
            <div style={{ padding: "20px 30px 0", flex: "2" }}>
              {/* <h3 className='login-title'>로그인</h3> */}
              <div>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  style={{ maxWidth: 600 }}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="이메일/사용자 이름"
                    name="username"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                    className='login-user-wrap'
                  >
                    <Input placeholder="Enter email" />
                  </Form.Item>

                  <Form.Item
                    label="비밀번호"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                    className='login-pw-wrap'
                  >
                    <Input.Password placeholder="Enter password" />
                  </Form.Item>

                  <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }} style={{ textAlign: "left" }}>
                    <Checkbox style={{ margin: '10px 0', color: "#808080", fontWeight: "600" }}>아이디 기억하기</Checkbox>
                  </Form.Item>

                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" className='loginbtn'>
                      로그인
                    </Button>
                  </Form.Item>
                </Form>
              </div>



              <div>
                {/* <p style={{marginBottom:"10px",fontSize:"15px"}}>
                Sign in with
              </p>
              
              <div>
                <FacebookOutlined className='facebook'/>
                <GoogleOutlined className='google'/>
              </div> */}

                <p style={{ marginTop: "20px", color: "#E91E63", fontWeight: "600", fontSize: "15px" }}>
                  <IconFont type="rock" /> 비밀번호를 잊으셨나요?
                </p>
                {/* <p style={{marginTop:"10px"}}>Don't have an account ?
                <a href="#" style={{color:"#1890ff",marginLeft:"7px"}}>Signup now</a>
              </p> */}
              </div>
            </div>
          </div>

          {/* 개인정보 정책 */}
          <div className='login-bottom'>
            <div className='login-bottom-text'>
              <p>개인 정보 정책 - 서비스 약관</p>
              <p style={{ fontWeight: "600" }}>© 1997-2023 KOREAN AIR</p>
            </div>
            <div className='login-lg'>
              <img src={koreaFlag} alt="" className='login-flag' />
              한국어
            </div>
          </div>
        </div>
      </div>

      {/* slide image */}
      <div className="col-md-7 col-lg-8 col-xl-9 d-none d-md-flex background">
        <div className="preloader" style={{ display: "none" }}>
          <div>
            <div className="ripple-background">
              <div className="circle xxlarge shade1"></div>
              <div className="circle xlarge shade2"></div>
              <div className="circle large shade3"></div>
              <div className="circle mediun shade4"></div>
              <div className="circle small shade5"></div>
            </div>
          </div>
        </div>

        <div className="slideshow">
          <ul>
            {slides.map((slide, index) => (
              <li
                key={index}
                className={index === currentSlideIndex ? "active" : ""}
              >
                <img src={slide} alt="slideimage" />
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* 네비게이션 */}
      <div className="nav">
        <div className='home-nav'>
          {/*</div><div onClick={() => onCardClick('video')}>*/}
          <div>
            <IconFont type="icon-meeting" style={{ fontSize: "32px", padding: "0 6px", cursor: "pointer", color: "#fff" }} />
          </div>
          {/*</div><div onClick={() => onCardClick('chat')}>*/}
          <div>
            <IconFont type="icon-chat" style={{ fontSize: "21px", padding: "0 6px", cursor: "pointer", color: "#fff" }} />
          </div>
          <UserAddOutlined className='emogi_nav' />
          {/*</div><div onClick={() => onCardClick('group')}>*/}
          <div>
            <UsergroupAddOutlined className='emogi_nav pt-30' />
          </div>
          <div>
            <WindowsOutlined onClick={() => handleDownload('windows')} style={{ fontSize: "23px", padding: "0 6px", cursor: "pointer", color: "#fff" }} />
          </div>
          <div>
            <AndroidOutlined onClick={() => handleDownload('android')} style={{ fontSize: "24px", padding: "0 6px", cursor: "pointer", color: "#fff" }} />
          </div>

          {/* {actionText && (
                <Button type="link" className="navleave" onClick={onLeaveOrJoinSession}>
                  {actionText}
                </Button>
              )} */}
        </div>
      </div>

    </div>

  );
};
export default Home;