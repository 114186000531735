import React, { useState, useEffect } from "react";
import InputEmoji from "react-input-emoji";
import { CaretRightOutlined, SettingOutlined, SmileOutlined } from '@ant-design/icons';

export default function EmojiPicker({onEnterData, chgChatData, chatData}) {
  const [text, setText] = useState("");
  useEffect(() => {
    // 값이 변경될 때마다 실행되는 로직
    console.log('값이 변경되었습니다:', chatData);
    setText(chatData)
  }, [chatData]);


  function setChangeText(text) {
    console.log("enter", text);
    chgChatData(text);
  }


  function handleOnEnter(text) {
    onEnterData();
  }

  return (
    <InputEmoji
      icon={SmileOutlined}
      value={text}
      onChange={setChangeText}
      cleanOnEnter
      onEnter={handleOnEnter}
      placeholder="Type a message"
    />
  );
}