import React, { useCallback, useContext, useEffect, useState, useRef, ChangeEvent } from 'react';
import { RouteComponentProps, useLocation, Link, useHistory, } from 'react-router-dom';
import produce from 'immer';
import type { Moment } from 'moment';
import moment from 'moment';
import { Input, Tree } from 'antd';
import { ChatPrivilege } from '@zoom/videosdk';
import ZoomContext from '../../context/zoom-context';
import { ChatReceiver, ChatRecord } from './chat-types';
import { useParticipantsChange } from './hooks/useParticipantsChange';
import ChatContext from '../../context/chat-context';
import ChatMessageItem from './component/chat-message-item';
import ChatReceiverContainer from './component/chat-receiver';
import { SendOutlined, SmileOutlined, PlusOutlined, SettingOutlined, UserAddOutlined, WhatsAppOutlined, FileDoneOutlined, UsergroupAddOutlined, RightOutlined, LeftOutlined, CloseOutlined, BellOutlined, PrinterFilled, InfoCircleOutlined, DownloadOutlined, LogoutOutlined, TeamOutlined, DownOutlined } from '@ant-design/icons';
import logoImage from '../../component/svgs/circlelogo.png';
import { IconFont } from '../../component/icon-font';
import Object2 from '../../../src/component/img/object-2.jpg'
import Object4 from '../../../src/component/img/object-4.jpg'
import Object5 from '../../../src/component/img/object-5.jpg'
import Object8 from '../../../src/component/img/object-8.jpg'
import { PaperClipOutlined, LoadingOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import type { TabsProps, TreeProps } from 'antd';
import { Button, Modal, Spin, Form, Radio, Space, Select, Drawer } from 'antd';
import axios from 'axios';
import Preview from '../preview/preview'


import { useMount } from '../../hooks';
import './chat.scss';


// 채팅 이모지 기능 추가 컴포넌트

import EmojiPicker from '../../component/EmojiPicker';

//모달창 관련 import
import Group from '../video/components/group';
import Alarm from '../video/components/alarm';
import Contact from '../video/components/contact';
import File from '../video/components/file';
import Call from '../video/components/call';
import Chatting from '../video/components/chatting';
import classNames from 'classnames';
import CallModal from '../../component/callmodal'

// 데이트 픽커
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ContactModules from '../video/components/contactModules';
import CallModules from '../video/components/callModules';

interface HomeProps extends RouteComponentProps {
  //subsession에서 에러안나도록 ?처리
  status?: string;
  onLeaveOrJoinSession?: () => void;
}
interface meetingList {
  sn: number; // 300004,
  meetingTitle: string; // "줌개발관련 미팅",
  meetingType: string; // "V",
  meetingDwUrl: string; // "http://aaa.bbb.com",
  meetingStart: string; // "2023-07-08 03:42:49",
  meetingEnd: string; // "2023-07-08 04:42:52",
  useAt: string; // "Y"

}


interface UserData {
  user_id: number
  full_name: string
  department_name: string
  // 여기까지 신규로 추가한 데이터
  userName: string
  companyId: number
  companyName: string
  partId: number
  partName: string
  teamId: number
  teamName: string
  tenantId: 2
  tenantName: string
  tenantDomain: string
  tenantType: string
  email: string
  phone: string
  description: string
  useAt: string
  userGroup: string
  userLevel: string
  userImage: string
  frstRegistPnttm: string
  frstRegisterId: number
  lastUpdtPnttm: string
  lastUpdusrId: number
  totalSearch: string
  //  다른 필드들의 타입도 여기에 추가
}

const { TextArea } = Input;
const ChatContainer: React.FunctionComponent<RouteComponentProps & HomeProps> = (props) => {
  const onChangeTime = (time: Moment, timeString: string) => {
    console.log(time, timeString);
  };
  const { history, status, onLeaveOrJoinSession } = props;
  // info 페이지 보여주기, 숨기기
  const [infovisible, setinfovisible] = useState(true)
  const [leftmodalvisible, setleftmodalvisible] = useState('Chatting')
  const [currentUserinfo, setcurrentUserinfo] = useState('')
  // 파일모달 보여주기, 숨기기
  const [filevisible, setfilevisible] = useState(false)
  // 검색 모달 보여주기, 숨기기
  const [searchmodalVisible, setsearchmodalVisible]=useState(false)
  // side_icon메뉴 선택 저장변수
  const [selectmenu, setselectmenu] = useState('')
  // 컴포넌트출력함수
  const zmClient = useContext(ZoomContext);
  const chatClient = useContext(ChatContext);
  const [chatRecords, setChatRecords] = useState<ChatRecord[]>([]);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [chatReceivers, setChatReceivers] = useState<ChatReceiver[]>([]);
  const [chatPrivilege, setChatPrivilege] = useState<ChatPrivilege>(ChatPrivilege.All);
  const [chatUser, setChatUser] = useState<ChatReceiver | null>(null);
  const [isHost, setIsHost] = useState<boolean>(false);
  const [isManager, setIsManager] = useState<boolean>(false);
  const [chatDraft, setChatDraft] = useState<string>('');
  const chatWrapRef = useRef<HTMLDivElement | null>(null);
  // 모달
  const [callModalOpen, setcallModalOpen] = useState(false);
  const [aaa, testaaa] = useState(false);
  const [prevwModalOpen, previewModalOpen] = useState(false);
  const [TargetTopic, setTargetTopic] = useState('')

  //
  const [siderbar, setsiderbar] = useState(true)
  // 로딩바 제어
  const [isLoading, setisLoading] = useState(true)
  const [open, setOpenTowLeft] = useState(false);
  const [childrenDrawer, setChildrenDrawer] = useState(false);

  // 등록 후 리로드
  const [insertCheck, setInsertCheck] = useState('start');

  // 데이트 픽커
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const showDrawer = () => {
    setOpenTowLeft(true);
  };
  const onClose = () => {
    setOpenTowLeft(false);
  };

  const showChildrenDrawer = () => {
    getOrgList();
    setChildrenDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  //three-drawer
  const [openGroup, setGroupOpen] = useState(false);

  const showGroupDrawer = () => {
    setGroupOpen(true);
  };

  const onGroupClose = () => {
    setGroupOpen(false);
  };
  //select box
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  // 미팅리스트 불러오기
  const [meetingList, setmeetingList] = useState<meetingList[]>();
  useEffect(() => {
    if (insertCheck === 'start') {
      getuserList();
    }
  }, [insertCheck]);
  const getuserList = () => {
    const meetingUrl = '/ippcmpapi/cmpMeetingZooms/search'

    const params = {
      sortColumn: '',
      sortType: 'ASC',
      pageNum: '1',
      recordSize: '7',
      pageSize: '5'
    }

    axios.post(meetingUrl, params).then(item => {

      console.log("### 00001 ::: ", item.data['cmpMeetingZooms']);
      setmeetingList(item.data['cmpMeetingZooms']);
      // console.log("### 00001 ::: ", item.data.cmpMeetingZooms);
      setisLoading(!isLoading)
      setInsertCheck("start");

    }).catch(err => {
      setisLoading(!isLoading)
      setInsertCheck("start");

    })



  }

  const [InsertMeetingName, setInsertMeetingName] = useState('')
  const [InsertMeetingType, setInsertMeetingType] = useState('')
  const [InsertMeetingPw, setInsertMeetingPw] = useState('')
  const [InsertDownloadUrl, setInsertDownloadUrl] = useState('')
  const [InsertMeetingStart, setInsertMeetingStart] = useState('')
  const [InsertMeetingEnd, setInsertMeetingEnd] = useState('')
  const [InsertIsUse, setInsertIsUse] = useState('Y')

  const [showInviteButton, setShowInviteButton] = useState(false);

  const handleMeetingNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInsertMeetingName(value);
    //console.log(InsertMeetingName)
  };
  const handleMeetingTypeChange = (value: string) => {
    setInsertMeetingType(value);
    console.log(InsertMeetingType)
  };
  const handleMeetingPwChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setInsertMeetingPw(value);
    console.log(InsertMeetingPw)
  };
  const handleDownloadUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInsertDownloadUrl(value);
    //console.log(InsertMeetingName)
  };
  const handleMeetingStartChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInsertMeetingStart(value);
    //console.log(InsertMeetingName)
  };
  const handleMeetingEndChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInsertMeetingEnd(value);
    //console.log(InsertMeetingName)
  };
  const handleIsUseChange = (value: string) => {
    setInsertIsUse(value);
    //console.log(InsertMeetingName)
  };

  // InsertMeeting 변수 초기값으로 변경
  const handleMeetingChangeCleanup = () => {
    setInsertMeetingName('')
    // setInsertMeetingType('')
    setInsertMeetingPw('')
    setInsertDownloadUrl('')
    setInsertMeetingStart('')
    setInsertMeetingEnd('')
    setInsertIsUse('Y')
  }

  const insertMeeting = () => {
    setInsertCheck("insertStart");
    if (!(InsertMeetingName === '' || InsertMeetingType === '' || InsertMeetingStart === '' || InsertMeetingEnd === '')) {
      const sbody = { "meetingTitle": InsertMeetingName, "meetingType": InsertMeetingType, "meetingPw": InsertMeetingPw, "meetingDwUrl": "", "meetingStart": InsertMeetingStart, "meetingEnd": InsertMeetingEnd, "useAt": InsertIsUse }
      axios.post("/ippcmpapi/cmpMeetingZooms", sbody).then(item => {

        console.log("### 00001 ::: ", item.data['cmpMeetingZooms']);
        getuserList()
        alert('등록에 성공하였습니다.')
        //handleMeetingChangeCleanup()
        setShowInviteButton(true)

      }).catch(err => {
        alert('등록에 실패하였습니다.')
        //handleMeetingChangeCleanup()

      })
    }
    else {
      alert('필수값을 입력하세요.\n회의 제목, 회의 타입, 회의 시작시간, 회의 종료시간')
      //handleMeetingChangeCleanup()
    }
  }

  // 유저 정보 모달
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };
  const handleCreateOk = () => {
    setCreateModalOpen(false);
  };
  const handleCreateCancel = () => {
    setCreateModalOpen(false);
  };

  // 통화 모달
  const showcallModal = () => {
    setcallModalOpen(true);
  };
  const showPrevwModal = () => {
    previewModalOpen(true);
  };

  const handleOk = () => {
    setcallModalOpen(false);
  };
  const handlePrevwOk = () => {
    previewModalOpen(false);
  };

  const handleCancel = () => {
    setcallModalOpen(false);
  };
  const handlePrevwCancel = () => {
    previewModalOpen(false);
  };

  // 파일이미지유저탭
  const onChange = (key: string) => {
    console.log(zmClient.getAllUser())
    console.log('is host : ', zmClient.getSessionHost())
    console.log(key);
    if(Number(key) == 1){
      getOrgList();
    }
  };






  
  // 조직도 리스트
  const [treeData, setTreeData] = useState([]);
  const [userData, setUserData] = useState<any[]>([]);

  // 임직원 리스트
  const getUserList = (deptId: any) => {
    var param = {
      "recordCountPerPage": 1000,
      "pageIndex": 1,
      "departmentCode": deptId,
    };
    axios.post('/feedback/employee/selectEmployee', param)
      .then(response => {
        // 서버로부터 받은 데이터를 처리
        console.log(response.data.resultList);

        setUserData(response.data.resultList);
        showGroupDrawer();
      })
      .catch(error => {
        alert("임직원 조회 중 에러가 발생하였습니다.")
      })
  };


  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
    // 트리 선택 취소 할 경우
    if (selectedKeys.length == 0) {
      // setUserData([]);
    } else {
      getUserList(selectedKeys[0]);
    }
  }


  
  const getOrgList = () => {
    var param = {
      "recordCountPerPage": 10000,
      "pageIndex": 1,
    };
    axios.post('/feedback/organizations/selectOrganizations', param)
      .then(response => {
        // 서버로부터 받은 데이터를 처리
        console.log(response.data.resultList);
        var map: any = {}, node: any, roots: any = [], i;

        for (i = 0; i < response.data.resultList.length; i += 1) {
          map[response.data.resultList[i].key] = i; // initialize the map
          response.data.resultList[i].children = []; // initialize the children
        }

        for (i = 0; i < response.data.resultList.length; i += 1) {
          node = response.data.resultList[i];
          if (
            node.upr_department_code != "" &&
            node.upr_department_code != null
          ) {
            // if you have dangling branches check that map[node.parentId] exists
            //   console.log(node.upr_department_code)
            //   console.log(response.data.resultList[map[node.upr_department_code]])
            if (
              response.data.resultList[
              map[node.upr_department_code]
              ] != undefined
            ) {
              response.data.resultList[
                map[node.upr_department_code]
              ].children.push(node);
              node.icon = <TeamOutlined />;

            }
            else {
              node.icon = <TeamOutlined />;
              roots.push(node);
            }
          } else {
            node.icon = <TeamOutlined />;
            roots.push(node);
          }
        }
        setTreeData(roots);
      })
      .catch(error => {
        alert("조직도 조회 중 에러가 발생하였습니다.")
      })
  };




// 조직도 관련 이벤트 끝
  // 연락처 conctact user list
  const [concactUserList, setconcactUserList] = useState<any[]>([]);

  // 선택된 채팅 유저 리스트
  const [chatUserList, setChatUserList] = useState<any[]>([]);
  
  // 체크박스 저장
  const [checkedList, setCheckedList] = useState<number[]>([])
  const [checkedUsersData, setCheckedUsersData] = useState<any[]>([]);


  const [userList, setuserList] = useState<UserData[]>([]) //  사용자 리스트



  // 검색
  // const handleContactSearchText = (event: any) => {
  //   setContactSearchText(event.target.value);
  // }

  // all Selct CheckBox
  const [allIsChecked, setAllIsChecked] = useState(false);

  const handleRegisterClick = () => {
    if (checkedUsersData.length == 0) {
      alert("선택하신 연락처가 없습니다.")
    } else {

      var duplicatesData = [];

      for (const obj1 of concactUserList) {
        for (const obj2 of checkedUsersData) {
          if (String(obj1.user_id) == String(obj2.user_id)) {
            duplicatesData.push(obj2);
            break; // 중복을 찾았으므로 다음 요소로 넘어감
          }
        }
      }
      if (duplicatesData.length > 0) {
        alert("이미 등록하신 연락처가 있습니다.");
        setCheckedList([]);
        setAllIsChecked(false);

      } else {
        if (confirm("해당 연락처를 추가하시겠습니까?")) {
          // const urlStr = window.location.href;
          // const url = new URL(urlStr);
          // const urlParams = url.searchParams;

          const name = sessionStorage.getItem("usernamer");

          // for (let index = 0; index < checkedUsersData.length; index++) {
          //   const element = checkedUsersData[index];
          //   var data = {
          //     "myUserId": name,
          //     "userId": element.userId,
          //     "userName": element.userName,
          //     "companyId": element.companyId,
          //     "companyName": element.companyName,
          //     "partId": element.partId,
          //     "partName": element.partName,
          //     "teamId": element.teamId,
          //     "teamName": element.teamName,
          //     "tenantId": element.tenantId,
          //     "tenantName": element.tenantName,
          //     "tenantDomain": element.tenantDomain,
          //     "tenantType": element.tenantType,
          //     "email": element.email,
          //     "phone": element.phone,
          //     "description": element.description,
          //     "userGroup": element.userGroup,
          //     "userLevel": element.userLevel,
          //     "userImage": element.userImage,
          //   };
          //   axios.post('/feedback/contactLog/contactLog/insert', data)
          //     .then(response => {
          //       // 서버로부터 받은 데이터를 처리
          //       if (index == checkedUsersData.length - 1) {
          //         setCheckedList([]);
          //         setAllIsChecked(false);
          //         setTimeout(() => {
          //           contactList();

          //           if(isMobile == true){
          //             setselectmenu("즐겨찾기");
          //             setleftmodalvisible("즐겨찾기");
          //           }
          //         }, 1000);
          //       }
          //     })
          //     .catch(error => {
          //       // 에러 처리
          //       console.error('contactLog 데이터를 로드하지 못 하였습니다. 기본 데이터를 로드합니다. :', error);
          //     });
          // }
        } else {
          return;
        }
      }
    }
  }

  
  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      setCheckedList(userList.map((item) => item.user_id));
      setAllIsChecked(true);
    } else {
      setCheckedList([]);
      setAllIsChecked(false);
    }
  };
  const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, user_id: number) => {
    const { checked } = event.target;
    if (checked) {
      setCheckedList((prevCheckedList) => [...prevCheckedList, user_id]);
    } else {
      setCheckedList((prevCheckedList) => prevCheckedList.filter((id) => id !== user_id));
    }
  };

  // 조직도 모달 사용자 추가
  const addChatUserList = () => {
    var duplicatesData = [];

    const checked = userData.filter((item) => checkedList.includes(item.user_id));

    if(checkedList.length == 0){
      alert("선택하신 사용자가 없습니다.")
    }else{
      for (const obj1 of checked) {
        for (const obj2 of checkedUsersData) {
          if (String(obj1.user_id) == String(obj2.user_id)) {
            duplicatesData.push(obj2);
            break; // 중복을 찾았으므로 다음 요소로 넘어감
          }
        }
      }
      if (duplicatesData.length > 0) {
        alert("이미 추가하신 사용자가 있습니다.");
        setCheckedList([]);
       
      }else{
        if(confirm("선택하신 사용자를 추가하시겠습니까?")){
          if(checkedUsersData.length == 0){
            setCheckedUsersData(checked);
          }else{
            const checked = userData.filter((item) => checkedList.includes(item.user_id));

            for (let index = 0; index < checked.length; index++) {
              checkedUsersData.push(checked[index])
            }
          }
          setCheckedList([]);
          setGroupOpen(false)
        }else{
          return;
        }
      }
    }
  };

  // 선택한 사용자 제거
  const deleteCheckedUsersData = (items:any) =>{
    const checked = checkedUsersData.filter((item) => {
      if(item.user_id != items.user_id){
        return item;
      }
    });
    setCheckedUsersData(checked);
  }

  // 조직도 관련 이벤트 끝

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `User`,
      children: (
        // 현재 세션에 있는 사용자 목록 출력
        <div className='tab-total tab-User'>
          <div>
            {zmClient.getAllUser().map((user) => (
              <div key={user.userId} className='user-info' onClick={() => { setcurrentUserinfo(user.displayName) }}>{user.displayName}
                <div>
                  <button onClick={() => {
                    showPrevwModal()
                    setTargetTopic(user.displayName)
                  }} className="btn btn-video-info p-0 text-slate-600 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z">
                      </path>
                    </svg>
                  </button>
                  <button onClick={showcallModal} className="btn btn-call-info p-0 text-slate-600 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z">
                      </path>
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      key: '2',
      label: `Image`,
      children: (
        <ul className='tab-total tab-image' style={{ padding: "10px" }}>
          <div className='grid grid-cols-4 gap-2'>
            <img src={Object2} alt="" className='aspect-square rounded-lg object-cover object-center' />
            <img src={Object4} alt="" className='aspect-square rounded-lg object-cover object-center' />
            <img src={Object5} alt="" className='aspect-square rounded-lg object-cover object-center' />
            <img src={Object8} alt="" className='aspect-square rounded-lg object-cover object-center' />
          </div>
        </ul>
      ),
    },
    {
      key: '3',
      label: `File`,
      children: (
        <ul className='tab-total tab-file'>
          <li>
            <div className="flex items-center space-x-3">
              <div className="mask is-squircle flex h-11 w-11 items-center justify-center bg-secondary text-white">
                <DownloadOutlined className='filedown-info' />
              </div>
              <div>
                <p className="text-slate-700 dark:text-navy-100">
                  Final.fig
                </p>
                <div className="flex text-xs text-slate-400 dark:text-navy-300">
                  <span>8.32 MB</span>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="flex items-center space-x-3">
              <div className="mask is-squircle flex h-11 w-11 items-center justify-center bg-secondary text-white">
                <DownloadOutlined className='filedown-info' />
              </div>
              <div>
                <p className="text-slate-700 dark:text-navy-100">
                  TheBook.pdf
                </p>
                <div className="flex text-xs text-slate-400 dark:text-navy-300">
                  <span>14 MB</span>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="flex items-center space-x-3">
              <div className="mask is-squircle flex h-11 w-11 items-center justify-center bg-secondary text-white">
                <DownloadOutlined className='filedown-info' />
              </div>
              <div>
                <p className="text-slate-700 dark:text-navy-100">
                  Report.docx
                </p>
                <div className="flex text-xs text-slate-400 dark:text-navy-300">
                  <span>3.5 MB</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      ),
    },
  ];

  // 채팅생성모달 사용자 등록
  const InviteUsers: TabsProps['items'] = [
    {
      key: '1',
      label: `조직도`,
      children: (
        <div className='tab-total tab-group'>
          <div>
            {/* 조직도 */}
            <div className='tab-modal-group'>
            <Tree
                showLine
                switcherIcon={<DownOutlined />}
                showIcon={true}
                defaultExpandedKeys={['0-0-0']}
                onSelect={onSelect}
                onClick={() => {
                }}
                treeData={treeData}
              />

            </div>
            {/*검색창 */}
            <div className='' style={{ display: "flex", alignItems: "center" }}>
              <label className="relative mr-1.5 flex" style={{ flex: "2" }}>
                <input className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900" placeholder="초대자를 입력하세요" type="text" style={{ paddingLeft: "34px" }} />
                <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 transition-colors duration-200" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z">
                    </path>
                  </svg>
                </span>
              </label>
              <svg xmlns="http://www.w3.org/2000/svg" className="twotab-search-btn transition-colors duration-200" fill="currentColor" viewBox="0 0 24 24">
                <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z">
                </path>
              </svg>
            </div>
            <table className='mt-10' style={{ width: "100%" }}>
              <tbody>
                {/* 연락처 */}
                {
                  checkedUsersData.length == 0?
                    <tr>  
                      <td>
                        <div style={{ display: "flex" }}>
                          조회된 데이터가 없습니다.
                        </div>
                      </td>
                    </tr>
                  :

                  checkedUsersData.map((item, index)=>{
                    return(
                        <tr>
                          <td>
                            <div style={{ display: "flex" }}>
                              <div
                                className="name-circle name-circle-size flex items-center justify-center rounded-full bg-primary text-white dark:bg-accent cursor-default pb-20"
                                style={{ marginRight: "7px" }}
                              >
                                {item.full_name[0]}
                              </div>
                              <div>
                                <div style={{}}>
                                  <a
                                    onClick={() => {

                                    }}
                                  >
                                    {item.full_name}
                                  </a>
                                  <span style={{ color: "#8a8a8a", fontSize: "12px", marginLeft: "5px" }}>
                                    {/* {item.teamName} */}
                                    {item.department_name}
                                  </span>
                                </div>
                                <br />
                                <div style={{ color: "rgb(138, 138, 138)" }}>
                                  {/* {
                                    휴대전화번호 정규식
                                    item.phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                                    item.phone.replace(/-/g, "")
                                  } */}
                                  010-9999-9999
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <a href="#" style={{ marginRight: "7px" }}>
                              <IconFont type="video" />
                            </a>
                            <a href="#" style={{ marginRight: "7px" }}>
                              <IconFont type="phone" />
                            </a>

                            <a  onClick={()=>{
                              deleteCheckedUsersData(item);
                            }}>
                              <IconFont type="icon-stop-leave" />
                            </a>
                          </td>
                        </tr>
                    )
                  })
                
                }
                
              </tbody>
            </table>
          </div>
          {/* <button onClick={showGroupDrawer}>조직도 모달 버튼</button> */}

        </div>
      ),
    },
    {
      key: '2',
      label: `연락처`,
      children: (
        <ContactModules></ContactModules>
      ),
    },
    {
      key: '3',
      label: `통화`,
      children: (
        <CallModules></CallModules>
      ),
    },
  ];
  // 웹컬러코드 변환함수
  const [colorCode, setcolorCode] = useState('')
  function stringToWebColorCode(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let j = 0; j < 3; j++) {
      const value = (hash >> (j * 8)) & 0xff;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  }
  const urlParams = new URLSearchParams(location.search);
  const urlTopicname = urlParams.get('topic') || '';
  const urlUsername = urlParams.get('name') || '';
  const firstCharacter = urlTopicname ? urlTopicname[0] : '';



  // 이름이 없다면 빈 문자열 저장
  // const presentName = urlParams.get('name') || '';
  const presentTopic = urlParams.get('topic') || '';
  const updateQueryParam = (meetingname: string, meetingtype: string) => {
    const TargetTopic = meetingname; // 수정할 쿼리 파라미터
    /*
    if (meetingtype==='P'){
    history.push({
      pathname: '/chat', // 이동할 페이지 경로
      search: `?topic=${newQuery}&name=${presentname}`, // 수정된 쿼리 파라미터
    });
    console.log(zmClient.getCurrentUserInfo())
    window.location.reload()
  }
  else if(meetingtype==="V"){
    history.push({
      pathname: '/video', // 이동할 페이지 경로
      search: `?topic=${newQuery}&name=${presentname}`, // 수정된 쿼리 파라미터
    });
    console.log(zmClient.getCurrentUserInfo())
    window.location.reload()
  }
  */if (sessionStorage.getItem('name') === null || TargetTopic === '') {
      alert('topic 또는 name을 확인해 주세요.');

    }
    else {
      history.push({
        pathname: '/chat', // 이동할 페이지 경로
        search: `?topic=${TargetTopic}&name=${sessionStorage.getItem('name')}`, // 수정된 쿼리 파라미터
      });
      console.log(zmClient.getCurrentUserInfo())
      window.location.reload()
    }

  }

  const renderComponent = (type: string) => {
    switch (type) {
      case 'Feed':
        return <Alarm history={props.history} location={props.location} match={props.match} />;
      case 'Contact':
        return <Contact history={props.history} location={props.location} match={props.match} />;
      case 'Chatting':
        return (
          <div className='is-scrollbar-hidden mt-3 flex grow flex-col overflow-y-auto call-scroll' style={{ height: "calc(100vh - 204px)" }}>
            {isLoading ? (<LoadingOutlined style={{ fontSize: "30px", marginTop: "30px" }} />) :
              meetingList?.map((item, index) => (
                <div
                  key={index}
                  className="menu flex cursor-pointer items-center space-x-2.5 px-4 py-2.5 font-inter hover:bg-slate-150 dark:hover:bg-navy-600  fold-pl"
                  onClick={() => {
                    updateQueryParam(item.meetingTitle, item.meetingType)
                  }}
                >
                  <div className="avatar2">
                    <div className="name-circle flex h-8 w-8 items-center justify-center rounded-full text-white dark:bg-accent cursor-default pb-20" style={{ background: `#${item.sn}` }}>
                      {item.meetingTitle.charAt(0)}
                    </div>
                    <div className="absolute right-0 h-3 w-3 rounded-full bg-slate-300 dark:border-navy-700" style={{ position: "absolute", border: "2px solid #fff" }}>
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col">
                    <div className="flex flex-none items-baseline justify-between space-x-1.5">
                      <p className="font-medium text-slate-700 line-clamp-1 dark:text-navy-100 truncate">
                        {item.meetingTitle}
                      </p>
                      <span className="text-tiny+ text-slate-400 dark:text-navy-300 fold-12 fold_text_left flex-none" style={{ fontSize: "14px" }}>
                        {new Date(item.meetingStart).toLocaleDateString("ko-KR", {
                          year: '2-digit',
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })}</span>
                    </div>
                    <div className="mt-1 flex items-center justify-between space-x-1">
                      {item.meetingType === "V" ?
                        <p className="text-xs+ text-slate-400 line-clamp-1 dark:text-navy-300">
                          Video meeting
                        </p>
                        : null}
                      {item.meetingType === "P" ?
                        <p className="text-xs+ text-slate-400 line-clamp-1 dark:text-navy-300">
                          Chatting
                        </p>
                        : null}
                      {item.meetingType === "C" ?
                        <p className="text-xs+ text-slate-400 line-clamp-1 dark:text-navy-300">
                          Call
                        </p>
                        : null}
                      <div className="flex h-4.5 min-w-[1.125rem] items-center justify-center rounded-full bg-slate-200 px-1.5 text-tiny+ font-medium leading-none text-slate-800 dark:bg-navy-450 dark:text-white fs-12">
                        2
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>);
      case 'Group':
        return <Group history={props.history} location={props.location} match={props.match} />;
      case 'Call':
        return <Call history={props.history} location={props.location} match={props.match} />;
      case 'File':
        return <File history={props.history} location={props.location} match={props.match} />;
      default:
        return null;
    }
  };
  // 로딩바
  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;


  // 파일첨부모달
  type LayoutType = Parameters<typeof Form>[0]['layout'];

  const App: React.FC = () => <Tabs defaultActiveKey="1" items={items} onChange={onChange} />;
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState<LayoutType>('vertical');

  const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
    setFormLayout(layout);
  };

  const formItemLayout =
    formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;

  const buttonItemLayout =
    formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

  const onChatMessage = useCallback(
    (payload: ChatRecord) => {
      setChatRecords(
        produce((records: ChatRecord[]) => {
          const { length } = records;
          if (length > 0) {
            const lastRecord = records[length - 1];
            if (
              payload.sender.userId === lastRecord.sender.userId &&
              payload.receiver.userId === lastRecord.receiver.userId &&
              payload.timestamp - lastRecord.timestamp < 1000 * 60 * 5
            ) {
              if (Array.isArray(lastRecord.message)) {
                lastRecord.message.push(payload.message as string);
              } else {
                lastRecord.message = [lastRecord.message, payload.message as string];
              }
            } else {
              records.push(payload);
            }
          } else {
            records.push(payload);
          }
        })
      );
      if (chatWrapRef.current) {
        chatWrapRef.current.scrollTo(0, chatWrapRef.current.scrollHeight);
      }
    },
    [chatWrapRef]
  );
  const onChatPrivilegeChange = useCallback(
    (payload) => {
      setChatPrivilege(payload.chatPrivilege);
      if (chatClient) {
        setChatReceivers(chatClient.getReceivers());
      }
    },
    [chatClient]
  );
  const onChatInput = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChatDraft(event.target.value);
  }, []);
  useEffect(() => {
    zmClient.on('chat-on-message', onChatMessage);
    return () => {
      zmClient.off('chat-on-message', onChatMessage);
    };
  }, [zmClient, onChatMessage]);
  useEffect(() => {
    zmClient.on('chat-privilege-change', onChatPrivilegeChange);
    return () => {
      zmClient.off('chat-privilege-change', onChatPrivilegeChange);
    };
  }, [zmClient, onChatPrivilegeChange]);
  useParticipantsChange(zmClient, () => {
    if (chatClient) {
      setChatReceivers(chatClient.getReceivers());
    }
    setIsHost(zmClient.isHost());
    // setIsManager(zmClient.isManager());
  });
  useEffect(() => {
    if (chatUser) {
      const index = chatReceivers.findIndex((user) => user.userId === chatUser.userId);
      if (index === -1) {
        setChatUser(chatReceivers[0]);
      }
    } else {
      if (chatReceivers.length > 0) {
        setChatUser(chatReceivers[0]);
      }
    }
  }, [chatReceivers, chatUser]);
  const setChatUserId = useCallback(
    (userId) => {
      const user = chatReceivers.find((u) => u.userId === userId);
      if (user) {
        setChatUser(user);
      }
    },
    [chatReceivers]
  );
  const sendMessage = useCallback(
    // event 변수가 마우스와 키보드 이벤트를 모두 처리할 수 있도록 변경.
    // (event: React.MouseEvent<HTMLDivElement>|React.KeyboardEvent<HTMLTextAreaElement>) => {
    //   event.preventDefault();
    //   if (chatUser && chatDraft) {
    //     chatClient?.send(chatDraft, chatUser?.userId);
    //     setChatDraft('');
    //   }
    // },

    () => {
      if (chatUser && chatDraft) {
        chatClient?.send(chatDraft, chatUser?.userId);
        setChatDraft('');
      }
    },
    [chatClient, chatDraft, chatUser]
  );
  useMount(() => {
    setCurrentUserId(zmClient.getSessionInfo().userId);
    if (chatClient) {
      setChatPrivilege(chatClient.getPrivilege());
    }
  });
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const urlTopicname = urlParams.get('topic');

    if (urlTopicname) {
      setinfovisible(true);
      setleftmodalvisible('');
    } else {
      //setinfovisible(false);
      setleftmodalvisible('Chatting');
      setselectmenu('Chatting')
    }
  }, []);

  return (

    <div>
      {/* 사이드메뉴 */}

      <div className='flex_style'>
        {siderbar ?
          <div className='main-sidebar ts-dropdown-content accordionfix video_style1'>

            {/* 로고 */}
            <div className='left_logo'>
              <a href="#" style={{ marginTop: "0" }}>
                <img src={logoImage} style={{ width: "30px", height: "30px" }} onClick={() => history.push(`/${location.search}`)} />
              </a>
            </div>
            <div className='side-icon' style={{ marginTop: "80px" }}>
              {/* lfet icon */}
              <div className={selectmenu === 'Feed' ? 'selectedmenu' : ''} onClick={() => {
                // setselectmenu('Feed')
                // setleftmodalvisible('Feed')
                history.push(`/alarm${location.search}`)
                console.log(selectmenu)
              }}>
                <BellOutlined />
                <p className='icon_fs'>활동</p>
              </div>

              <div
                className={selectmenu === 'Chatting' ? 'selectedmenu' : ''}
                onClick={() => {
                  setselectmenu('Chatting');
                  setleftmodalvisible('Chatting');
                  history.push(`/chat${location.search}`);
                  console.log(selectmenu);
                }}
              >
                <IconFont type="icon-chat" />
                <p className='icon_fs'>채팅</p>
              </div>

              <div className={selectmenu === 'Contact' ? 'selectedmenu' : ''} onClick={() => {
                // setselectmenu('Contact')
                // setleftmodalvisible('Contact')
                history.push(`/contact${location.search}`)
                console.log(selectmenu)
              }}>
                <UserAddOutlined />
                <p className='icon_fs'>연락처</p>
              </div>
              <div className={selectmenu === 'Group' ? 'selectedmenu' : ''} onClick={() => {
                // setselectmenu('Group')
                // setleftmodalvisible('Group')
                history.push(`/group${location.search}`)
                // console.log(selectmenu)
              }}>
                <UsergroupAddOutlined />
                <p className='icon_fs'>조직도</p>
              </div>

              <div className={selectmenu === 'Call' ? 'selectedmenu' : ''} onClick={() => {
                // setselectmenu('Call')
                // setleftmodalvisible('Call')
                history.push(`/call${location.search}`)
                console.log(selectmenu)
              }}>
                <WhatsAppOutlined />
                <p className='icon_fs'>통화</p>
              </div>

              <div className={selectmenu === 'File' ? 'selectedmenu' : ''} onClick={() => {
                // setselectmenu('File')
                // setleftmodalvisible('File')
                history.push(`/file${location.search}`)
                console.log(selectmenu)
              }}>
                <FileDoneOutlined />
                <p className='icon_fs'>파일</p>
              </div>

            </div>

            <div className='side-setting-icon'>
              <div className='whitemode'>
                <a><IconFont type="white-mode" /></a>
                {/* <p className='icon_fs'>화이트모드</p> */}
              </div>

              <div className='setline' onClick={() => { history.push(`/setting${location.search}`) }}>
                <SettingOutlined />
                {/* <p className='icon_fs'>설정</p> */}
              </div>

              <div>
                <a href="/"><LogoutOutlined className='logout_btn' /></a>
                {/* <p className='icon_fs'>로그아웃</p> */}
              </div>
            </div>
          </div>
          : null}
      </div>

      {(!(leftmodalvisible === '') && (leftmodalvisible === selectmenu)) ?
        <div className={classNames('left_modal fold-chat-left', { 'group-left': (siderbar) }, { 'left_modal_hidden': (!siderbar) })}>
          <div className='flex_style left_modal_title'>
            <h3>{selectmenu}</h3>
            <CloseOutlined onClick={() => {
              setleftmodalvisible('')
              setselectmenu('')
            }} />
          </div>

          {/*검색창 */}
          <div className="flex px-4" style={{ marginBottom: "18px" }}>
            <label className="relative mr-1.5 flex" style={{ flex: "2" }}>
              <input className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900" placeholder="Search" type="text" style={{ paddingLeft: "34px" }} />
              <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 transition-colors duration-200" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z">
                  </path>
                </svg>
              </span>
            </label>

            {/* <button className="btn -mr-2 h-8 w-8 shrink-0 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25" style={{ border: "none", }}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M22 6.5h-9.5M6 6.5H2M9 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM22 17.5h-6M9.5 17.5H2M13 20a2.5 2.5 0 100-5 2.5 2.5 0 000 5z">
                </path>
              </svg>
            </button> */}
          </div>
          {renderComponent(selectmenu)}

          {/* 생성버튼 */}
          <div className='chat-createBtn' onClick={() => showDrawer()}>
            <PlusOutlined />
          </div>
          <Drawer title="생성" width={520} closable={false} onClose={onClose} open={open}>
            <CloseOutlined className='chat-create-closebtn' onClick={() => onClose()}></CloseOutlined>
            <div>
              <Form.Item label="회의 제목">
                <Input placeholder="회의 제목을 입력해주세요" onChange={handleMeetingNameChange} value={InsertMeetingName} />
              </Form.Item>
              <Form.Item label="미팅 타입">
                <Select className='chat-useat'
                  defaultValue="미팅 타입을 입력해주세요"
                  onChange={handleMeetingTypeChange}
                  options={[
                    { value: 'V', label: '비디오' },
                    { value: 'C', label: '전화' },
                    { value: 'P', label: '채팅' },
                  ]}
                />
              </Form.Item>
              <Form.Item label="비밀번호">
                <Input placeholder="비밀번호를 입력해주세요" onChange={handleMeetingPwChange} value={InsertMeetingPw} />
              </Form.Item>
              {/** 
          <Form.Item label="다운로드 URL">
            <Input placeholder="다운로드 URL을 입력해주세요" />
          </Form.Item>
          */}
              <Form.Item label="회의시작일">
                {/* <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy" // You can customize the date format here
            isClearable // Adds a clear button to reset the date selection
            showYearDropdown // Shows a dropdown to select the year
            scrollableYearDropdown // Allows you to scroll through the year dropdown
          /> */}
                <Input placeholder="회의시작일을 입력해주세요" onChange={handleMeetingStartChange} value={InsertMeetingStart} />
              </Form.Item>
              <Form.Item label="회의종료일">
                <Input placeholder="회의종료일을 입력해주세요" onChange={handleMeetingEndChange} value={InsertMeetingEnd} />
              </Form.Item>
              {/** 
          <Form.Item label="사용여부">
          <Select className='chat-useat'
            defaultValue="사용여부를 입력해주세요"
            onChange={handleIsUseChange}
            options={[
              { value: 'Y', label: 'Y' },
              { value: 'N', label: 'N' },
            ]}
          />
          </Form.Item>
          */}
              <div className='chat-create-footer'>
                {/*<Button className='' style={{ marginRight: "5px" }}>취소</Button>*/}
                {showInviteButton && (
                  <Button onClick={() => { showChildrenDrawer();  }} style={{ marginRight: "5px" }}>
                    초대자 등록
                  </Button>
                )}
                <Button type="primary" className='chatsendbtn' onClick={() => {
                  insertMeeting()
                }} disabled={(InsertMeetingName === '' || InsertMeetingType === '' || InsertMeetingStart === '')}>등록
                </Button>
              </div>
            </div>

            <Drawer
              width={420}
              closable={false}
              onClose={onChildrenDrawerClose}
              open={childrenDrawer}
            >
              <Tabs defaultActiveKey="1" items={InviteUsers} onChange={onChange} />
              {/* This is two-level drawer */}
              <CloseOutlined className='chat-create-closebtn' onClick={() => onChildrenDrawerClose()}></CloseOutlined>
              <br/>
            </Drawer>
          </Drawer>

          {/* 채팅 사용자 등록 조직도 모달 */}
          <Drawer className='chat-group-modal' title="임직원" placement="right" open={openGroup} onClose={onGroupClose}>
            <label className="relative mr-1.5 flex" style={{ flex: "2", marginBottom: "15px" }}>
              <input
                className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                placeholder="Search"
                type="text"
                style={{ paddingLeft: '34px' }}
              // value={searchText}
              // onChange={handleSearchChange}
              />
              <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
                <svg xmlns="http:// www.w3.org/2000/svg" className="h-4 w-4 transition-colors duration-200" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z">
                  </path>
                </svg>
                {/* <button onClick={searchFnc} className="btn -mr-2 h-8 w-8 shrink-0 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25" style={{ border: "none", }}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5"><path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </button> */}
              </span>
              {/* <button
                    onClick={searchFnc}
                    className="btn -mr-2 h-8 w-8 shrink-0 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25"
                    style={{ border: "none" }}
                  >
                    <IconFont type="filter" />
                  </button> */}
            </label>
            {/* 연락처 테이블 */}
            <div className='contact-table-mb contact-scroll is-scrollbar-hidden mt-3 flex grow flex-col overflow-y-auto call-scroll cursor-default mb-table-height'>
              {/* userList를 이용하여 사용자 목록 표시 */}
              <div className='contact-table-btn' style={{ justifyContent: "flex-end" }}>
                <Button className='' onClick={()=>{
                  addChatUserList();
                }}>등록</Button>
                {/* Button style={{ marginRight: "5px" }}> 그룹생성</Button> */}
              </div>
              <div style={{ overflowY: "auto", padding: "0 10px", height: "calc( 100vh - 230px )", marginTop: "10px"}}>
                <ul style={{ marginBottom: "30px" }}>
                  {
                    userData.length == 0?
                     <li>조회된 데이터가 없습니다.</li>
                    :
                    userData.map((item, index)=>{
                     return( <li className="chat-modal-list-item">
                        <input
                          type='checkbox'
                          checked={checkedList.includes(item.user_id)}
                          onChange={(event) => handleRowCheckboxChange(event, item.user_id)}
                          className='contact-mb-check'
                        />
                        <div className="contact-list-mb">
                          <div className="contact-list-avata-mb">
                            <div className="">
                              <div
                                className="fs-17 flex items-center justify-center rounded-full text-white dark:bg-accent"
                                style={{ background: "#d15a83", width: "35px", height: "35px" }}
                              >
                                {item?.full_name[0]}
                              </div>
                            </div>
                          </div>
                          <div style={{ display: "flex", placeSelf: "center" }}></div>
                          <div className="ant-list-item-meta-content" style={{ alignItems: "center" }}>
                            <div className='contact-text-mb' style={{ alignItems: "center" }}>
                              <h4 className="ant-list-item-meta-title" style={{ wordBreak: "keep-all", margin: "0 5px 0 0" }}
                              >
                                {/* {item?.userName} */}
                                {item?.full_name}
                              </h4>
                              <p className="text-muted contact_number" id="audiotext" style={{ marginTop: "-3px" }}>
                                {/* {item?.phone} */}
                                01012345678
                              </p>
                              {/* 팀/부서 */}
                              <div style={{ display: "flex" }}>
                                <span style={{ color: "#8a8a8a", fontSize: "12px" }}>
                                  {/* {item.partName} */}
                                {item?.department_name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <a href="#" className='contact-mb-video-icon'>
                          <svg xmlns="http:// www.w3.org/2000/svg" width="18" height="19" fill="currentColor" className="bi bi-camera-video contact-video" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"></path>
                          </svg>
                        </a>
                        <a href="#">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5 fold-chat-width contact-mb-call-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5"><path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
                        </a>
                      </li>
                      )
                    })
                  }
                  
                </ul>
              </div>
              {/* 페이지네이션 */}
              {/* <ul className="contact-pagination-mb">
                    {pageInfo.existPrevPage ? (
                      <>
                        <li className="page-item">
                          <a className="page-link" href="#" onClick={() => handlePageChange(pageInfo.startPage)}>
                            <DoubleLeftOutlined className="fs-12" />
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#" onClick={() => handlePageChange(pageInfo.startPage - 1)}>
                            <LeftOutlined className="fs-12" />
                          </a>
                        </li>
                      </>
                    ) : (
                      ""
                    )}  {Array.from({ length: pageInfo.totalPageCount }).map((_, index) => (
                      <li className="page-item" key={index}>
                        <a
                          className={`page-link ${currentPage === index + 1 ? 'pagenation-color' : ''}`}
                          href="#"
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </a>
                      </li>
                    ))} {pageInfo.existNextPage ? (
                      <>
                        <li className="page-item">
                          <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
                            <RightOutlined className="fs-12" />
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#" onClick={() => handlePageChange(pageInfo.endPage)}>
                            <DoubleRightOutlined className="fs-12" />
                          </a>
                        </li>
                      </>
                    ) : (
                      ""
                    )} 
            </ul>*/}
            </div>
          </Drawer>

        </div >
        : null}

      {/* 채팅본문 */}
      {
        props.location.search &&
        <div style={{ display: "flex" }}>
          <div className={classNames("chat-wrap", { "info_hidden": infovisible, 'left_modal_hidden': (!leftmodalvisible && !siderbar), 'only_chatinfo_visible': (!infovisible && !siderbar && !leftmodalvisible) })} style={{ width: "calc(  100% - 400px)" }}>
            <div className="chat-header relative z-10 flex w-full shrink-0 items-center justify-between border-b border-slate-150 bg-white px-[calc(var(--margin-x)-.5rem)] shadow-sm transition-[padding,width] duration-[.25s] dark:border-navy-700 dark:bg-navy-800 chat-head-icon">
              <div className="flex items-center space-x-5" style={{ marginLeft: "10px" }}>

                {/* <!-- 메뉴로 가는 버튼 --> */}
                {/* <div className="ml-1 mr-2 h-7 w-7 sidebar_button">
                <button className="menu-toggle ml-0.5 flex h-7 w-7 flex-col justify-center space-y-1.5 text-primary outline-none focus:outline-none dark:text-accent-light/80">
                <span></span>
                <span></span>
                <span></span>
                </button>
                </div> */}

                <div className="flex cursor-pointer items-center space-x-4 font-inter" >

                  <div className="ml-1 mr-2 h-7 w-7" onClick={() => {
                    if (leftmodalvisible === '') {
                      setleftmodalvisible('Chatting');
                      setselectmenu('Chatting')
                      // ...
                    } else {
                      setleftmodalvisible('');
                      setselectmenu('')
                    }
                  }}>
                    <button className="ml-0.5 flex h-7 w-7 flex-col space-y outline-none cursor-pointer">
                      <span className='menu-togglespan'></span>
                      <span className='menu-togglespan' style={{ width: "0.75rem", height: "2.5px" }}></span>
                      <span className='menu-togglespan'></span>
                    </button>
                  </div>


                  <div className="avatar2">
                    {/* <img className="rounded-full " src={initialImage} alt="avatar" /> */}
                    <div className='fs-17 flex h-8 w-8 items-center justify-center rounded-full text-white dark:bg-accent alarm-name' style={{ background: "#0dbd8b" }}>{firstCharacter}</div>
                  </div>
                  <div className='head-user-name' style={{ textAlign: "left" }}>
                    <p className="font-medium text-slate-700 line-clamp-1 dark:text-navy-100" x-text="activeChat.name">{ }</p>
                    <p className="mt-0.5 text-xs" style={{ color: "#64748b" }}>{urlTopicname}</p>
                  </div>
                </div>
              </div>
              {/**헤더 */}
              <div className="mr-1 flex items-center" style={{position:"relative"}}>
                <button onClick={() => {
                  setTargetTopic(urlTopicname)
                  showPrevwModal()
                  // console.log(TargetTopic)
                  // setcurrentUserinfo(urlParams.toString())
                }} className="btn hidden h-9 w-9 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:flex">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fold-chat-width" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z">
                    </path>
                  </svg>
                </button>
                <button onClick={() => {
                  showcallModal()
                  setcurrentUserinfo(presentTopic)
                }}
                  className="btn hidden h-9 w-9 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:flex">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5 fold-chat-width" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z">
                    </path>
                  </svg>
                </button>

                {/* search */}
                <button onClick={()=>setsearchmodalVisible(!searchmodalVisible)} className="btn h-9 w-9 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5 fold-chat-width" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z">
                    </path>
                  </svg>
                </button>

                {/* search modal */}
                {searchmodalVisible?
                <div className='search-modal'>
                  <label className="relative mr-1.5 flex" style={{ flex: "2" }}>
                    <input className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900" placeholder="Search" type="text" style={{ paddingLeft: "34px" }} />
                    <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 transition-colors duration-200" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z">
                        </path>
                      </svg>
                    </span>
                  </label>
                </div>:null}

                <button className="btn hidden h-9 w-9 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:flex text-slate-500 dark:text-navy-200 text-primary dark:text-accent-light"
                  onClick={() => setinfovisible(!infovisible)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5 fold-chat-width" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.25 21.167h5.5c4.584 0 6.417-1.834 6.417-6.417v-5.5c0-4.583-1.834-6.417-6.417-6.417h-5.5c-4.583 0-6.417 1.834-6.417 6.417v5.5c0 4.583 1.834 6.417 6.417 6.417ZM13.834 2.833v18.334">
                    </path>
                  </svg>
                </button>
                <div x-data="usePopper({placement:'bottom-end',offset:4})" className="inline-flex ">
                  <button onClick={() => {
                    //siderbar 닫기
                    setsiderbar(!siderbar)
                    setleftmodalvisible('')
                    setselectmenu('')
                  }} className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5 text-slate-500 dark:text-navy-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                    </svg>
                  </button>

                  {/* 더보기 메뉴 */}
                  {/* <div x-ref="popperRoot" className="popper-root" style={{ position: "fixed", inset: "3px 330px auto auto", margin: "0px", transform: "translate(0px, 52px)" }} data-popper-placement="bottom-end">
                <div className="popper-box rounded-md border border-slate-150 bg-white py-1.5 font-inter dark:border-navy-500 dark:bg-navy-700">
                  <ul>
                    <li>
                      <a href="#" className="flex h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100">
                        <svg xmlns="http://www.w3.org/2000/svg" className="mt-px h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" clip-rule="evenodd"></path>
                          <path stroke-linecap="round" stroke-linejoin="round" d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2">
                          </path>
                        </svg>
                        <span>Unmute</span></a>
                    </li>
                    <li>
                      <a href="#" className="flex h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100">
                        <svg xmlns="http://www.w3.org/2000/svg" className="mt-px h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z">
                          </path>
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z">
                          </path>
                        </svg>
                        <span>Chat Setting</span></a>
                    </li>
                    <li>
                      <a href="#" className="flex h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100">
                        <svg xmlns="http://www.w3.org/2000/svg" className="mt-px h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636">
                          </path>
                        </svg>
                        <span>Block User</span></a>
                    </li>
                    <li>
                      <a href="#" className="flex h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide text-error outline-none transition-all hover:bg-error/20 focus:bg-error/20">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16">
                          </path>
                        </svg>
                        <span> Delete chat</span></a>
                    </li>
                  </ul>
                </div>
              </div> */}
                </div>
              </div>

              {/* 통화모달 */}
              <CallModal isOpen={callModalOpen} onOk={handleOk} onCancel={handleCancel} currentUsername={currentUserinfo}>
              </CallModal>
              {/** 
              <Modal className='call-modal' title="" open={callModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="text-center p-4" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                  <div className="avatar-lg mx-auto mb-4" id="acimg">
                    <div className='fs-17 flex h-8 w-8 items-center justify-center rounded-full text-white dark:bg-accent chatinfo-name' style={{ background: "#0dbd8b" }}>{currentUserinfo[0]}</div>
                  </div>
                  <h3 className="text-truncate vcname" style={{ marginTop: "10px" }}>{currentUserinfo}</h3>
                  <p className="text-muted">Frontend Developer</p>
                  <hr className='hr-style' />
                  <p className="text-muted" id="audiotext" style={{ display: "none" }}>Calling...</p>
                  <div className="" style={{ marginTop: "1.5rem" }}>
                    <ul className="list-inline mb-1" style={{ display: "flex" }}>
                      <li className="list-inline-item px-3 me-2 ms-0">
                        <button type="button" id="cutmiscall" className="btn btn-danger-call avatar-sm rounded-circle">
                          <span className="avatar-title bg-transparent font-size-20" style={{ fontSize: "20px" }}>
                            <CloseOutlined />
                          </span>
                        </button>
                      </li>
                      <li className="list-inline-item px-3">

                        <button type="button" className="btn btn-success-call avatar-sm rounded-circle" id="callaudiomodel">
                          <span className="avatar-title bg-transparent font-size-20" style={{ marginTop: "7px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z">
                              </path>
                            </svg>
                          </span>
                          <Spin indicator={antIcon} className='call-spiner' />
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </Modal>
              */}

              {/* 프리뷰모달 */}
              <Modal className='preview-modal' title="" open={prevwModalOpen} onOk={handlePrevwOk} onCancel={handlePrevwCancel}>
                <div className="text-center p-4" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                  <Preview history={props.history} location={props.location} match={props.match} TargetTopic={TargetTopic} onCloseModal={handlePrevwCancel}  ></Preview>
                </div>
              </Modal>

            </div>
            <div className="chat-message-wrap" ref={chatWrapRef}>
              {chatRecords.map((record) => (
                <ChatMessageItem
                  record={record}
                  currentUserId={currentUserId}
                  setChatUser={setChatUserId}
                  key={record.timestamp}
                />
              ))}
            </div>
            <div className='chat-message-input'>
              {ChatPrivilege.NoOne !== chatPrivilege || isHost || isManager ? (
                <>
                  {/*}
              <div style={{ display: 'flex', justifyContent: "space-between" }}>
                <ChatReceiverContainer
                  chatUsers={chatReceivers}
                  selectedChatUser={chatUser}
                  isHostOrManager={isHost || isManager}
                  chatPrivilege={chatPrivilege}
                  setChatUser={setChatUserId}
                />

              </div>
              */}
                  <div className="chat-message-box" style={{ display: "flex", height: "66px" }}>
                    <div className='chat-icon' onClick={() => {
                      setfilevisible(!filevisible)
                    }}>
                      <PaperClipOutlined />
                    </div>

                    {/* 파일첨부 모달 영역 */}
                    <div className={classNames('file-modal', { 'file-modal-none': !filevisible })}>
                      <Form
                        {...formItemLayout}
                        layout={formLayout}
                        form={form}
                        initialValues={{ layout: formLayout }}
                        onValuesChange={onFormLayoutChange}
                        style={{ maxWidth: formLayout === 'inline' ? 'none' : 600 }}
                      >
                        {/* <Form.Item label="Form Layout" name="layout">
                    <Radio.Group value={formLayout}>
                      <Radio.Button value="horizontal">Horizontal</Radio.Button>
                      <Radio.Button value="vertical">Vertical</Radio.Button>
                      <Radio.Button value="inline">Inline</Radio.Button>
                    </Radio.Group>
                  </Form.Item> */}

                        <Form.Item label="File A">
                          <Input placeholder="please put the file" />
                        </Form.Item>
                        <Form.Item label="File B">
                          <Input placeholder="please put the file" />
                        </Form.Item>
                        <Form.Item {...buttonItemLayout}>
                          <Button type="primary" className='filesendbtn'>Submit</Button>
                        </Form.Item>
                      </Form>
                    </div>


                    {/* <TextArea
                  onPressEnter={sendMessage}
                  onChange={onChatInput}
                  value={chatDraft}
                  placeholder="Type message here ..."
                  className='textareaStyle'
                /> */}

                    <div className="chatInputContainer">
                      <EmojiPicker
                        onEnterData={sendMessage}
                        chgChatData={setChatDraft}
                        chatData={chatDraft}
                      />
                    </div>

                    {/* <div className='chat-icon'>
                  <SmileOutlined />
                  <div className='emogi-modal'></div>
                </div> */}

                    <div className='sendBtn' onClick={sendMessage}><SendOutlined /></div>
                  </div>
                </>
              ) : (
                <div className="chat-disabled">Chat disabled</div>
              )}
            </div>

            {!infovisible ?
              <div className='chatinfo-sidebar ts-dropdown-content accordionfix chat-wrap'>
                {/* 제목 */}
                <div className='chatinfo_title'>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ fontSize: "18px", color: "#3f4a5b", fontWeight: "500" }}> Chat Info </span>
                  </div>

                  <div className='chat-head-icon' style={{ display: "flex" }}>
                    {/* <button className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5 text-slate-500 dark:text-navy-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ color: "#3f4a5b" }}>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z">
                        </path>
                      </svg>
                    </button> */}
                    {/* 채팅 닫기 */}
                    <div style={{ cursor: "pointer", padding: "5px", fontSize: "15px" }} onClick={() => {
                      setinfovisible(!infovisible)
                      console.log(infovisible)

                    }}>
                      <RightOutlined />
                    </div>
                  </div>


                </div>

                {/* 채팅 인포 */}
                {/*<ChatContainer></ChatContainer>*/}
                <div className='ts-dropdown-content' style={{ height: "80%" }} ref={chatWrapRef}>
                  {currentUserinfo ?
                    <div className="flex flex-col items-center" style={{ marginTop: "2.25rem" }}>
                      <div className="avatar-chat h-20 w-20">
                        {/* <img className="rounded-full" src={initialImage} alt="avatar" /> */}
                        <div className='fs-17 flex h-8 w-8 items-center justify-center rounded-full text-white dark:bg-accent chatinfo-name' style={{ background: "#0dbd8b" }}>{currentUserinfo[0]}</div>
                      </div>
                      <h3 x-text="activeChat.name" className="mt-2 text-lg font-medium text-slate-700 dark:text-navy-100">{currentUserinfo}</h3>
                      <p>Frontend Developer</p>
                      <div className="mt-2 flex space-x-1.5 chat-info-btn">
                        <button onClick={showPrevwModal} className="btn h-10 w-10 rounded-full p-0 text-slate-600 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z">
                            </path>
                          </svg>
                        </button>
                        <button onClick={showcallModal} className="btn h-10 w-10 rounded-full p-0 text-slate-600 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z">
                            </path>
                          </svg>
                        </button>
                        {/* <button className="btn h-10 w-10 rounded-full p-0 text-slate-600 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z">
                            </path>
                          </svg>
                        </button> */}
                      </div>
                    </div>
                    : null}


                  {/* 이미지 파일 유저리스트 탭 */}
                  <div>
                    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                  </div>
                </div>
              </div> : null}
          </div>
        </div>
      }

      {/* 생성 모달  */}
      <Modal className='chat-create-modal' title="" open={createModalOpen} onOk={handleCreateOk} onCancel={handleCreateCancel}>
        <div style={{ marginTop: "20px" }}>
          <Form.Item label="회의 제목">
            <Input placeholder="회의 제목을 입력해주세요" />
          </Form.Item>
          <Form.Item label="미팅 타입">
            <Select className='chat-useat'
              defaultValue="미팅 타입을 입력해주세요"
              onChange={handleChange}
              options={[
                { value: 'V', label: '비디오' },
                { value: 'C', label: '채팅' },
                { value: 'P', label: '전화' },
              ]}
            />
          </Form.Item>
          <Form.Item label="비밀번호">
            <Input placeholder="비밀번호를 입력해주세요" />
          </Form.Item>
          <Form.Item label="다운로드 URL">
            <Input placeholder="다운로드 URL을 입력해주세요" />
          </Form.Item>
          <Form.Item label="회의시작일">
            <Input placeholder="회의시작일을 입력해주세요" />
          </Form.Item>
          <Form.Item label="회의종료일">
            <Input placeholder="회의종료일을 입력해주세요" />
          </Form.Item>

          <Form.Item label="사용여부">
            <Select className='chat-useat'
              defaultValue="사용여부를 입력해주세요"
              onChange={handleChange}
              options={[
                { value: 'Y', label: 'Y' },
                { value: 'N', label: 'N' },
              ]}
            />
          </Form.Item>
          <div className='chat-create-footer'>
            <Button className='' style={{ marginRight: "5px" }}>취소</Button>
            <Button type="primary" className='chatsendbtn'>등록</Button>
          </div>
        </div>
      </Modal>
    </div >
  );
};

export default ChatContainer;
