import React, { useState } from "react";
import { Modal, Spin } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";

interface CallModalProps {
    isOpen: boolean;
    onOk: () => void;
    onCancel: () => void;
    currentUsername : string;
    currentUserteam? : string;
  }
  
const CallModal: React.FC<CallModalProps> = ({ isOpen, onOk, onCancel, currentUsername, currentUserteam }) => {
    const handleOk = () => {
        onOk();
    };

    const handleCancel = () => {
        onCancel();
    };
    const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;
    return (
        <Modal
            className="call-modal"
            title=""
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <div className="text-center p-4" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <div className="avatar-lg mx-auto mb-4" id="acimg">
                    <div className='fs-17 flex h-8 w-8 items-center justify-center rounded-full text-white dark:bg-accent chatinfo-name' style={{ background: "#0dbd8b" }}>{currentUsername[0]}</div>
                </div>
                <h3 className="text-truncate vcname" style={{ marginTop: "10px" }}>{currentUsername}</h3>
                <p className="text-muted">{currentUserteam?(currentUserteam):'No Description'}</p>
                <hr className='hr-style' />
                <p className="text-muted" id="audiotext" style={{ display: "none" }}>Calling...</p>
                <div className="" style={{ marginTop: "1.5rem" }}>
                    <ul className="list-inline mb-1" style={{ display: "flex" }}>
                        <li className="list-inline-item px-3 me-2 ms-0">
                            <button type="button" id="cutmiscall" className="btn btn-danger-call avatar-sm rounded-circle"  onClick={handleCancel}>
                                <span className="avatar-title bg-transparent font-size-20" style={{ fontSize: "20px" }}>
                                    <CloseOutlined />
                                </span>
                            </button>
                        </li>
                        <li className="list-inline-item px-3">

                            <button type="button" className="btn btn-success-call avatar-sm rounded-circle" id="callaudiomodel"  onClick={handleOk}>
                                <span className="avatar-title bg-transparent font-size-20" style={{ marginTop: "7px" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z">
                                        </path>
                                    </svg>
                                </span>
                                <Spin indicator={antIcon} className='call-spiner' />
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </Modal>
    );
};

export default CallModal;