import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import './call.scss';
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom"
import { DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, LoadingOutlined, MailOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';

import { Button, Form, Input, Modal, Pagination, Spin } from 'antd';
import { IconFont } from '../../../component/icon-font';
import CallModal from '../../../component/callmodal'

const ContactModules = () => {


    interface HomeProps extends RouteComponentProps {
        // subsession에서 에러안나도록 ?처리
        status?: string;
        onLeaveOrJoinSession?: () => void;
      }
      
      interface Params {
        sortColumn: string
        sortType: string
        pageNum: number
        recordSize: number
        pageSize: number
      }
      
      const initParams: Params = {
        sortColumn: "",
        sortType: "ASC",
        pageNum: 1,
        recordSize: 10,
        pageSize: 5
      }
      
      interface PageInfo {
        endPage: number
        existNextPage: boolean
        existPrevPage: boolean
        limitStart: number
        startPage: number
        totalPageCount: number
        totalRecordCount: number
      }
      const initialPageInfo: PageInfo = {
        endPage: 1,
        existNextPage: false,
        existPrevPage: false,
        limitStart: 0,
        startPage: 1,
        totalPageCount: 0,
        totalRecordCount: 0
      }
      interface UserData {
        userId: number
        userName: string
        companyId: number
        companyName: string
        partId: number
        partName: string
        teamId: number
        teamName: string
        tenantId: 2
        tenantName: string
        tenantDomain: string
        tenantType: string
        email: string
        phone: string
        description: string
        useAt: string
        userGroup: string
        userLevel: string
        userImage: string
        frstRegistPnttm: string
        frstRegisterId: number
        lastUpdtPnttm: string
        lastUpdusrId: number
        totalSearch: string
        //  다른 필드들의 타입도 여기에 추가
      }
      
      interface ContactData {
        myuserId: number,
        userId: number
        userName: string
        companyId: number
        companyName: string
        partId: number
        partName: string
        teamId: number
        teamName: string
        tenantId: number
        tenantName: string
        tenantDomain: string
        tenantType: string
        email: string
        phone: string
        description: string
        useAt: string
        userGroup: string
        userLevel: string
        userImage: string
        frstRegistPnttm: string
        frstRegisterId: number
        lastUpdtPnttm: string
        lastUpdusrId: number
        totalSearch: string
        //  다른 필드들의 타입도 여기에 추가
      }
      
      interface UserParams {
        userName: string
        companyName: string
        partName: string
        email: string
        phone: string
      }
      
      const initUserParams = {
        userName: "",
        companyName: "",
        partName: "",
        email: "",
        _phone: "",
        get phone() {
          return this._phone
        },
        set phone(value) {
          this._phone = value
        }
      }

 // 브라우저 파라미터 정보 저장
 const history = useHistory();
 const location = useLocation();
 const urlParams = new URLSearchParams(location.search);
 //  const presentname = urlParams.get('name')||''
 //  연락처 정보 저장
 const [userList, setuserList] = useState<UserData[]>([]) //  사용자 리스트
 const [selectUser, setSelectUser] = useState<UserData>();
 const [sbody, setSbody] = useState<UserParams>(initUserParams) //  검색 파라미터
 const [totalPages, settotalPages] = useState(0) //  토탈 페이지
 const [pageInfo, setPageInfo] = useState<PageInfo>(initialPageInfo) //  ajax 로 받아오는 


 // 연락처 conctact user list
 const [concactUserList, setconcactUserList] = useState<UserData[]>([]);
 const [totalPages2, settotalPages2] = useState(0) //  토탈 페이지
 const [pageInfo2, setPageInfo2] = useState<PageInfo>(initialPageInfo) //  ajax 로 받아오는 
 const [contactSearchText, setContactSearchText] = useState("")

 // 연락처 모달

 //페이지 인포
 const [params, setParams] = useState<Params>(initParams) //  정렬 페이지번호 파라미터
 const [presentname, setPresentnamet] = useState(sessionStorage.getItem("username")) //  사용자 정
 const [searchText, setSearchText] = useState("")
 const [currentUsername, setcurrentUsername] = useState("")
 const [currentUserTeams, setcurrentUserTeams] = useState("")
 const [currentPage, setCurrentPage] = useState(1)
 const [itemsPerPage] = useState(7)


 useEffect(() => {
   getPresentnamet()
   getuserList(params, sbody);
   contactList();
 }, [params, sbody])

 const [isMobile, setIsMobile] = useState(false);
 // 모바일 여부 확인
 useEffect(() => {
   const checkIsMobile = () => {
     const userAgent = navigator.userAgent.toLowerCase();
     setIsMobile(/mobile|android|iphone|ipad|webos|blackberry|windows phone/i.test(userAgent));
   };

   checkIsMobile(); // 최초 렌더링 시 기기 확인

   // 리사이징 시에도 기기 확인
   window.addEventListener('resize', checkIsMobile);

   // 컴포넌트 언마운트 시 리스너 제거
   return () => {
     window.removeEventListener('resize', checkIsMobile);
   };
 }, []);

 const [isLoading, setisLoading] = useState(true)
 // const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />
 const getPresentnamet = () => {
   const userNmae = sessionStorage.getItem("username")
   if (userNmae === undefined) {
     setPresentnamet("")
   } else {
     setPresentnamet(sessionStorage.getItem("username"))
   }
 }
 const getuserList = (params: Params, sbody: UserParams) => {
   axios
     .post("/ippcmpapi/cmpUsers/search", sbody, {
       params: params
     })
     .then((response) => {
       setisLoading(false)
       //  서버로부터 받은 데이터를 처리
       console.log(response.data.cmpUsers)

       // 데이터 세팅
       setuserList(response.data.cmpUsers)
       settotalPages(response.data.pageInfo.totalRecordCount)
       setPageInfo(response.data.pageInfo)
       // setCurrentPage(params.pageNum)
       console.log('요청성공! : /ippcmpapi/cmpUsers/search')
     })
     .catch((error) => {
       //  에러 처리
       // eslint-disable-next-line no-console
       console.error("cmpportal에 접속하지 못했습니다. 기본 데이터를 로드합니다. :", error)
       setuserList([])
       setisLoading(false)
     })
 }

 // 검색창의 값을 받아 searchText에 저장
 const handleSearchChange = (event: any) => {
   setSearchText(event.target.value)
 }

 const searchFnc = () => {
   if (searchText.length > 0) {
     setCurrentPage(0)
     const searchBody = {
       totalSearch: searchText,
       userName: sbody.userName,
       companyName: sbody.companyName,
       partName: sbody.partName,
       email: sbody.email,
       phone: sbody.phone
     }

     const searchParams = {
       sortColumn: params.sortColumn,
       sortType: params.sortType,
       pageNum: 1,
       recordSize: params.recordSize,
       pageSize: params.pageSize
     }
     // getuserList(searchParams, searchBody)
     setSbody(searchBody)
     setParams(searchParams)
   } else {
     // eslint-disable-next-line no-alert
     alert("두자이상만 검색 할 수 있습니다.")
     setCurrentPage(0)
     const searchBody = {
       totalSearch: '',
       userName: sbody.userName,
       companyName: sbody.companyName,
       partName: sbody.partName,
       email: sbody.email,
       phone: sbody.phone
     }

     const searchParams = {
       sortColumn: params.sortColumn,
       sortType: params.sortType,
       pageNum: 1,
       recordSize: params.recordSize,
       pageSize: params.pageSize
     }
     // getuserList(searchParams, searchBody)
     setSbody(searchBody)
     setParams(searchParams)
   }
 }

 //  페이지 변경 시
 const handlePageChange = (page: number) => {
   const searchBody = {
     totalSearch: searchText,
     userName: "",
     companyName: "",
     partName: "",
     email: "",
     phone: ""
   }
   const searchParams = {
     sortColumn: "",
     sortType: "ASC",
     pageNum: page,
     recordSize: 8,
     pageSize: 5
   }
   setSbody(searchBody)
   setParams(searchParams)
 }

 // 모달
 const [callModalOpen, setcallModalOpen] = useState(false);

 const showcallModal = () => {
   setcallModalOpen(true)
 }

 const handleOk = () => {
   setcallModalOpen(false)
 }

 const handleCancel = () => {
   setcallModalOpen(false)
 }

 // 유저 정보 모달
 const [createModalOpen, setCreateModalOpen] = useState(false);
 const showCreateModal = () => {
   setCreateModalOpen(true)
 }
 const handleCreateOk = () => {
   setCreateModalOpen(false)
 }
 const handleCreateCancel = () => {
   setCreateModalOpen(false)
 }

 const movepage = (targetName: string | number | '') => {
   if (presentname === '') {
     alert('name을 확인해 주세요.');
   }
   else {
     history.push(`/video?topic=${targetName}&name=${presentname}`)
     window.location.reload()
   }
 }

 // 유저 정보 모달
 const [userModalOpen, setUserModalOpen] = useState(false);
 // siderbar
 const [siderbar, setsiderbar] = useState(true)
 const settingSelectUser = (item: UserData) => {
   setSelectUser(item)
 }
 const showUserModal = (item: UserData) => {
   setUserModalOpen(true)
   setSelectUser(item)
 }
 const handleUserOk = () => {
   setUserModalOpen(false)
 }
 const handleUserCancel = () => {
   setUserModalOpen(false)
 }
 const setVideo = (item: UserData) => {
   if (presentname === "") {
     return
   } else {
     history.push(`/video?topic=${item.userId}&name=${presentname}`)
     // window.location.reload()
   }
 }

 const setPhone = (item: UserData) => {
   setcurrentUsername(item.userName)
   setcurrentUserTeams(item.teamName)
   showcallModal()
 }

 // 체크박스 저장
 const [checkedList, setCheckedList] = useState<number[]>([])
 const [checkedUsersData, setCheckedUsersData] = useState<UserData[]>([]);

 useEffect(() => {
   // checkedList가 변경될 때마다 checkedUsersData를 업데이트합니다.
   const checked = userList.filter((item) => checkedList.includes(item.userId));
   setCheckedUsersData(checked);
 }, [checkedList]);

 const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   const { checked } = event.target;
   if (checked) {
     setCheckedList(userList.map((item) => item.userId));
     setAllIsChecked(true);
   } else {
     setCheckedList([]);
     setAllIsChecked(false);
   }
 };
 const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, userId: number) => {
   const { checked } = event.target;
   if (checked) {
     setCheckedList((prevCheckedList) => [...prevCheckedList, userId]);
   } else {
     setCheckedList((prevCheckedList) => prevCheckedList.filter((id) => id !== userId));
   }
 };


 const deleteContact = (item: any) => {
   if (confirm("해당 연락처를 삭제하시겠습니까?")) {
     var data = {
       "sn": item.sn
     };
     axios.post('/feedback/contactLog/contactLog/delete', data)
       .then(response => {
         // 서버로부터 받은 데이터를 처리
         setTimeout(() => {
           contactList();
         }, 500);
       })
       .catch(error => {
         // 에러 처리
         console.error('contactLog 데이터를 로드하지 못 하였습니다. 기본 데이터를 로드합니다. :', error);
       });
   } else {
     return;
   }

 }

 // 연락처 리스트 조회
 const contactList = () => {
   console.log(checkedUsersData);
   // Do your desired operation here...
   const urlStr = window.location.href;
   const url = new URL(urlStr);
   const urlParams = url.searchParams;

   const name = urlParams.get('name');

   var data = {
     "myUserId": name,
     "totalSearch": contactSearchText,
   };
   axios.post('/feedback/contactLog/contactLog/select', data)
     .then(response => {
       setisLoading(false)
       // 서버로부터 받은 데이터를 처리
       setconcactUserList(response.data.contactLogData)
       settotalPages2(1)

       var data = {
         endPage: 1,
         existNextPage: false,
         existPrevPage: false,
         limitStart: 1,
         startPage: 1,
         totalPageCount: 1,
         totalRecordCount: 1
       }
       setPageInfo2(data)
     })
     .catch(error => {
       // 에러 처리
       console.error('contactLog 데이터를 로드하지 못 하였습니다. 기본 데이터를 로드합니다. :', error);
     });

 };

 // 검색
 const handleContactSearchText = (event: any) => {
   setContactSearchText(event.target.value);
 }

 // all Selct CheckBox
 const [allIsChecked, setAllIsChecked] = useState(false);

 const handleRegisterClick = () => {
   if (checkedUsersData.length == 0) {
     alert("선택하신 연락처가 없습니다.")
   } else {

     var duplicatesData = [];

     for (const obj1 of concactUserList) {
       for (const obj2 of checkedUsersData) {
         if (String(obj1.userId) == String(obj2.userId)) {
           duplicatesData.push(obj2);
           break; // 중복을 찾았으므로 다음 요소로 넘어감
         }
       }
     }
     if (duplicatesData.length > 0) {
       alert("이미 등록하신 연락처가 있습니다.");
       setCheckedList([]);
       setAllIsChecked(false);

     } else {
       if (confirm("해당 연락처를 추가하시겠습니까?")) {
         const urlStr = window.location.href;
         const url = new URL(urlStr);
         const urlParams = url.searchParams;

         const name = urlParams.get('name');

         for (let index = 0; index < checkedUsersData.length; index++) {
           const element = checkedUsersData[index];
           var data = {
             "myUserId": name,
             "userId": element.userId,
             "userName": element.userName,
             "companyId": element.companyId,
             "companyName": element.companyName,
             "partId": element.partId,
             "partName": element.partName,
             "teamId": element.teamId,
             "teamName": element.teamName,
             "tenantId": element.tenantId,
             "tenantName": element.tenantName,
             "tenantDomain": element.tenantDomain,
             "tenantType": element.tenantType,
             "email": element.email,
             "phone": element.phone,
             "description": element.description,
             "userGroup": element.userGroup,
             "userLevel": element.userLevel,
             "userImage": element.userImage,
           };
           axios.post('/feedback/contactLog/contactLog/insert', data)
             .then(response => {
               // 서버로부터 받은 데이터를 처리
               if (index == checkedUsersData.length - 1) {
                 setCheckedList([]);
                 setAllIsChecked(false);
                 setTimeout(() => {
                   contactList();

                   if(isMobile == true){
                     setselectmenu("즐겨찾기");
                     setleftmodalvisible("즐겨찾기");
                   }
                 }, 1000);
               }
             })
             .catch(error => {
               // 에러 처리
               console.error('contactLog 데이터를 로드하지 못 하였습니다. 기본 데이터를 로드합니다. :', error);
             });
         }

       } else {
         return;
       }
     }
   }
 }

 const [selectmenu, setselectmenu] = useState('즐겨찾기')
 const [leftmodalvisible, setleftmodalvisible] = useState('즐겨찾기')

  return (
            <div >
              <div style={{ width: "calc(100% - 10px)" }}>
                {/* 통화모달 */}
                <CallModal
                  isOpen={callModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  currentUsername={currentUsername}
                  currentUserteam={currentUserTeams}
                />
                {/* 검색창 */}

                <label className="relative flex" style={{ flex: "2", marginBottom: "15px" }}>
                  <input
                    className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                    placeholder="Search"
                    type="text"
                    style={{ paddingLeft: '34px' }}
                    value={searchText}
                    onChange={handleSearchChange}
                  />
                  <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
                    <svg xmlns="http:// www.w3.org/2000/svg" className="h-4 w-4 transition-colors duration-200" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z">
                      </path>
                    </svg>
                    {/* <button onClick={searchFnc} className="btn -mr-2 h-8 w-8 shrink-0 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25" style={{ border: "none", }}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5"><path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </button> */}
                  </span>
                  {/* <button
                    onClick={searchFnc}
                    className="btn -mr-2 h-8 w-8 shrink-0 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25"
                    style={{ border: "none" }}
                  >
                    <IconFont type="filter" />
                  </button> */}
                </label>
                {/* 연락처 테이블 */}
                <div className='contact-table-mb contact-scroll is-scrollbar-hidden mt-3 flex grow flex-col overflow-y-auto call-scroll cursor-default mb-table-height'>
                  <div className='contact-table-btn-mb'>
                    {/* <Button onClick={handleRegisterClick} style={{ marginRight: "5px" }}>등록</Button> */}
                    {/* <Button style={{ marginRight: "5px" }}> 그룹생성</Button> */}
                  </div>
                  {/* userList를 이용하여 사용자 목록 표시 */}
                 <div className='contact-table-btn' style={{ justifyContent: "flex-end"}}>
                    <Button onClick={handleRegisterClick} style={{ fontSize: "12px" }}>등록</Button>
                     {/* Button style={{ marginRight: "5px" }}> 그룹생성</Button> */}
                  </div>
                  <ul style={{ marginBottom: "30px" }}>
                    {userList.map((item, index) => (
                      <li className="ant-list-item" style={{padding:"9px 0"}} key={index}>
                        <input
                            type='checkbox'
                            checked={checkedList.includes(item.userId)}
                            onChange={(event) => handleRowCheckboxChange(event, item.userId)}
                            className='contact-mb-check'
                          />
                        <div className="contact-list-mb">
                          <div className="contact-list-avata-mb">
                            <div className="">
                              <div
                                className="fs-17 flex items-center justify-center rounded-full text-white dark:bg-accent"
                                style={{ background: "#d15a83", width: "35px", height: "35px" }}
                              >
                                {item?.userName[0]}
                              </div>
                            </div>
                          </div>
                          <div style={{ display: "flex", placeSelf: "center" }}></div>
                          <div className="ant-list-item-meta-content" style={{ alignItems: "center" }}>
                            <div className='contact-text-mb' style={{ alignItems: "center" }}>
                              <h4 className="ant-list-item-meta-title" style={{ wordBreak: "keep-all", margin: "0 5px 0 0" }}
                                onClick={() => {
                                  showUserModal(item)
                                }}>{item?.userName}
                              </h4>
                              <p className="text-muted contact_number" id="audiotext" style={{marginTop: "-3px"}}>
                                {item.phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)}</p>
                              {/* 팀/부서 */}
                              <div style={{ display: "flex" }}>
                                <span style={{ color: "#8a8a8a", fontSize: "12px" }}>{item.teamName} / {item.partName}</span>
                              </div>
                            </div>
                            {/* <div style={{ color: "#8a8a8a", fontSize: "12px" }}><p>010-9999-9999</p></div> */}
                          </div>
                        </div>
                        <a onClick={() => { movepage(item.userId) }} href="#" className='contact-mb-video-icon'>
                          <svg xmlns="http:// www.w3.org/2000/svg" width="18" height="19" fill="currentColor" className="bi bi-camera-video contact-video" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"></path>
                          </svg>
                        </a>
                        <a href="#" onClick={() => {
                          showcallModal()
                          setcurrentUsername(item.userName)
                          setcurrentUserTeams(item.teamName)
                        }}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5 fold-chat-width contact-mb-call-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5"><path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
                        </a>
                      </li>
                    ))}
                  </ul>
                  {/* 페이지네이션 */}
                  <ul className="contact-pagination-mb">
                    {pageInfo.existPrevPage ? (
                      <>
                        <li className="page-item">
                          <a className="page-link" href="#" onClick={() => handlePageChange(pageInfo.startPage)}>
                            <DoubleLeftOutlined className="fs-12" />
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#" onClick={() => handlePageChange(pageInfo.startPage - 1)}>
                            <LeftOutlined className="fs-12" />
                          </a>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Array.from({ length: pageInfo.totalPageCount }).map((_, index) => (
                      <li className="page-item" key={index}>
                        <a
                          className={`page-link ${currentPage === index + 1 ? 'pagenation-color' : ''}`}
                          href="#"
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </a>
                      </li>
                    ))}
                    {pageInfo.existNextPage ? (
                      <>
                        <li className="page-item">
                          <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
                            <RightOutlined className="fs-12" />
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#" onClick={() => handlePageChange(pageInfo.endPage)}>
                            <DoubleRightOutlined className="fs-12" />
                          </a>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                {/* 페이지네이션 */}
                {/* <Pagination defaultCurrent={1} total={2} style={{marginTop: "20px"}}/> */}
                </div>

                {/* 유저 정보 모달  */}
                {userModalOpen ?
                  <div className='contact-info-sidebar'>
                    {/* 제목 */}
                    <div className='chatinfo_title'>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ fontSize: "18px", color: "#3f4a5b", fontWeight: "500" }}> Details </span>
                      </div>

                      <div className='chat-head-icon' style={{ display: "flex" }}>
                        {/* <button className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5 text-slate-500 dark:text-navy-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ color: "#3f4a5b" }}>
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z">
                            </path>
                          </svg>
                        </button> */}
                        {/* 채팅 닫기 */}
                        <div style={{ cursor: "pointer", padding: "5px", fontSize: "15px" }} onClick={() => {
                          setUserModalOpen(false)

                        }}>
                          <RightOutlined />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-center" style={{ marginTop: "2.25rem" }}>
                      <div className="avatar-chat h-20 w-20">
                        {/* <img className="rounded-full" src={initialImage} alt="avatar" /> */}
                        <div className='fs-17 flex h-8 w-8 items-center justify-center rounded-full text-white dark:bg-accent chatinfo-name' style={{ background: "#0dbd8b" }}>{selectUser?.userName.charAt(0)}</div>
                      </div>
                      <h3 x-text="activeChat.name" className="mt-2 text-lg font-medium text-slate-700 dark:text-navy-100">{selectUser?.userName}</h3>
                      <p style={{ fontSize: "12px" }}> {selectUser?.description}</p>
                      <p style={{ fontSize: "12px" }}>{selectUser?.companyName}</p>

                      <div style={{ width: "100%", borderBottom: "1px solid #e9eef5", marginTop: "20px" }}></div>

                      <div style={{ marginTop: "7px", fontSize: "12px" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <p className="text-muted" id="audiotext" style={{ marginTop: "10px" }}>
                            <svg
                              style={{ marginRight: "7px", height: "20px" }}
                              xmlns="http:// www.w3.org/2000/svg"
                              className=""
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="1.5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                              />
                            </svg>
                          </p>
                          <p className="text-muted" id="audiotext" style={{ fontSize: "16px" }}>
                            {selectUser?.phone}
                          </p>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p className="text-muted" id="audiotext" style={{ marginTop: "10px" }}>
                            <MailOutlined className='mail-icon' />
                          </p>
                          <p className="text-muted" id="audiotext" style={{ fontSize: "16px" }}>
                            {selectUser?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> : null}
              </div>
             
        </div>
  )
}

export default ContactModules
