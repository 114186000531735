import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import { RouteComponentProps, useLocation, Link, useHistory, } from 'react-router-dom';
import { CarryOutOutlined, DownOutlined, TeamOutlined } from '@ant-design/icons';
import { UserAddOutlined, WhatsAppOutlined, FileDoneOutlined, UsergroupAddOutlined, SettingOutlined, RightOutlined, SendOutlined, LeftOutlined, CloseOutlined, BellOutlined, PaperClipOutlined, PlusOutlined, MailOutlined, LogoutOutlined } from '@ant-design/icons';

import { Tree, Button, Space, Input, Form, Pagination } from 'antd';
import type { DataNode, TreeProps } from 'antd/es/tree';

import Alarm from '../components/alarm';
import Contact from '../components/contact';
import File from '../components/file';
import Call from '../components/call'
import Chatting from '../components/chatting';
import Filemodal from '../components/filemodal';
import Groupmodal from '../components/groupmodal';
import { IconFont } from '../../../component/icon-font';
import logoImage from '../../../component/svgs/circlelogo.png';
import axios from 'axios';
import classNames from 'classnames';
import { Modal } from 'antd';
import "./group.scss";

import CallModal from '../../../component/callmodal'

/*
const treeData: DataNode[] = [
  {
    title: '부서',
    key: '0-0',
    children: [
      {
        title: '부서1',
        key: '0-0-0',
        children: [
          {
            title: '홍길동',
            key: '0-0-0-0',
          },
          {
            title: '홍길동',
            key: '0-0-0-1',
          },
          {
            title: '홍길동',
            key: '0-0-0-2',
          },
        ],
      },
      {
        title: '부서2',
        key: '0-0-1',
        children: [
          {
            title: '홍길동',
            key: '0-0-1-0',
          },
        ],
      },
      {
        title: '부서3',
        key: '0-0-2',
        children: [
          {
            title: '홍길동',
            key: '0-0-2-0',
          },
          {
            title: '홍길동',
            key: '0-0-2-1',
          },
        ],
      },
    ],
  },
];*/

interface HomeProps extends RouteComponentProps {
  //subsession에서 에러안나도록 ?처리
  status?: string;
  onLeaveOrJoinSession?: () => void;
}

interface UserData {
  full_name: string;
  department_name: string;
  title_name: string;
  email_address: string;
}

console.log('페이지 이동됨')
const Group: React.FunctionComponent<RouteComponentProps & HomeProps> = (props) => {

  const { history, status, onLeaveOrJoinSession } = props;
  const [treeData, setTreeData] = useState([]);
  const [userData, setUserData] = useState<any[]>([]);
  const [selectmenu, setselectmenu] = useState('조직도')
  const [leftmodalvisible, setleftmodalvisible] = useState('조직도')

  //유저 정보 모달
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };
  const handleCreateOk = () => {
    setCreateModalOpen(false);
  };
  const handleCreateCancel = () => {
    setCreateModalOpen(false);
  };

  // 유저 정보 모달
  const [userModalOpen, setUserModalOpen] = useState(false);

  const [selectUser, setSelectUser] = useState<UserData>();
  const settingSelectUser = (item: UserData) => {
    setSelectUser(item)
  }


  // sider 출력
  const [siderbar, setsiderbar] = useState(true)

  //컴포넌트출력함수
  const renderComponent = (type: string) => {
    switch (type) {
      case 'Feed':
        return <Alarm history={props.history} location={props.location} match={props.match} />;
      case 'Contact':
        return <Contact history={props.history} location={props.location} match={props.match} />;
      case 'Chatting':
        return <Chatting />;
      case '조직도':
        return (
          <div x-data="{expanded:true}">
            {/*검색창 */}
            <div className="flex px-4">
              <label className="relative mr-1.5 flex" style={{ flex: "2" }}>
                <input className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900" placeholder="Search" type="text" style={{ paddingLeft: "34px" }} />
                <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 transition-colors duration-200" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z">
                    </path>
                  </svg>
                </span>
              </label>

              {/* <button className="btn -mr-2 h-8 w-8 shrink-0 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25" style={{ border: "none", }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M22 6.5h-9.5M6 6.5H2M9 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM22 17.5h-6M9.5 17.5H2M13 20a2.5 2.5 0 100-5 2.5 2.5 0 000 5z">
                  </path>
                </svg>
              </button> */}
            </div>

            <div className="mt-4 flex items-center justify-between px-4">
              {/* <div className="-mr-1.5 flex">
            <button className="group-btn btn h-6 w-6 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </button>
          </div> */}
            </div>
            <div x-show="expanded" x-collapse="" className='treemenu is-scrollbar-hidden grow overflow-y-auto call-scroll' >
              <Tree
                showLine
                switcherIcon={<DownOutlined />}
                showIcon={true}
                defaultExpandedKeys={['0-0-0']}
                onSelect={onSelect}
                onClick={() => {
                  if (isMobile) {
                    setleftmodalvisible('')
                    setselectmenu('')
                  }
                }}
                treeData={treeData}
              />

              {/* 생성버튼 */}
              <div className='chat-createBtn' onClick={() => showCreateModal()}>
                <PlusOutlined />
              </div>
            </div >
          </div>
        );
      case 'Call':
        return <Call history={props.history} location={props.location} match={props.match} />;
      case 'File':
        return <File history={props.history} location={props.location} match={props.match} />;
      default:
        return null;
    }
  };
  useEffect(() => {
    getOrgList();
  }, []);


  const [isMobile, setIsMobile] = useState(false);
  // 모바일 여부 확인
  useEffect(() => {
    const checkIsMobile = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      setIsMobile(/mobile|android|iphone|ipad|webos|blackberry|windows phone/i.test(userAgent));
    };

    checkIsMobile(); // 최초 렌더링 시 기기 확인

    // 리사이징 시에도 기기 확인
    window.addEventListener('resize', checkIsMobile);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  // 조직도 리스트
  const getOrgList = () => {
    var param = {
      "recordCountPerPage": 10000,
      "pageIndex": 1,
    };
    axios.post('/feedback/organizations/selectOrganizations', param)
      .then(response => {
        // 서버로부터 받은 데이터를 처리
        console.log(response.data.resultList);
        var map: any = {}, node: any, roots: any = [], i;

        for (i = 0; i < response.data.resultList.length; i += 1) {
          map[response.data.resultList[i].key] = i; // initialize the map
          response.data.resultList[i].children = []; // initialize the children
        }

        for (i = 0; i < response.data.resultList.length; i += 1) {
          node = response.data.resultList[i];
          if (
            node.upr_department_code != "" &&
            node.upr_department_code != null
          ) {
            // if you have dangling branches check that map[node.parentId] exists
            //   console.log(node.upr_department_code)
            //   console.log(response.data.resultList[map[node.upr_department_code]])
            if (
              response.data.resultList[
              map[node.upr_department_code]
              ] != undefined
            ) {
              response.data.resultList[
                map[node.upr_department_code]
              ].children.push(node);
              node.icon = <TeamOutlined />;

            }
            else {
              node.icon = <TeamOutlined />;
              roots.push(node);
            }
          } else {
            node.icon = <TeamOutlined />;
            roots.push(node);
          }
        }
        console.log(roots)
        setTreeData(roots);
      })
      .catch(error => {
        alert("조직도 조회 중 에러가 발생하였습니다.")
      })
  };

  // 임직원 리스트
  const getuserList = (deptId: any) => {
    var param = {
      "recordCountPerPage": 1000,
      "pageIndex": 1,
      "departmentCode": deptId,
    };
    axios.post('/feedback/employee/selectEmployee', param)
      .then(response => {
        // 서버로부터 받은 데이터를 처리
        console.log(response.data.resultList);

        setUserData(response.data.resultList);
      })
      .catch(error => {
        alert("임직원 조회 중 에러가 발생하였습니다.")
      })
  };


  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
    // 트리 선택 취소 할 경우
    if (selectedKeys.length == 0) {
      setUserData([]);
    } else {
      getuserList(selectedKeys[0]);
    }
  }


  // video Page 이동
  const [presentname, setPresentnamet] = useState(sessionStorage.getItem("username")) //  사용자 정
  const movepage = (targetName: string | number | '') => {
    if (presentname === '') {
      alert('name을 확인해 주세요.');
    }
    else {
      history.push(`/video?topic=${targetName}&name=${presentname}`)
      window.location.reload()
    }
  }



  // 모달
  const [callModalOpen, setcallModalOpen] = useState(false);

  const [currentUsername, setcurrentUsername] = useState("")
  const [currentUserTeams, setcurrentUserTeams] = useState("")

  const showcallModal = () => {
    setcallModalOpen(true)
  }

  const handleOk = () => {
    setcallModalOpen(false)
  }

  const handleCancel = () => {
    setcallModalOpen(false)
  }

  const showUserModal = (item: UserData) => {
    setUserModalOpen(true)
    setSelectUser(item)
  }


  return (
    <div>
      {siderbar ?
        <div className='main-sidebar ts-dropdown-content accordionfix video_style1'>
          {/* 로고 */}
          <div className='left_logo'>
            <a href="#" style={{ marginTop: "0" }}>
              <img src={logoImage} style={{ width: "30px", height: "30px" }} onClick={() => history.push(`/${location.search}`)} />
            </a>
          </div>
          <div className='side-icon' style={{ marginTop: "80px" }}>
            {/* lfet icon */}
            <div className={selectmenu === 'Feed' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Feed')
              //setleftmodalvisible('Feed')
              history.push(`/alarm${location.search}`)
              console.log(selectmenu)
            }}>
              <BellOutlined />
              <p className='icon_fs'>활동</p>
            </div>

            <div className={selectmenu === 'Chatting' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Chatting')
              //setleftmodalvisible('Chatting')
              history.push(`/chat${location.search}`)
              console.log(selectmenu)
            }}>
              <IconFont type="icon-chat" />
              <p className='icon_fs'>채팅</p>
            </div>

            <div className={selectmenu === 'Contact' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Contact')
              //setleftmodalvisible('Contact')
              history.push(`/contact${location.search}`)
              console.log(selectmenu)
            }}>
              <UserAddOutlined />
              <p className='icon_fs'>연락처</p>
            </div>
            <div className={selectmenu === '조직도' ? 'selectedmenu' : ''} onClick={() => {
              setselectmenu('조직도')
              setleftmodalvisible('조직도')
              history.push(`/group${location.search}`)
              //console.log(selectmenu)
            }}>
              <UsergroupAddOutlined />
              <p className='icon_fs'>조직도</p>
            </div>

            <div className={selectmenu === 'Call' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Call')
              //setleftmodalvisible('Call')
              history.push(`/call${location.search}`)
              console.log(selectmenu)
            }}>
              <WhatsAppOutlined />
              <p className='icon_fs'>통화</p>
            </div>

            <div className={selectmenu === 'File' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('File')
              //setleftmodalvisible('File')
              history.push(`/file${location.search}`)
              console.log(selectmenu)
            }}>
              <FileDoneOutlined />
              <p className='icon_fs'>파일</p>
            </div>

          </div>

          <div className='side-setting-icon'>
            <div className='whitemode'>
              <a><IconFont type="white-mode" /></a>
              {/* <p className='icon_fs'>화이트모드</p> */}
            </div>

            <div className='setline' onClick={() => { history.push(`/setting${location.search}`) }}>
              <SettingOutlined />
              {/* <p className='icon_fs'>설정</p> */}
            </div>

            <div>
              <a href="/"><LogoutOutlined className='logout_btn' /></a>
              {/* <p className='icon_fs'>로그아웃</p> */}
            </div>
          </div>



        </div> : null}

      {/* left modal */}
      {(!(leftmodalvisible === '') && (leftmodalvisible === selectmenu)) ?
        <div className='left_modal group-left'>
          <div className='flex_style left_modal_title'>
            <h3>{selectmenu}</h3>
            <CloseOutlined onClick={() => {
              setleftmodalvisible('')
              setselectmenu('')
            }} />
          </div>
          {renderComponent(selectmenu)}
        </div>
        : null}
      <div className="flex h-[calc(100%-4.5rem)] grow flex-col group-content">
        <div className="is-scrollbar-hidden flex grow flex-col">

          {/* 본문 트리메뉴 */}
          <div x-data="{expanded:true}" className={classNames('group-tree-content', { 'group-tree-hidden': !leftmodalvisible })}
            style={
              siderbar ? { position: "relative" } : { position: "relative", marginLeft: "-10px" }
            }>
            {/* 헤더 */}
            <div className='group-header-nav'>
              <div className='group-header-name'>
                <h3 style={{ fontSize: "16px" }}>임직원</h3>
              </div>
              <div className='group-header-btn'>
                {/* <button className="btn h-9 w-9 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z">
                    </path>
                  </svg>
                </button> */}
                {/* <button className="btn hidden h-9 w-9 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:flex text-slate-500 dark:text-navy-200 text-primary dark:text-accent-light">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.25 21.167h5.5c4.584 0 6.417-1.834 6.417-6.417v-5.5c0-4.583-1.834-6.417-6.417-6.417h-5.5c-4.583 0-6.417 1.834-6.417 6.417v5.5c0 4.583 1.834 6.417 6.417 6.417ZM13.834 2.833v18.334">
                    </path>
                  </svg>
                </button> */}
                {/* <button x-ref="popperRef" className="btn h-9 w-9 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 text-slate-500">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z">
                    </path>
                  </svg>
                </button> */}
                <button onClick={() => {
                  //siderbar 닫기

                  setsiderbar(!siderbar)
                  setleftmodalvisible('')
                  setselectmenu('')
                }} className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5 text-slate-500 dark:text-navy-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                  </svg>
                </button>
              </div>

            </div>
            <div className='group-table-wrap' style={{ padding: "0px 0 0 32px" }}>
              {
                //모바일 시작
                isMobile ?
                  // <div style={{ width: "calc(100% - 22px)", marginTop: "50px", fontSize: "13px" }}>
                  //   <p>모바일 ui 작업 필요</p>
                  //   <div className='group-table-wrap-mb'>
                  //     <div className='group-table-btn-mb' style={{ textAlign: "right" }}>
                  //       {/* <Button style={{ marginRight: "5px", fontSize: "13px" }}>등록</Button> */}
                  //       {/* <Button style={{ fontSize: "13px" }}>채팅생성</Button> */}
                  //       {/* <Button style={{ fontSize: "13px" }} >confluence 생성</Button> */}
                  //     </div>
                  //     <table className='group-table-mb' >
                  //       <thead>
                  //         {/* <th><input type="checkbox" /></th> */}
                  //         <th>이름</th>
                  //         <th>부서</th>
                  //         <th>직책</th>
                  //       </thead>
                  //       <tbody>
                  //         {
                  //           userData.length === 0 ? (
                  //             <tr aria-colspan={4}>
                  //               <td colSpan={4} style={{ textAlign: "center" }}>조회된 임직원 리스트가 없습니다.</td>
                  //             </tr>
                  //           )
                  //             :
                  //             (

                  //               // 임직원 리스트 있을 때
                  //               userData.map((item, index) => {
                  //                 return (
                  //                   <tr key={index}>
                  //                     {/* <td><input type="checkbox" /></td> */}
                  //                     <td>
                  //                       <a onClick={() => {
                  //                         setSelectUser(item)
                  //                         console.log(selectUser)
                  //                         setUserModalOpen(!userModalOpen)
                  //                       }}>{item.full_name}
                  //                       </a>
                  //                     </td>
                  //                     <td>{item.department_name}</td>
                  //                     <td>{item.title_name}</td>
                  //                   </tr>
                  //                 )
                  //               })
                  //             )
                  //         }
                  //       </tbody>
                  //     </table>
                  //   </div>
                  // </div>
                  <div style={{ width: "calc(100% - 20px)" }}>

                    <label className="relative mr-1.5 flex" style={{ flex: "2", marginBottom: "20px" }}>
                      <input
                        className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                        placeholder="Search"
                        type="text"
                        style={{ paddingLeft: '34px' }}
                      // value={searchText}
                      // onChange={handleSearchChange}
                      />
                      <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
                        <svg xmlns="http:// www.w3.org/2000/svg" className="h-4 w-4 transition-colors duration-200" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z">
                          </path>
                        </svg>
                        {/* <button onClick={searchFnc} className="btn -mr-2 h-8 w-8 shrink-0 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25" style={{ border: "none", }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5"><path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                      </button> */}
                      </span>
                      {/* <button
                      className="btn -mr-2 h-8 w-8 shrink-0 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25"
                      style={{ border: "none" }}
                    >
                      <IconFont type="filter" />
                    </button> */}
                    </label>
                    {/* 연락처 테이블 */}
                    <div className='contact-table-mb contact-scroll is-scrollbar-hidden mt-3 flex grow flex-col overflow-y-auto call-scroll cursor-default mb-table-height'>
                      <div className='contact-table-btn-mb'>
                        {/* <Button onClick={handleRegisterClick} style={{ marginRight: "5px" }}>등록</Button> */}
                        {/* <Button style={{ marginRight: "5px" }}> 그룹생성</Button> */}
                      </div>
                      {/* userList를 이용하여 사용자 목록 표시 */}
                      {/* <div className='contact-table-btn'>
                      <Button onClick={handleRegisterClick} style={{ marginRight: "5px" }}>등록</Button>
                      <Button style={{ marginRight: "5px" }}> 그룹생성</Button>
                    </div> */}
                      <ul style={{ marginBottom: "30px" }}>
                        {
                          userData.length === 0 ? (
                            <div>
                              <li className="ant-list-item">
                                <div className="contact-list-mb">
                                  <div style={{ display: "flex", placeSelf: "center" }}></div>
                                  <div className="ant-list-item-meta-content" style={{ alignItems: "center" }}>
                                    <div className='contact-text-mb' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                      <span style={{ color: "#8a8a8a" }}>조회된 리스트 없음</span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </div>
                          )
                            :
                            (

                              // 임직원 리스트 있을 때
                              userData.map((item, index) => {
                                return (
                                  <div>
                                    <li className="ant-list-item">
                                      <div className="contact-list-mb">
                                        <div className="contact-list-avata-mb">
                                          <div className="">
                                            <div
                                              className="fs-17 flex items-center justify-center rounded-full text-white dark:bg-accent"
                                              onClick={() => {
                                                showUserModal(item)
                                              }}
                                              style={{ background: "#d15a83", width: "35px", height: "35px" }}>
                                              {item.full_name[0]}
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{ display: "flex", placeSelf: "center" }}></div>
                                        <div className="ant-list-item-meta-content" style={{ alignItems: "center" }}>
                                          <div className='contact-text-mb' style={{ display: "flex", alignItems: "center" }}
                                            onClick={() => {
                                              showUserModal(item)
                                            }}>
                                            <h4 className="ant-list-item-meta-title" style={{ wordBreak: "keep-all", margin: "0 5px 0 0", display: "inline" }}>
                                              {item.full_name}
                                            </h4>
                                            <span style={{ color: "#8a8a8a", fontSize: "12px" }}>{item.department_name}</span>
                                            {/* <span style={{ color: "#8a8a8a", fontSize: "12px" }}>{item.title_name}</span> */}
                                          </div>
                                        </div>
                                      </div>
                                      <a onClick={() => { movepage(item.user_id) }} style={{ marginRight: "7px" }}>
                                        <svg xmlns="http:// www.w3.org/2000/svg" width="18" height="19" fill="currentColor" className="bi bi-camera-video contact-video" viewBox="0 0 16 16">
                                          <path fillRule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"></path>
                                        </svg>
                                      </a>
                                      <a href="#" onClick={() => {
                                        showcallModal()
                                        setcurrentUsername(item.full_name)
                                        // setcurrentUserTeams(item.team_name)
                                        setcurrentUserTeams(item.department_name)
                                      }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "19px", marginLeft: "5px" }} className="h-5.5 w-5.5 fold-chat-width" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5"><path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
                                      </a>
                                    </li>
                                  </div>
                                )
                              })
                            )
                        }

                      </ul>
                    </div>
                  </div>
                  :
                  //웹 시작              
                  <div className="flex items-center file-search" style={{ flexDirection: "column", marginTop: "34px" }}>
                    {/* <div className='group-table-btn'>
                    <Button style={{ marginRight: "5px" }}>등록</Button>
                    <Button style={{ marginRight: "5px" }}>채팅생성</Button>
                    <Button>confluence 생성</Button>
                  </div> */}

                    {/* 검색영역 시작 */}
                    <div className="flex" style={{ justifyContent: "flex-end", width: "100%" }}>
                      <div className="flex file-head-icon">
                        <div className="table-search-wrapper flex items-center">
                          {/* <button className="table-search-toggle btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="#3f4a5b">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                            </svg>
                          </button> */}
                        </div>
                        <div id="dropdown-folders-table" className="inline-flex">
                          {/* <button className="popper-ref btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="#3f4a5b">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z">
                              </path>
                            </svg>
                          </button> */}
                        </div>
                      </div>
                    </div>
                    {/* 웹 검색 */}
                    <div className="flex" style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                      <label className="relative mr-1.5 flex" style={{ width: "30%", margin: "10px 0 0 0" }}>
                        <input className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900" placeholder="Search" type="text" value="" style={{ paddingLeft: "34px" }} />
                        <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
                          <span role="img" className="anticon">
                            <svg xmlns="http:// www.w3.org/2000/svg" className="" fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" aria-hidden="true" focusable="false">
                              <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z"></path>
                            </svg>
                          </span>
                        </span>
                      </label>
                    </div>
                    {/* 테이블 영역 */}
                    <table className='group-table' >
                      <thead>
                        {/* <th><input type="checkbox" /></th> */}
                        <th>이름</th>
                        <th>부서</th>
                        <th>직책</th>
                      </thead>
                      <tbody>
                        {
                          userData.length === 0 ? (
                            <tr aria-colspan={4}>
                              <td colSpan={4} style={{ textAlign: "center" }}>조회된 임직원 리스트가 없습니다.</td>
                            </tr>
                          )
                            :
                            (

                              // 임직원 리스트 있을 때
                              userData.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    {/* <td><input type="checkbox" /></td> */}
                                    <td onClick={() => {
                                      showUserModal(item)
                                    }}>{item.full_name}</td>
                                    <td>{item.department_name}</td>
                                    <td>{item.title_name}</td>

                                  </tr>
                                )
                              })
                            )
                        }
                      </tbody>
                    </table>

                    {/* 페이지네이션 */}
                    <div className='pagination-web'>
                      <div>1</div>
                    </div>
                  </div>

              }
            </div>
          </div >
        </div >
      </div >

      {/* 통화모달 */}
      <CallModal
        isOpen={callModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        currentUsername={currentUsername}
        currentUserteam={currentUserTeams}
      />

      {/* 생성 모달  */}
      <Modal className='group-create-modal' title="" open={createModalOpen} onOk={handleCreateOk} onCancel={handleCreateCancel}>
        <div style={{ marginTop: "20px" }}>
          <Form.Item label="이름">
            <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item label="부서">
            <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item label="직책">
            <Input placeholder="input placeholder" />
          </Form.Item>
          <div className='group-create-footer'>
            <Button className='ant-btn' style={{ marginRight: "5px", marginTop: "15px" }}>취소</Button>
            <Button type="primary" className='filesendbtn'>등록</Button>
          </div>
        </div>
      </Modal>
      {/* 유저 정보 모달  */}
      {userModalOpen ?
        <div className='group-info-sidebar'>
          {/* 제목 */}
          <div className='chatinfo_title'>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ fontSize: "18px", margin: "0 0 0 6px", color: "#3f4a5b", fontWeight: "500" }}> Details </span>
            </div>

            <div className='chat-head-icon' style={{ display: "flex" }}>
              {/* <button className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5 text-slate-500 dark:text-navy-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ color: "#3f4a5b" }}>
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z">
                  </path>
                </svg>
              </button> */}
              {/* 채팅 닫기 */}
              <div style={{ cursor: "pointer", padding: "5px", fontSize: "15px" }} onClick={() => {
                setUserModalOpen(false)

              }}>
                <RightOutlined />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center" style={{ marginTop: "2.25rem" }}>
            <div className="avatar-chat h-20 w-20">
              {/* <img className="rounded-full" src={initialImage} alt="avatar" /> */}
              <div className='fs-17 flex h-8 w-8 items-center justify-center rounded-full text-white dark:bg-accent chatinfo-name' style={{ background: "#0dbd8b" }}>{selectUser?.full_name.charAt(0)}</div>
            </div>
            <h3 x-text="activeChat.name" className="mt-2 text-lg font-medium text-slate-700 dark:text-navy-100" style={{ marginBottom: "13px" }}>{selectUser?.full_name}</h3>
            <p style={{ marginBottom: "3px" }}> {selectUser?.department_name}</p>
            <p>{selectUser?.title_name}</p>

            <div style={{ width: "100%", borderBottom: "1px solid #e9eef5", marginTop: "20px" }}></div>

            <div style={{ marginTop: "7px", fontSize: "12px" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <p className="text-muted" id="audiotext" style={{ marginTop: "10px" }}>
                  <svg
                    style={{ marginRight: "7px", height: "20px" }}
                    xmlns="http:// www.w3.org/2000/svg"
                    className=""
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="1.5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                </p>
                <p className="text-muted" id="audiotext" style={{ fontSize: "16px" }}>
                  010-3333-9999
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="text-muted" id="audiotext" style={{ marginTop: "10px" }}>
                  <MailOutlined className='mail-icon' />
                </p>
                <p className="text-muted" id="audiotext" style={{ fontSize: "16px" }}>
                  {selectUser?.email_address}
                </p>
              </div>
            </div>
          </div>
        </div>
        : null}

    </div >
  );
};
export default Group;