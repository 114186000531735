import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import './call.scss';
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom"
import { DoubleLeftOutlined, DoubleRightOutlined, LoadingOutlined, MailOutlined, PlusOutlined } from '@ant-design/icons';

import { Button, Modal, Spin } from 'antd';
import { IconFont } from '../../../component/icon-font';
import CallModal from '../../../component/callmodal'
import FormattedDate from './formatteddate'
const CallModules = () => {


    interface historyData {
        meeting_id: string,
        sender_id: string,
        receive_id: string,
        group_id: string,
        gubun: string,
        service_gubun: string,
        meeting_start_time: string,
        meeting_end_time: string,
        frst_register_id: string,
        frst_regist_pnttm: string,
        last_updusr_id: string,
        last_updt_pnttm: string,
        userName: string,
        userId: string,
        teamName: string,
    }

    interface Params {
        sortColumn: string
        sortType: string
        pageNum: number
        recordSize: number
        pageSize: number
    }

    const initParams: Params = {
        sortColumn: "",
        sortType: "ASC",
        pageNum: 1,
        recordSize: 10,
        pageSize: 5
    }

    interface PageInfo {
        endPage: number
        existNextPage: boolean
        existPrevPage: boolean
        limitStart: number
        startPage: number
        totalPageCount: number
        totalRecordCount: number
    }
    const initialPageInfo: PageInfo = {
        endPage: 1,
        existNextPage: false,
        existPrevPage: false,
        limitStart: 0,
        startPage: 1,
        totalPageCount: 0,
        totalRecordCount: 0
    }

    interface UserData {
        userId: number;
        userName: string;
        companyId: number;
        companyName: string;
        partId: number;
        partName: string,
        teamId: number,
        teamName: string,
        tenantId: 2,
        tenantName: string,
        tenantDomain: string,
        tenantType: string,
        email: string,
        phone: string,
        description: string,
        useAt: string,
        userGroup: string,
        userLevel: string,
        userImage: string,
        frstRegistPnttm: string,
        frstRegisterId: number,
        lastUpdtPnttm: string,
        lastUpdusrId: number
        // 다른 필드들의 타입도 여기에 추가
    }
    interface UserParams {
        userName: string
        companyName: string
        partName: string
        email: string
        phone: string
    }

    const initUserParams = {
        userName: "",
        companyName: "",
        partName: "",
        email: "",
        _phone: "",
        get phone() {
            return this._phone
        },
        set phone(value) {
            this._phone = value
        }
    }
    interface HomeProps extends RouteComponentProps {
        //subsession에서 에러안나도록 ?처리
        status?: string;
        onLeaveOrJoinSession?: () => void;
    }

   //브라우저 파라미터 정보 저장
   const history = useHistory();
   const location = useLocation();
   const urlParams = new URLSearchParams(location.search);
   const presentname = urlParams.get('name') || ''
   const [isLoading, setisLoading] = useState(true)
   //연락처 정보 저장
   const [callList, setcallList] = useState<UserData[]>([]);;
   const [callinfo, setcallinfo] = useState(false);
 
 
 
   // 연락처 conctact user list
   const [concactUserList, setconcactUserList] = useState<UserData[]>([]);
   const [totalPages2, settotalPages2] = useState(0) //  토탈 페이지
   const [pageInfo2, setPageInfo2] = useState<PageInfo>(initialPageInfo) //  ajax 로 받아오는 
   const [contactSearchText, setContactSearchText] = useState("")
 
   //  연락처 정보 저장
   const [userList, setuserList] = useState<UserData[]>([]) //  사용자 리스트
   const [selectUser, setSelectUser] = useState<UserData | historyData>();
   const [selectHistoryUser, setselectHistoryUser] = useState<historyData>();
   const [sbody, setSbody] = useState<UserParams>(initUserParams) //  검색 파라미터
   const [totalPages, settotalPages] = useState(0) //  토탈 페이지
   const [pageInfo, setPageInfo] = useState<PageInfo>(initialPageInfo) //  ajax 로 받아오는 
 
 
   // history 정보
   const [historyInfo, setHistoryInfo] = useState<historyData[]>([]);
   useEffect(() => {
     getCallList();
     getCallHistoryList();
     contactList();
   }, []);
 
   const getCallList = () => {
     axios.get('/ippcmpapi/cmpUsers')
       .then(response => {
         // 서버로부터 받은 데이터를 처리
         console.log(response.data.cmpUsers);
         //데이터 파싱
         const paseddata = response.data.cmpUsers.map((item: UserData) => {
           const { userId, userName, teamName } = item;
           return { userId, userName, teamName };
         });
         //데이터 세팅
         setcallList(paseddata)
         setisLoading(!isLoading)
       })
       .catch(error => {
         // 에러 처리
         console.error('cmpportal에 접속하지 못했습니다. 기본 데이터를 로드합니다. :', error);
 
         setisLoading(!isLoading)
       });
 
 
   };
   const getCallHistoryList = () => {
 
     const urlStr = window.location.href;
     const url = new URL(urlStr);
     const urlParams = url.searchParams;
 
     const name = urlParams.get('name');
 
     const topic = urlParams.get('topic');
 
     var data = {
       senderId: name
     };
     axios.post('/feedback/callLog/callLog/select', data)
       .then(response => {
         // 서버로부터 받은 데이터를 처리
         setHistoryInfo(response.data.callLogData);
       })
       .catch(error => {
         // 에러 처리
         console.error('History 데이터를 로드하지 못 하였습니다. 기본 데이터를 로드합니다. :', error);
         setisLoading(!isLoading)
       });
   }
 
 
   // side_icon메뉴 선택 저장변수
   const [selectmenu, setselectmenu] = useState('Call')
   const [leftmodalvisible, setleftmodalvisible] = useState('Call')
   // 클릭된 유저 정보 저장
   const [currentUsername, setcurrentUsername] = useState('');
   const [currentUserTeams, setcurrentUserTeams] = useState('');
 
   // 검색기능
   const [searchText, setSearchText] = useState('');
   // 검색창의 값을 받아 searchText에 저장
   const handleSearchChange = (event: any) => {
     setSearchText(event.target.value);
   };
   // 검색
   const filteredList = callList.filter((item) =>
     item.userName.toLowerCase().includes(searchText.toLowerCase())
   );
 
   // 모달
   const [callModalOpen, setcallModalOpen] = useState(false);
 
   // siderbar 출력
   const [siderbar, setsiderbar] = useState(true);
 
   const showcallModal = () => {
     setcallModalOpen(true);
   };
 
   // 모달 통화 버튼 눌렀을 때 실행되는 함수
   const handleOk = () => {
     // setcallModalOpen(false);
     const urlStr = window.location.href;
     const url = new URL(urlStr);
     const urlParams = url.searchParams;
 
     const name = urlParams.get('name');
 
     const topic = urlParams.get('topic');
     console.log(currentUsername)
     var data = {
       senderId: name,
       receiveId: currentUsername,
       gubun: "outComming",
       serviceGubun: "history",
 
     };
     axios.post('/feedback/callLog/callLog/insert', data)
       .then(response => {
         // 서버로부터 받은 데이터를 처리
         setcallModalOpen(false);
         setTimeout(() => {
           getCallHistoryList();
         }, 1000);
       })
       .catch(error => {
         // 에러 처리
         console.error('History 데이터를 로드하지 못 하였습니다. 기본 데이터를 로드합니다. :', error);
         setisLoading(!isLoading)
       });
   };
 
   const handleCancel = () => {
     setcallModalOpen(false);
   };
 
   const movepage = (targetName: string | '') => {
     if (presentname === '') {
       alert('name을 확인해 주세요.');
     }
     else {
       history.push(`/video?topic=${targetName}&name=${presentname}`)
       window.location.reload()
     }
   }
 
 
   // 연락처 리스트 조회
   const contactList = () => {
     // Do your desired operation here...
     const urlStr = window.location.href;
     const url = new URL(urlStr);
     const urlParams = url.searchParams;
 
     const name = urlParams.get('name');
 
     var data = {
       "myUserId": name,
       "totalSearch": contactSearchText,
     };
     axios.post('/feedback/contactLog/contactLog/select', data)
       .then(response => {
         setisLoading(false)
         // 서버로부터 받은 데이터를 처리
         setconcactUserList(response.data.contactLogData)
         settotalPages2(1)
 
         var data = {
           endPage: 1,
           existNextPage: false,
           existPrevPage: false,
           limitStart: 1,
           startPage: 1,
           totalPageCount: 1,
           totalRecordCount: 1
         }
         setPageInfo2(data)
       })
       .catch(error => {
         // 에러 처리
         console.error('contactLog 데이터를 로드하지 못 하였습니다. 기본 데이터를 로드합니다. :', error);
       });
 
   };
   // 검색
   const handleContactSearchText = (event: any) => {
     setContactSearchText(event.target.value);
   }
 
 
   // 유저 정보 모달
   const [userModalOpen, setUserModalOpen] = useState(false);
   const settingSelectUser = (item: UserData) => {
     setSelectUser(item)
   }
   const showUserModal = (item: UserData) => {
     setcurrentUsername(item.userName)
     setcurrentUserTeams(item.teamName)
     setcallModalOpen(true)
     setSelectUser(item)
   }
 
   const setVideo = (item: UserData) => {
     if (presentname === "") {
       return
     } else {
       history.push(`/video?topic=${item.userId}&name=${presentname}`)
       // window.location.reload()
     }
   }
 
   const setPhone = (item: UserData) => {
     setcurrentUsername(item.userName)
     setcurrentUserTeams(item.teamName)
     showcallModal()
   }
 
 
 
   const [isMobile, setIsMobile] = useState(false);
   // 모바일 여부 확인
   useEffect(() => {
     const checkIsMobile = () => {
       const userAgent = navigator.userAgent.toLowerCase();
       setIsMobile(/mobile|android|iphone|ipad|webos|blackberry|windows phone/i.test(userAgent));
     };
 
     checkIsMobile(); // 최초 렌더링 시 기기 확인
 
     // 리사이징 시에도 기기 확인
     window.addEventListener('resize', checkIsMobile);
 
     // 컴포넌트 언마운트 시 리스너 제거
     return () => {
       window.removeEventListener('resize', checkIsMobile);
     };
   }, []);
   // 역순 출력
   const reversedHistoryInfo = historyInfo.slice().reverse();
    return (
        <table className='call-table-mb' style={{width:"97%"}}>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={4}>
                <LoadingOutlined style={{ fontSize: "30px", marginTop: "20px" }} />
              </td>
            </tr>
          ) : reversedHistoryInfo.length > 0 ? (
            reversedHistoryInfo.map((item, index) => (
              <tr onClick={() => {
                // 오류로 임시 비활성화
                // setSelectUser(item)
                // setcallinfo(true)

              }} key={index}>
                <td>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="name-circle name-circle-size name-circle-mb flex items-center justify-center rounded-full bg-primary text-white dark:bg-accent cursor-default" style={{ width: "41px", margin:"0 8px" }}>
                      {item?.receive_id[0]}
                    </div>
                    <div className='' style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                      <div style={{ display: "flex" }}>
                        <div style={{}}>
                          <div style={{ alignSelf: "center" }}>
                            <div className='' style={{ textAlign: "left" }}>
                              <a style={{ marginRight: "5px" }}>{item?.receive_id}</a>
                              <p style={{ color: "#8a8a8a", fontSize: "12px", wordBreak: "keep-all", margin: "5px 0 0 0" }}>개발 CMP</p>
                            </div>
                          </div>
                          <div className='call-time-mb' style={{ fontSize: "12px", color: "rgb(138, 138, 138)" }}>

                          
                          {console.log(item?.meeting_start_time)}
                        </div> 
                        </div>
                      </div>

                      <div style={{ color: "#8a8a8a", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {
                          item?.gubun == "inComming" ?
                            <>
                              <svg style={{ color: "#8a8a8a", marginTop: "6px", display: "flex" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-inbound" viewBox="0 0 16 16">
                                <path d="M15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0zm-12.2 1.182a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                              </svg>
                              <span style={{ marginLeft: "8px", alignSelf: "center", fontSize: "13px" }}> <FormattedDate dateStr={item?.meeting_start_time} /></span>
                            </>
                            :
                            <>
                              <svg style={{ width: "14px", height: "14px", marginTop: "4px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-outbound" viewBox="0 0 16 16">
                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                              </svg>
                              <span style={{ marginLeft: "8px", alignSelf: "center", fontSize: "13px" }}> <FormattedDate dateStr={item?.meeting_start_time} /></span>
                            </>
                        }

                      </div>
                    </div>
                  </div>
                </td>
                {/* <td>
                  모바일 통화 모달
                  <a onClick={() => {
                    showcallModal()
                    setcurrentUsername(item?.receive_id)
                    setcurrentUserTeams(item?.group_id)
                  }}>
                      <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "17px", marginLeft: "5px", marginTop: "4px" }} className="h-5.5 w-5.5 fold-chat-width" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5"><path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg></a>
                </td> */}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                조회된 데이터가 없습니다.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    )

}

export default CallModules