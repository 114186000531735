import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import { RouteComponentProps, useLocation, Link, useHistory, } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { UserAddOutlined, WhatsAppOutlined, FileDoneOutlined, UsergroupAddOutlined, SettingOutlined, RightOutlined, SendOutlined, LeftOutlined, CloseOutlined, BellOutlined, PaperClipOutlined, TeamOutlined, DownloadOutlined, FolderOutlined, PlusOutlined, LogoutOutlined } from '@ant-design/icons';

import Alarm from '../components/alarm';
import Contact from '../components/contact';
//import File from '../components/file';
import Call from '../components/call'
import Chatting from '../components/chatting';
import Group from '../components/group';
import Filemodal from '../components/filemodal';
import Groupmodal from '../components/groupmodal';
import classNames from 'classnames';
import './file.scss';
import './call.scss';
import { IconFont } from '../../../component/icon-font';
import logoImage from '../../../component/svgs/circlelogo.png';
import type { DataNode, DirectoryTreeProps } from 'antd/es/tree';
import { Tree, Modal, Form, Input, Button, Pagination } from 'antd';
const { DirectoryTree } = Tree;


const treeData: DataNode[] = [
  {
    title: '내 파일',
    key: '0-0',
    children: [
      {
        title: (
          <div className='tree-fill-wrap'>
            <span style={{ marginRight: '8px' }}>내 파일 1</span>
            <DownloadOutlined className='file-downicon' />
          </div>), key: '0-0-0', isLeaf: true
      },
      {
        title: (
          <div className='tree-fill-wrap'>
            <span style={{ marginRight: '8px' }}>내 파일 2</span>
            <DownloadOutlined className='file-downicon' />
          </div>), key: '0-0-1', isLeaf: true
      },
    ],
    icon: <FolderOutlined style={{ fontSize: '19px', color: '#3f4a5b' }} />,
  },
  {
    title: '공유',
    key: '0-1',
    children: [
      {
        title: (
          <div className='tree-fill-wrap'>
            <span style={{ marginRight: '8px' }}>공유 파일 1</span>
            <DownloadOutlined className='file-downicon' />
          </div>), key: '0-1-0', isLeaf: true
      },
      {
        title: (
          <div className='tree-fill-wrap'>
            <span style={{ marginRight: '8px' }}>공유 파일 2</span>
            <DownloadOutlined className='file-downicon' />
          </div>), key: '0-1-1', isLeaf: true
      },
    ],
    icon: <TeamOutlined style={{ fontSize: '19px', color: '#3f4a5b' }} />
  },

];
interface HomeProps extends RouteComponentProps {
  //subsession에서 에러안나도록 ?처리
  status?: string;
  onLeaveOrJoinSession?: () => void;
}
const File: React.FunctionComponent<RouteComponentProps & HomeProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [selectmenu, setselectmenu] = useState('File')
  const [leftmodalvisible, setleftmodalvisible] = useState('File');

  const [isMobile, setIsMobile] = useState(false);

  // siderbar 출력
  const [siderbar, setsiderbar] = useState(true);
  // 모바일 여부 확인
  useEffect(() => {
    const checkIsMobile = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      setIsMobile(/mobile|android|iphone|ipad|webos|blackberry|windows phone/i.test(userAgent));
    };

    checkIsMobile(); // 최초 렌더링 시 기기 확인

    // 리사이징 시에도 기기 확인
    window.addEventListener('resize', checkIsMobile);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);
  //컴포넌트출력함수
  const renderComponent = (type: string) => {
    switch (type) {
      case 'Feed':
        return <Alarm history={props.history} location={props.location} match={props.match} />;
      case 'Contact':
        return <Contact history={props.history} location={props.location} match={props.match} />;
      case 'Chatting':
        return <Chatting />;
      case 'Group':
        return <Group history={props.history} location={props.location} match={props.match} />;
      case 'Call':
        return <Call history={props.history} location={props.location} match={props.match} />;
      case 'File':
        return (
          <div style={{ width: "100%" }}>
            <div className="flex px-4">
              <label className="relative mr-1.5 flex" style={{ flex: "2" }}>
                <input className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900" placeholder="Search" type="text" style={{ paddingLeft: "34px" }} />
                <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 transition-colors duration-200" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z">
                    </path>
                  </svg>
                </span>
              </label>

              {/* <button className="btn -mr-2 h-8 w-8 shrink-0 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25" style={{ border: "none", }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M22 6.5h-9.5M6 6.5H2M9 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM22 17.5h-6M9.5 17.5H2M13 20a2.5 2.5 0 100-5 2.5 2.5 0 000 5z">
                  </path>
                </svg>
              </button> */}
            </div>

            <div className='is-scrollbar-hidden mt-3 flex grow flex-col overflow-y-auto call-scroll' style={{ height: "calc(100vh - 110px)" }}>
              {/* Call 페이지의 기능 및 HTML 태그 */}
              {/* <h3>call</h3> */}
              <div className='filesharing-svg-wrap' style={{ width: "100%", textAlign: "justify" }}>
                <DirectoryTree
                  multiple
                  defaultExpandAll
                  onSelect={onSelect}
                  onExpand={onExpand}
                  treeData={treeData}
                />
              </div>

              {/* 생성버튼 */}
              <div className='chat-createBtn' onClick={() => showCreateModal()}>
                <PlusOutlined />
              </div>
            </div>

          </div>


        )
          ;
      default:
        return null;
    }
  };

  const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
    console.log('Trigger Select', keys, info);
  };

  const onExpand: DirectoryTreeProps['onExpand'] = (keys, info) => {
    console.log('Trigger Expand', keys, info);
  };

  //유저 정보 모달
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };
  const handleCreateOk = () => {
    setCreateModalOpen(false);
  };
  const handleCreateCancel = () => {
    setCreateModalOpen(false);
  };

  return (
    <div>
      <head>

      </head>
      {siderbar ?
        <div className='main-sidebar ts-dropdown-content accordionfix video_style1'>

          {/* 로고 */}
          <div className='left_logo'>
            <a href="#" style={{ marginTop: "0" }}>
              <img src={logoImage} style={{ width: "30px", height: "30px" }} onClick={() => history.push(`/${location.search}`)} />
            </a>
          </div>
          <div className='side-icon' style={{ marginTop: "80px" }}>
            {/* lfet icon */}
            <div className={selectmenu === 'Feed' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Feed')
              //setleftmodalvisible('Feed')
              history.push(`/alarm${location.search}`)
              console.log(selectmenu)
            }}>
              <BellOutlined />
              <p className='icon_fs'>활동</p>
            </div>

            <div className={selectmenu === 'Chatting' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Chatting')
              //setleftmodalvisible('Chatting')
              history.push(`/chat${location.search}`)
              console.log(selectmenu)
            }}>
              <IconFont type="icon-chat" />
              <p className='icon_fs'>채팅</p>
            </div>

            <div className={selectmenu === 'Contact' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Contact')
              //setleftmodalvisible('Contact')
              history.push(`/contact${location.search}`)
              console.log(selectmenu)
            }}>
              <UserAddOutlined />
              <p className='icon_fs'>연락처</p>
            </div>
            <div className={selectmenu === 'Group' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Group')
              //setleftmodalvisible('Group')
              history.push(`/group${location.search}`)
              //console.log(selectmenu)
            }}>
              <UsergroupAddOutlined />
              <p className='icon_fs'>조직도</p>
            </div>

            <div className={selectmenu === 'Call' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Call')
              //setleftmodalvisible('Call')
              history.push(`/call${location.search}`)
              console.log(selectmenu)
            }}>
              <WhatsAppOutlined />
              <p className='icon_fs'>통화</p>
            </div>

            <div className={selectmenu === 'File' ? 'selectedmenu' : ''} onClick={() => {
              setselectmenu('File')
              setleftmodalvisible('File')
              history.push(`/file${location.search}`)
              console.log(selectmenu)
            }}>
              <FileDoneOutlined />
              <p className='icon_fs'>파일</p>
            </div>

          </div>

          <div className='side-setting-icon'>
            <div className='whitemode'>
              <a><IconFont type="white-mode" /></a>
              {/* <p className='icon_fs'>화이트모드</p> */}
            </div>

            <div className='setline' onClick={() => { history.push(`/setting${location.search}`) }}>
              <SettingOutlined />
              {/* <p className='icon_fs'>설정</p> */}
            </div>
            
            <div>
              <a href="/"><LogoutOutlined className='logout_btn' /></a>
              {/* <p className='icon_fs'>로그아웃</p> */}
            </div>
          </div>
        </div> : null}

      <div>
        <div x-data="{expanded:true}"
          style={
            siderbar ? { position: "relative" } : { position: "relative", marginLeft: "10px" }
          }
          className={classNames('file-tree-content', { 'file-tree-hidden': !leftmodalvisible })}>
          {/* 헤더 */}
          <div className='header-nav'>
            <div className='header-name'>
              <h3 style={{ fontSize: "16px" }}>File</h3>
            </div>
            <div className='file-header-btn'>
              {/* <button className="btn h-9 w-9 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z">
                  </path>
                </svg>
              </button> */}
              {/* <button className="btn hidden h-9 w-9 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:flex text-slate-500 dark:text-navy-200 text-primary dark:text-accent-light">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9.25 21.167h5.5c4.584 0 6.417-1.834 6.417-6.417v-5.5c0-4.583-1.834-6.417-6.417-6.417h-5.5c-4.583 0-6.417 1.834-6.417 6.417v5.5c0 4.583 1.834 6.417 6.417 6.417ZM13.834 2.833v18.334">
                  </path>
                </svg>
              </button> */}
              {/* <button x-ref="popperRef" className="btn h-9 w-9 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 text-slate-500">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z">
                  </path>
                </svg>
              </button> */}
              <button onClick={() => {
                //siderbar 닫기
                setsiderbar(!siderbar)
                setleftmodalvisible('')
                setselectmenu('')
              }} className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5 text-slate-500 dark:text-navy-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                </svg>
              </button>
            </div>

          </div>
          <div className='file-table-wrap'>
            {
              //모바일 시작
              isMobile ?
                <div style={{ width: "calc(100% - 20px)", marginTop: "0px" }}>
                  {/* 검색창 */}
                  <label className="relative flex" style={{ flex: "2", marginBottom: "15px" }}>
                    <input className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900" placeholder="Search" type="text" style={{ paddingLeft: "34px" }} />
                    <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 transition-colors duration-200" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z">
                        </path>
                      </svg>
                    </span>
                  </label>
                  {/* 파일 리스트 */}
                  <ul className='ant-list-items'>
                    <li className="ant-list-item">
                      <div className="ant-list-item-meta">
                        <div className="ant-list-item-meta-avatar">
                          <span className=""><svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-yellow-500"
                            viewBox="0 0 20 20" fill="#eab308">
                            <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                          </svg>
                          </span>
                        </div>
                        <div className="ant-list-item-meta-content">
                          <h4 className="ant-list-item-meta-title">
                            Designs
                          </h4>
                          <div className="ant-list-item-meta-description">14 MB, 홍길동 23/07/24</div>
                        </div>
                      </div>
                      <ul className="ant-list-item-action">
                        {/* <li><a><svg xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
                        </svg></a></li> */}
                        <DownloadOutlined className='file-downicon' />
                      </ul>
                    </li>
                    <li className="ant-list-item">
                      <div className="ant-list-item-meta">
                        <div className="ant-list-item-meta-avatar">
                          <span className="">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-yellow-500"
                              viewBox="0 0 20 20" fill="#eab308">
                              <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                            </svg>
                          </span>
                        </div>
                        <div className="ant-list-item-meta-content">
                          <h4 className="ant-list-item-meta-title">
                            Movies
                          </h4>
                          <div className="ant-list-item-meta-description">14 MB, 홍길동 23/07/24</div>
                        </div>
                      </div>
                      <ul className="ant-list-item-action">
                        {/* <li><a><svg xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
                        </svg></a></li> */}
                        <DownloadOutlined className='file-downicon' />
                      </ul>
                    </li>
                    <li className="ant-list-item">
                      <div className="ant-list-item-meta">
                        <div className="ant-list-item-meta-avatar">
                          <span className=""><svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-yellow-500"
                            viewBox="0 0 20 20" fill="#eab308">
                            <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                          </svg>
                          </span>
                        </div>
                        <div className="ant-list-item-meta-content">
                          <h4 className="ant-list-item-meta-title">
                            Media
                          </h4>
                          <div className="ant-list-item-meta-description">14 MB, 홍길동 23/07/24</div>
                        </div>
                      </div>
                      <ul className="ant-list-item-action">
                        {/* <li><a><svg xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
                        </svg></a></li> */}
                        <DownloadOutlined className='file-downicon' />
                      </ul>
                    </li>
                    <li className="ant-list-item">
                      <div className="ant-list-item-meta">
                        <div className="ant-list-item-meta-avatar">
                          <span className=""><svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-yellow-500"
                            viewBox="0 0 20 20" fill="#eab308">
                            <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                          </svg>
                          </span>
                        </div>
                        <div className="ant-list-item-meta-content">
                          <h4 className="ant-list-item-meta-title">
                            Web Applications
                          </h4>
                          <div className="ant-list-item-meta-description">14 MB, 홍길동 23/07/24</div>
                        </div>
                      </div>
                      <ul className="ant-list-item-action">
                        {/* <li><a><svg xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
                        </svg></a></li> */}
                        <DownloadOutlined className='file-downicon' />
                      </ul>
                    </li>
                    <li className="ant-list-item">
                      <div className="ant-list-item-meta">
                        <div className="ant-list-item-meta-avatar">
                          <span className=""><svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-yellow-500"
                            viewBox="0 0 20 20" fill="#eab308">
                            <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                          </svg>
                          </span>
                        </div>
                        <div className="ant-list-item-meta-content">
                          <h4 className="ant-list-item-meta-title">
                            Archives
                          </h4>
                          <div className="ant-list-item-meta-description">14 MB, 홍길동 23/07/24</div>
                        </div>
                      </div>
                      <ul className="ant-list-item-action">
                        {/* <li><a><svg xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
                        </svg></a></li> */}
                        <DownloadOutlined className='file-downicon' />
                      </ul>
                    </li>
                  </ul>
                </div>
                :
                // 웹 시작
                <div className="flex items-center file-search" style={{ flexDirection: "column", marginTop: "34px" }}>
                  <div className="flex" style={{ justifyContent: "flex-end", width: "100%" }}>
                    <div className="flex file-head-icon">
                      <div className="table-search-wrapper flex items-center">
                        {/* <button className="table-search-toggle btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="#3f4a5b">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                          </svg>
                        </button> */}
                      </div>
                      <div id="dropdown-folders-table" className="inline-flex">
                        {/* <button className="popper-ref btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="#3f4a5b">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z">
                            </path>
                          </svg>
                        </button> */}
                      </div>
                    </div>
                  </div>
                  {/* 웹 검색 */}
                  <div className="flex" style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <label className="relative mr-1.5 flex" style={{ width: "30%", margin: "10px 0 0 0" }}>
                      <input className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900" placeholder="Search" type="text" value="" style={{ paddingLeft: "34px" }} />
                      <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
                        <span role="img" className="anticon">
                          <svg xmlns="http:// www.w3.org/2000/svg" className="" fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" aria-hidden="true" focusable="false">
                            <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z"></path>
                          </svg>
                        </span>
                      </span>
                    </label>
                  </div>
                  <table className='file-table' >
                    <thead>
                      <th>이름</th>
                      <th>마지막 편집</th>
                      <th>용량</th>
                      <th>멤버</th>
                      <th></th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                            </svg>
                            <span>Designs</span>
                          </div>
                        </td>
                        <td>2 day ago</td>
                        <td>14.3GB</td>
                        <td>
                          <div className="file-member-icon is-initial rounded-full bg-info text-xs+ uppercase text-white ring ring-white dark:ring-navy-700">
                            JD
                          </div>
                        </td>
                        <td>
                          {/* <button className="btn file-more-btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z">
                              </path>
                            </svg>
                          </button> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                            </svg>
                            <span>Movies</span>
                          </div>
                        </td>
                        <td>a month ago</td>
                        <td>31 GB</td>
                        <td>
                          <div className="file-member-icon is-initial rounded-full bg-info text-xs+ uppercase text-white ring ring-white dark:ring-navy-700">
                            SM
                          </div>
                        </td>
                        <td>
                          {/* <button className="btn file-more-btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z">
                              </path>
                            </svg>
                          </button> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                            </svg>
                            <span>Media</span>
                          </div>
                        </td>
                        <td>2 day ago</td>
                        <td>17.2 GB</td>
                        <td>
                          <div className="file-member-icon is-initial rounded-full bg-info text-xs+ uppercase text-white ring ring-white dark:ring-navy-700">
                            UH
                          </div>
                        </td>
                        <td>
                          {/* <button className="btn file-more-btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z">
                              </path>
                            </svg>
                          </button> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                            </svg>
                            <span>Web Applications</span>
                          </div>
                        </td>
                        <td>42 minute ago</td>
                        <td>12.4 GB</td>
                        <td>
                          <div className="file-member-icon is-initial rounded-full bg-info text-xs+ uppercase text-white ring ring-white dark:ring-navy-700">
                            ND
                          </div>
                        </td>
                        <td>
                          {/* <button className="btn file-more-btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z">
                              </path>
                            </svg>
                          </button> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                            </svg>
                            <span>Designs</span>
                          </div>
                        </td>
                        <td>2 day ago</td>
                        <td>4.33 GB</td>
                        <td>
                          <div className="file-member-icon is-initial rounded-full bg-info text-xs+ uppercase text-white ring ring-white dark:ring-navy-700">
                            AD
                          </div>
                        </td>
                        <td>
                          {/* <button className="btn file-more-btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z">
                              </path>
                            </svg>
                          </button> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                            </svg>
                            <span>Archives</span>
                          </div>
                        </td>
                        <td>12 day ago</td>
                        <td>1.2 TB</td>
                        <td>
                          <div className="file-member-icon is-initial rounded-full bg-info text-xs+ uppercase text-white ring ring-white dark:ring-navy-700">
                            HD
                          </div>
                        </td>
                        <td>
                          {/* <button className="btn file-more-btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z">
                              </path>
                            </svg>
                          </button> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                            </svg>
                            <span>Movies</span>
                          </div>
                        </td>
                        <td>a month ago</td>
                        <td>14.9GB</td>
                        <td>
                          <div className="file-member-icon is-initial rounded-full bg-info text-xs+ uppercase text-white ring ring-white dark:ring-navy-700">
                            SM
                          </div>
                        </td>
                        <td>
                          {/* <button className="btn file-more-btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z">
                              </path>
                            </svg>
                          </button> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"></path>
                            </svg>
                            <span>Documents</span>
                          </div>
                        </td>
                        <td>a day ago</td>
                        <td>602 MB</td>
                        <td>
                          <div className="file-member-icon is-initial rounded-full bg-info text-xs+ uppercase text-white ring ring-white dark:ring-navy-700">
                            GH
                          </div>
                        </td>
                        <td>
                          {/* <button className="btn file-more-btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z">
                              </path>
                            </svg>
                          </button> */}
                        </td>
                      </tr>

                    </tbody>
                  </table>

                  {/* 페이지네이션 */}
                  <div className='pagination-web'>
                    <div>1</div>
                  </div>
                </div>
            }
          </div>
        </div >
      </div>
      {/* left modal */}
      {(!(leftmodalvisible === '') && (leftmodalvisible === selectmenu)) ?
        <div className='left_modal group-left'>
          <div className='flex_style left_modal_title'>
            <h3>{selectmenu}</h3>
            <CloseOutlined onClick={() => {
              setleftmodalvisible('')
              setselectmenu('')
            }} />
          </div>
          {renderComponent(selectmenu)}
        </div>
        : null}

      {/* 생성 모달  */}
      <Modal className='file-create-modal' title="" open={createModalOpen} onOk={handleCreateOk} onCancel={handleCreateCancel}>
        <div>
          <Form>
            <Form.Item label="File A">
              <Input placeholder="please put the file" />
            </Form.Item>
            <Form.Item label="File B">
              <Input placeholder="please put the file" />
            </Form.Item>
            <Form.Item label="File C">
              <Input placeholder="please put the file" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" className='filesendbtn' style={{ float: "right" }}>Submit</Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default File;