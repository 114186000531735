import React, { useEffect, useState, useCallback } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import './call.scss';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { PlusOutlined, CloseOutlined, LoadingOutlined, SettingOutlined, FileDoneOutlined, WhatsAppOutlined, UsergroupAddOutlined, UserAddOutlined, BellOutlined, RightOutlined, LogoutOutlined } from '@ant-design/icons';
import { Button, Modal, Spin, Form, Input, Pagination } from 'antd';

import Alarm from '../components/alarm';
import Contact from '../components/contact';
import File from '../components/file';
import Chatting from '../components/chatting';
import Filemodal from '../components/filemodal';
import Groupmodal from '../components/groupmodal';
import { IconFont } from '../../../component/icon-font';
import logoImage from '../../../component/svgs/circlelogo.png';
import classNames from 'classnames';
import CallModal from '../../../component/callmodal';

// 날짜 시간 변경 컴포넌트
import FormattedDate from './formatteddate'

interface historyData {
  meeting_id: string,
  sender_id: string,
  receive_id: string,
  group_id: string,
  gubun: string,
  service_gubun: string,
  meeting_start_time: string,
  meeting_end_time: string,
  frst_register_id: string,
  frst_regist_pnttm: string,
  last_updusr_id: string,
  last_updt_pnttm: string,
  userName: string,
  userId: string,
  teamName: string,
}

interface Params {
  sortColumn: string
  sortType: string
  pageNum: number
  recordSize: number
  pageSize: number
}

const initParams: Params = {
  sortColumn: "",
  sortType: "ASC",
  pageNum: 1,
  recordSize: 10,
  pageSize: 5
}

interface PageInfo {
  endPage: number
  existNextPage: boolean
  existPrevPage: boolean
  limitStart: number
  startPage: number
  totalPageCount: number
  totalRecordCount: number
}
const initialPageInfo: PageInfo = {
  endPage: 1,
  existNextPage: false,
  existPrevPage: false,
  limitStart: 0,
  startPage: 1,
  totalPageCount: 0,
  totalRecordCount: 0
}

interface UserData {
  userId: number;
  userName: string;
  companyId: number;
  companyName: string;
  partId: number;
  partName: string,
  teamId: number,
  teamName: string,
  tenantId: 2,
  tenantName: string,
  tenantDomain: string,
  tenantType: string,
  email: string,
  phone: string,
  description: string,
  useAt: string,
  userGroup: string,
  userLevel: string,
  userImage: string,
  frstRegistPnttm: string,
  frstRegisterId: number,
  lastUpdtPnttm: string,
  lastUpdusrId: number
  // 다른 필드들의 타입도 여기에 추가
}
interface UserParams {
  userName: string
  companyName: string
  partName: string
  email: string
  phone: string
}

const initUserParams = {
  userName: "",
  companyName: "",
  partName: "",
  email: "",
  _phone: "",
  get phone() {
    return this._phone
  },
  set phone(value) {
    this._phone = value
  }
}
interface HomeProps extends RouteComponentProps {
  //subsession에서 에러안나도록 ?처리
  status?: string;
  onLeaveOrJoinSession?: () => void;
}

const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

const Call: React.FunctionComponent<RouteComponentProps & HomeProps> = (props) => {
  //브라우저 파라미터 정보 저장
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const presentname = urlParams.get('name') || ''
  const [isLoading, setisLoading] = useState(true)
  //연락처 정보 저장
  const [callList, setcallList] = useState<UserData[]>([]);;
  const [callinfo, setcallinfo] = useState(false);



  // 연락처 conctact user list
  const [concactUserList, setconcactUserList] = useState<UserData[]>([]);
  const [totalPages2, settotalPages2] = useState(0) //  토탈 페이지
  const [pageInfo2, setPageInfo2] = useState<PageInfo>(initialPageInfo) //  ajax 로 받아오는 
  const [contactSearchText, setContactSearchText] = useState("")

  //  연락처 정보 저장
  const [userList, setuserList] = useState<UserData[]>([]) //  사용자 리스트
  const [selectUser, setSelectUser] = useState<UserData | historyData>();
  const [selectHistoryUser, setselectHistoryUser] = useState<historyData>();
  const [sbody, setSbody] = useState<UserParams>(initUserParams) //  검색 파라미터
  const [totalPages, settotalPages] = useState(0) //  토탈 페이지
  const [pageInfo, setPageInfo] = useState<PageInfo>(initialPageInfo) //  ajax 로 받아오는 


  // history 정보
  const [historyInfo, setHistoryInfo] = useState<historyData[]>([]);
  useEffect(() => {
    getCallList();
    getCallHistoryList();
    contactList();
  }, []);

  const getCallList = () => {
    axios.get('/ippcmpapi/cmpUsers')
      .then(response => {
        // 서버로부터 받은 데이터를 처리
        console.log(response.data.cmpUsers);
        //데이터 파싱
        const paseddata = response.data.cmpUsers.map((item: UserData) => {
          const { userId, userName, teamName } = item;
          return { userId, userName, teamName };
        });
        //데이터 세팅
        setcallList(paseddata)
        setisLoading(!isLoading)
      })
      .catch(error => {
        // 에러 처리
        console.error('cmpportal에 접속하지 못했습니다. 기본 데이터를 로드합니다. :', error);

        setisLoading(!isLoading)
      });


  };
  const getCallHistoryList = () => {

    const urlStr = window.location.href;
    const url = new URL(urlStr);
    const urlParams = url.searchParams;

    const name = urlParams.get('name');

    const topic = urlParams.get('topic');

    var data = {
      senderId: name
    };
    axios.post('/feedback/callLog/callLog/select', data)
      .then(response => {
        // 서버로부터 받은 데이터를 처리
        setHistoryInfo(response.data.callLogData);
      })
      .catch(error => {
        // 에러 처리
        console.error('History 데이터를 로드하지 못 하였습니다. 기본 데이터를 로드합니다. :', error);
        setisLoading(!isLoading)
      });
  }


  // side_icon메뉴 선택 저장변수
  const [selectmenu, setselectmenu] = useState('Call')
  const [leftmodalvisible, setleftmodalvisible] = useState('Call')
  // 클릭된 유저 정보 저장
  const [currentUsername, setcurrentUsername] = useState('');
  const [currentUserTeams, setcurrentUserTeams] = useState('');

  // 검색기능
  const [searchText, setSearchText] = useState('');
  // 검색창의 값을 받아 searchText에 저장
  const handleSearchChange = (event: any) => {
    setSearchText(event.target.value);
  };
  // 검색
  const filteredList = callList.filter((item) =>
    item.userName.toLowerCase().includes(searchText.toLowerCase())
  );

  // 모달
  const [callModalOpen, setcallModalOpen] = useState(false);

  // siderbar 출력
  const [siderbar, setsiderbar] = useState(true);

  const showcallModal = () => {
    setcallModalOpen(true);
  };

  // 모달 통화 버튼 눌렀을 때 실행되는 함수
  const handleOk = () => {
    // setcallModalOpen(false);
    const urlStr = window.location.href;
    const url = new URL(urlStr);
    const urlParams = url.searchParams;

    const name = urlParams.get('name');

    const topic = urlParams.get('topic');
    console.log(currentUsername)
    var data = {
      senderId: name,
      receiveId: currentUsername,
      gubun: "outComming",
      serviceGubun: "history",

    };
    axios.post('/feedback/callLog/callLog/insert', data)
      .then(response => {
        // 서버로부터 받은 데이터를 처리
        setcallModalOpen(false);
        setTimeout(() => {
          getCallHistoryList();
        }, 1000);
      })
      .catch(error => {
        // 에러 처리
        console.error('History 데이터를 로드하지 못 하였습니다. 기본 데이터를 로드합니다. :', error);
        setisLoading(!isLoading)
      });
  };

  const handleCancel = () => {
    setcallModalOpen(false);
  };

  const movepage = (targetName: string | '') => {
    if (presentname === '') {
      alert('name을 확인해 주세요.');
    }
    else {
      history.push(`/video?topic=${targetName}&name=${presentname}`)
      window.location.reload()
    }
  }


  // 연락처 리스트 조회
  const contactList = () => {
    // Do your desired operation here...
    const urlStr = window.location.href;
    const url = new URL(urlStr);
    const urlParams = url.searchParams;

    const name = urlParams.get('name');

    var data = {
      "myUserId": name,
      "totalSearch": contactSearchText,
    };
    axios.post('/feedback/contactLog/contactLog/select', data)
      .then(response => {
        setisLoading(false)
        // 서버로부터 받은 데이터를 처리
        setconcactUserList(response.data.contactLogData)
        settotalPages2(1)

        var data = {
          endPage: 1,
          existNextPage: false,
          existPrevPage: false,
          limitStart: 1,
          startPage: 1,
          totalPageCount: 1,
          totalRecordCount: 1
        }
        setPageInfo2(data)
      })
      .catch(error => {
        // 에러 처리
        console.error('contactLog 데이터를 로드하지 못 하였습니다. 기본 데이터를 로드합니다. :', error);
      });

  };
  // 검색
  const handleContactSearchText = (event: any) => {
    setContactSearchText(event.target.value);
  }


  // 유저 정보 모달
  const [userModalOpen, setUserModalOpen] = useState(false);
  const settingSelectUser = (item: UserData) => {
    setSelectUser(item)
  }
  const showUserModal = (item: UserData) => {
    setcurrentUsername(item.userName)
    setcurrentUserTeams(item.teamName)
    setcallModalOpen(true)
    setSelectUser(item)
  }

  const setVideo = (item: UserData) => {
    if (presentname === "") {
      return
    } else {
      history.push(`/video?topic=${item.userId}&name=${presentname}`)
      // window.location.reload()
    }
  }

  const setPhone = (item: UserData) => {
    setcurrentUsername(item.userName)
    setcurrentUserTeams(item.teamName)
    showcallModal()
  }



  const [isMobile, setIsMobile] = useState(false);
  // 모바일 여부 확인
  useEffect(() => {
    const checkIsMobile = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      setIsMobile(/mobile|android|iphone|ipad|webos|blackberry|windows phone/i.test(userAgent));
    };

    checkIsMobile(); // 최초 렌더링 시 기기 확인

    // 리사이징 시에도 기기 확인
    window.addEventListener('resize', checkIsMobile);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  //컴포넌트출력함수
  const renderComponent = (type: string) => {
    switch (type) {
      case 'Feed':
        return <Alarm history={props.history} location={props.location} match={props.match} />;
      case 'Contact':
        return <Contact history={props.history} location={props.location} match={props.match} />;
      case 'Chatting':
        return <Chatting />;
      case 'Group':
        return <Groupmodal />;
      case 'Call':
        return (
          <div>
            <div style={{ width: "100%" }}>
              <div className="flex px-4">
                <label className="relative mr-1.5 flex" style={{ flex: "2" }}>
                  <input
                    className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
                    placeholder="Search"
                    type="text"
                    style={{ paddingLeft: '34px' }}
                    value={contactSearchText}
                    onChange={handleContactSearchText}
                  />
                  <span
                    className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"
                    style={{ position: "absolute" }}
                  >
                    <IconFont type="search" />
                  </span>
                </label>

                {/* <button
                  onClick={contactList}
                  className="btn -mr-2 h-8 w-8 shrink-0 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25"
                  style={{ border: "none" }}
                >
                  <IconFont type="filter" />
                </button> */}
              </div>

              <div
                className="is-scrollbar-hidden mt-3 px-4 flex grow flex-col overflow-y-auto call-scroll cursor-default"
                style={{ height: "calc(100vh - 200px)" }}
              >
                <table className='mt-10'>
                  <tbody>
                    {/* 연락처 */}
                    {isLoading ? (
                      <LoadingOutlined style={{ fontSize: "30px", marginTop: "30px" }} />
                    ) : concactUserList.length > 0 ? (
                      // eslint-disable-next-line array-callback-return
                      concactUserList.map((item: any, index: any) => {
                        return (
                          <tr key={index} >
                            <td>
                              <div className='web-tr-hover' onClick={() => {
                                setSelectUser(item)
                                setcallinfo(true)
                              }} style={{ display: "flex", alignItems: "center" }}>
                                <div
                                  className="name-circle name-circle-size fold-none flex items-center justify-center rounded-full bg-primary text-white dark:bg-accent cursor-default pb-20"
                                  style={{ marginRight: "7px" }}
                                >
                                  {item.userName[0]}
                                </div>
                                <div>
                                  <div style={{}}>
                                    {item.userName}
                                    <span style={{ color: "#8a8a8a", fontSize: "12px", marginLeft: "5px" }}>{item.teamName}</span>
                                  </div>
                                  <br />
                                  <div style={{ color: "rgb(138, 138, 138)" }}>
                                    {
                                      // 휴대전화번호 정규식
                                      item.phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                                      // item.phone.replace(/-/g, "")
                                    }
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='flex call-emoji'>
                                <a onClick={() => setVideo(item)} href="#" style={{ marginRight: "7px" }} className='call-svg-font'>
                                  <IconFont type="video" />
                                </a>
                                <a href="#" className='contact-margin5 call-svg-font15' style={{ paddingTop: "3px" }} onClick={() => setPhone(item)}>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="call-svg-wh fold-chat-width contact-mb-call-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5"><path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
                                </a>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <p>등록하신 연락처가 없습니다.</p>
                    )}
                  </tbody>
                </table>

                {/* 생성버튼 */}
                <div className='chat-createBtn' style={{ bottom: "60px" }} onClick={() => showCreateModal()}>
                  <PlusOutlined />
                </div>
              </div>

              {/* 페이지네이션 */}
              {/* <ul className="contact-pagination">
                {pageInfo.existPrevPage ? (
                  <>
                    <li className="page-item">
                      <a className="page-link" href="#" onClick={() => handlePageChange(pageInfo.startPage)}>
                        <DoubleLeftOutlined className="fs-12" />
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#" onClick={() => handlePageChange(pageInfo.startPage - 1)}>
                        <LeftOutlined className="fs-12" />
                      </a>
                    </li>
                  </>
                ) : (
                  ""
                )}
                {Array.from({ length: pageInfo.totalPageCount }).map((_, index) => (
                  <li className="page-item" key={index}>
                    <a
                      className={`page-link ${currentPage === index + 1 ? 'pagenation-color' : ''}`}
                      href="#"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </a>
                  </li>
                ))}
                {pageInfo.existNextPage ? (
                  <>
                    <li className="page-item">
                      <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
                        <RightOutlined className="fs-12" />
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#" onClick={() => handlePageChange(pageInfo.endPage)}>
                        <DoubleRightOutlined className="fs-12" />
                      </a>
                    </li>
                  </>
                ) : (
                  ""
                )}
              </ul> */}


              {/* 연락처 생성 모달  */}
              <Modal className='call-createModal' title="" open={createModalOpen} onOk={handleOk} onCancel={handleCreateCancel}>
                <div style={{ marginTop: "20px" }}>
                  <Form.Item label="이름">
                    <Input placeholder="이름을 입력해주세요" />
                  </Form.Item>
                  <Form.Item label="부서">
                    <Input placeholder="부서를 입력해주세요" />
                  </Form.Item>
                  <Form.Item label="직책">
                    <Input placeholder="직책을 입력해주세요" />
                  </Form.Item>
                  <div className='group-create-footer'>
                    <Button className='ant-btn' style={{ marginRight: "5px", marginTop: "8px" }}>취소</Button>
                    <Button type="primary" className='filesendbtn' style={{ marginTop: '8px' }}>등록</Button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        )
      case 'File':
        return <Filemodal />;
      default:
        return null;
    }
  };


  //유저 정보 모달
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };
  const handleCreateOk = () => {
    setCreateModalOpen(false);
  };
  const handleCreateCancel = () => {
    setCreateModalOpen(false);
  };
  const reversedHistoryInfo = historyInfo.slice().reverse();
  return (
    <div>
      {siderbar ?
        <div className='main-sidebar ts-dropdown-content accordionfix video_style1'>

          {/* 로고 */}
          <div className='left_logo'>
            <a href="#" style={{ marginTop: "0" }}>
              <img src={logoImage} style={{ width: "30px", height: "30px" }} onClick={() => history.push(`/${location.search}`)} />
            </a>
          </div>
          <div className='side-icon' style={{ marginTop: "80px" }}>
            {/* lfet icon */}
            <div className={selectmenu === 'Feed' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Feed')
              //setleftmodalvisible('Feed')
              history.push(`/alarm${location.search}`)
              console.log(selectmenu)
            }}>
              <BellOutlined />
              <p className='icon_fs'>활동</p>
            </div>

            <div className={selectmenu === 'Chatting' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Chatting')
              //setleftmodalvisible('Chatting')
              history.push(`/chat${location.search}`)
              console.log(selectmenu)
            }}>
              <IconFont type="icon-chat" />
              <p className='icon_fs'>채팅</p>
            </div>

            <div className={selectmenu === 'Contact' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Contact')
              //setleftmodalvisible('Contact')
              history.push(`/contact${location.search}`)
              console.log(selectmenu)
            }}>
              <UserAddOutlined />
              <p className='icon_fs'>연락처</p>
            </div>
            <div className={selectmenu === 'Group' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Group')
              //setleftmodalvisible('Group')
              history.push(`/group${location.search}`)
              //console.log(selectmenu)
            }}>
              <UsergroupAddOutlined />
              <p className='icon_fs'>조직도</p>
            </div>

            <div className={selectmenu === 'Call' ? 'selectedmenu' : ''} onClick={() => {
              setselectmenu('Call')
              setleftmodalvisible('Call')
              history.push(`/call${location.search}`)
              console.log(selectmenu)
              setcallinfo(false)
            }}>
              <WhatsAppOutlined />
              <p className='icon_fs'>통화</p>
            </div>

            <div className={selectmenu === 'File' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('File')
              //setleftmodalvisible('File')
              history.push(`/file${location.search}`)
              console.log(selectmenu)
            }}>
              <FileDoneOutlined />
              <p className='icon_fs'>파일</p>
            </div>

          </div>

          <div className='side-setting-icon'>
            <div className='whitemode'>
              <a><IconFont type="white-mode" /></a>
              {/* <p className='icon_fs'>화이트모드</p> */}
            </div>

            <div className='setline' onClick={() => { history.push(`/setting${location.search}`) }}>
              <SettingOutlined />
              {/* <p className='icon_fs'>설정</p> */}
            </div>

            <div>
              <a href="/"><LogoutOutlined className='logout_btn' /></a>
              {/* <p className='icon_fs'>로그아웃</p> */}
            </div>
          </div>
        </div> : null}

      <div>
        <div x-data="{expanded:true}" className={classNames('file-tree-content', { 'file-tree-hidden': !leftmodalvisible, 'left_modal-alarm': (!leftmodalvisible && !siderbar) })}
          style={
            siderbar ? { position: "relative" } : { position: "relative", marginLeft: "10px" }
          }>
          {/* 헤더 */}
          <div className='call-header-nav'>
            <div className='call-header-name' style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <h3 className='call-header-h3'>History</h3>
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <button className="btn rounded-full p-0 text-slate-500 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 call-margin10" style={{ marginRight: "20px" }}>
                  All
                </button> */}
                {/* <button className="btn rounded-full p-0 text-slate-500 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 call-margin5" style={{ marginRight: "12px" }}>
                  Voicemail
                </button> */}
                <button onClick={() => { setcallinfo(true) }} className="btn hidden h-9 w-9 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:flex text-slate-500 dark:text-navy-200 text-primary dark:text-accent-light">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.25 21.167h5.5c4.584 0 6.417-1.834 6.417-6.417v-5.5c0-4.583-1.834-6.417-6.417-6.417h-5.5c-4.583 0-6.417 1.834-6.417 6.417v5.5c0 4.583 1.834 6.417 6.417 6.417ZM13.834 2.833v18.334">
                    </path>
                  </svg>
                </button>
                <button onClick={() => {
                  //siderbar 닫기
                  setsiderbar(!siderbar)
                  setleftmodalvisible('')
                  setselectmenu('')
                }} className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5 text-slate-500 dark:text-navy-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                  </svg>
                </button>
              </div>
            </div>

          </div>



          {/* 통화모달 */}
          <CallModal
            isOpen={callModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            currentUsername={currentUsername}
            currentUserteam={currentUserTeams}
          />
          <div className='call-table-wrap'>
            {
              // 모바일 시작
              isMobile ?
                <div style={{ width: "calc(100% - 20px)", marginTop: "0px" }}>
                  {/* 검색창 */}
                  <label className="relative flex" style={{ flex: "2", marginBottom: "15px" }}>
                    <input className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900" placeholder="Search" type="text" style={{ paddingLeft: "34px" }} />
                    <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 transition-colors duration-200" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z">
                        </path>
                      </svg>
                    </span>
                  </label>

                  {/* 통화목록 */}
                  <table className='call-table-mb'>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan={4}>
                            <LoadingOutlined style={{ fontSize: "30px", marginTop: "20px" }} />
                          </td>
                        </tr>
                      ) :
                        reversedHistoryInfo.length > 0 ? (
                          reversedHistoryInfo.map((item, index) => (
                            <tr onClick={() => {
                              // 오류로 임시 비활성화
                              // setSelectUser(item)
                              // setcallinfo(true)

                            }} key={index}>
                              <td>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <div className="name-circle name-circle-size name-circle-mb flex items-center justify-center rounded-full bg-primary text-white dark:bg-accent cursor-default" style={{ width: "42px" }}>
                                    {item?.receive_id[0]}
                                  </div>
                                  <div className='' style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                                    <div style={{ display: "flex" }}>
                                      <div style={{}}>
                                        <div style={{ alignSelf: "center" }}>
                                          <div className='' style={{ textAlign: "left" }}>
                                            <a style={{ marginRight: "5px" }}>{item?.receive_id}</a>
                                            <p style={{ color: "#8a8a8a", fontSize: "12px", wordBreak: "keep-all", margin: "5px 0 0 0" }}>개발 CMP</p>
                                          </div>
                                        </div>
                                        <div className='call-time-mb' style={{ fontSize: "12px", color: "rgb(138, 138, 138)" }}>


                                          {console.log(item?.meeting_start_time)}
                                        </div>
                                      </div>
                                    </div>

                                    <div style={{ color: "#8a8a8a", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                      {
                                        item?.gubun == "inComming" ?
                                          <>
                                            <svg style={{ color: "#8a8a8a", marginTop: "6px", display: "flex" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-inbound" viewBox="0 0 16 16">
                                              <path d="M15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0zm-12.2 1.182a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                            </svg>
                                            <span style={{ marginLeft: "8px", alignSelf: "center", fontSize: "13px" }}> <FormattedDate dateStr={item?.meeting_start_time} /></span>
                                          </>
                                          :
                                          <>
                                            <svg style={{ width: "14px", height: "14px", marginTop: "4px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-outbound" viewBox="0 0 16 16">
                                              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                                            </svg>
                                            <span style={{ marginLeft: "8px", alignSelf: "center", fontSize: "13px" }}> <FormattedDate dateStr={item?.meeting_start_time} /></span>
                                          </>
                                      }

                                    </div>
                                  </div>
                                </div>
                              </td>
                              {/* <td>
                              모바일 통화 모달
                              <a onClick={() => {
                                showcallModal()
                                setcurrentUsername(item?.receive_id)
                                setcurrentUserTeams(item?.group_id)
                              }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "17px", marginLeft: "5px", marginTop: "4px" }} className="h-5.5 w-5.5 fold-chat-width" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5"><path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg></a>
                            </td> */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={4} style={{ textAlign: "center" }}>
                              조회된 데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
                :
                //웹 시작
                <div className="flex items-center file-search" style={{ flexDirection: "column", marginTop: "34px" }}>
                  {/* 검색영역 시작 */}
                  <div className="flex" style={{ justifyContent: "flex-end", width: "100%" }}>
                    <div className="flex file-head-icon">
                      <div className="table-search-wrapper flex items-center">
                        {/* <button className="table-search-toggle btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="#3f4a5b">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                          </svg>
                        </button> */}
                      </div>
                      <div id="dropdown-folders-table" className="inline-flex">
                        {/* <button className="popper-ref btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="#3f4a5b">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z">
                            </path>
                          </svg>
                        </button> */}
                      </div>
                    </div>
                  </div>
                  {/* 테이블 시작 */}
                  {/* 웹 검색 */}
                  <div className="flex" style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <label className="relative mr-1.5 flex" style={{ width: "30%", margin: "10px 0 0 0" }}>
                      <input className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900" placeholder="Search" type="text" value="" style={{ paddingLeft: "34px" }} />
                      <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
                        <span role="img" className="anticon">
                          <svg xmlns="http:// www.w3.org/2000/svg" className="" fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" aria-hidden="true" focusable="false">
                            <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z"></path>
                          </svg>
                        </span>
                      </span>
                    </label>
                  </div>

                  <table className='call-history-table'>
                    <thead>
                      <tr>
                        <th>이름</th>
                        <th>부서</th>
                        <th>상태</th>
                        <th>날짜</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <LoadingOutlined style={{ fontSize: "30px", marginTop: "20px" }} />
                      ) : reversedHistoryInfo.length > 0 ? (
                        reversedHistoryInfo.map((item, index) => (
                          <tr>
                            <td>
                              <div style={{ display: "flex" }}>
                                <div className="name-circle name-circle-size flex items-center justify-center rounded-full bg-primary text-white dark:bg-accent cursor-default pb-20" style={{ marginRight: "7px" }}>{item?.receive_id[0]}</div>
                                <div style={{ alignSelf: "center" }}>
                                  <div className='call-name'>
                                    <a>{item?.receive_id}</a>
                                    <p style={{ color: "#8A8A8A", marginTop: "10px" }}>010-9999-9999</p>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td onClick={() => console.log('it is team name : ', item.teamName)}><span style={{ color: "#8a8a8a", fontSize: "12px", marginLeft: "5px" }}>개발 CMP</span></td>
                            <td>
                              <div style={{ color: "#8a8a8a", display: "flex", justifyContent: "center" }}>
                                {
                                  item?.gubun == "inComming" ?
                                    <>
                                      <svg style={{ color: "#8a8a8a", marginTop: "6px", display: "flex" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-inbound" viewBox="0 0 16 16">
                                        <path d="M15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0zm-12.2 1.182a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                      </svg>
                                      <span style={{ marginLeft: "8px", alignSelf: "center", fontSize: "13px" }}> 수신</span>
                                    </>
                                    :
                                    <>
                                      <svg style={{ width: "14px", height: "14px", marginTop: "4px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-outbound" viewBox="0 0 16 16">
                                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                                      </svg>
                                      <span style={{ marginLeft: "8px", alignSelf: "center", fontSize: "13px" }}> 발신</span>
                                    </>
                                }

                              </div>
                            </td>
                            <td>
                              {item?.meeting_start_time}
                            </td>
                            <td>
                              <a onClick={() => { movepage(item?.receive_id) }} href="#" style={{ marginRight: "7px" }}>
                                <svg xmlns="http:// www.w3.org/2000/svg" width="18" height="19" fill="currentColor" className="bi bi-camera-video contact-video" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"></path>
                                </svg>
                              </a>
                              <a onClick={() => {
                                showcallModal()
                                setcurrentUsername(item?.receive_id)
                                setcurrentUserTeams(item?.group_id)
                              }}>
                                <svg xmlns="http:// www.w3.org/2000/svg" className="h-5 w-5 contact-call" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.2" style={{ marginRight: "7px" }}>
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path>
                                </svg>
                              </a>
                            </td>
                          </tr>

                        )
                        )) :
                        <td colSpan={5} style={{ padding: "10px 0" }}>조회된 데이터가 없습니다.</td>
                      }

                    </tbody>
                  </table>
                  {/* 페이지네이션 */}
                  <div className='pagination-web'>
                    <div>1</div>
                  </div>
                </div>
            }

          </div>
        </div >
      </div>
      {callinfo ?
        <div className='callinfo-sidebar' style={{ zIndex: 1001 }}>
          {/* 제목 */}
          <div className='chatinfo_title'>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ fontSize: "18px", margin: "0 0 0 6px", color: "#3f4a5b", fontWeight: "500" }}> Details </span>
            </div>

            <div className='chat-head-icon' style={{ display: "flex" }}>
              {/* <button className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5 text-slate-500 dark:text-navy-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ color: "#3f4a5b" }}>
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z">
                  </path>
                </svg>
              </button> */}
              {/* 채팅 닫기 */}
              <div style={{ cursor: "pointer", padding: "5px", fontSize: "15px" }} onClick={() => {
                setcallinfo(false)
              }}>
                <RightOutlined />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center" style={{ marginTop: "2.25rem" }}>
            <div className="avatar-chat h-20 w-20">
              {/* <img className="rounded-full" src={initialImage} alt="avatar" /> */}
              <div className='fs-17 flex h-8 w-8 items-center justify-center rounded-full text-white dark:bg-accent chatinfo-name' style={{ background: "#0dbd8b" }}>{selectUser?.userName[0]}</div>
            </div>
            <h3 x-text="activeChat.name" style={{ marginBottom: "6px" }} className="mt-2 text-lg font-medium text-slate-700 dark:text-navy-100">{selectUser?.userName}</h3>
            <p>Frontend Developer</p>
            <div className="mt-2 flex space-x-1.5 chat-info-btn">
              <button className="btn h-10 w-10 rounded-full p-0 text-slate-600 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z">
                  </path>
                </svg>
              </button>
              <button className="btn h-10 w-10 rounded-full p-0 text-slate-600 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z">
                  </path>
                </svg>
              </button>
              {/* <button className="btn h-10 w-10 rounded-full p-0 text-slate-600 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z">
                  </path>
                </svg>
              </button> */}
            </div>
          </div>
          <div style={{ borderBottom: "1px solid #e9eef5", marginTop: "20px" }}></div>

          {/* detail history */}
          <div className='is-scrollbar-hidden flex grow flex-col overflow-y-auto call-scroll' style={{ height: "calc(100vh - 329px)" }}>
            <table className='call-table-mb call-num-pad'>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={4}>
                      <LoadingOutlined style={{ fontSize: "30px", marginTop: "20px" }} />
                    </td>
                  </tr>
                ) : historyInfo.length > 0 ? (
                  historyInfo.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className="name-circle name-circle-size name-circle-mb flex items-center justify-center rounded-full bg-primary text-white dark:bg-accent cursor-default" style={{ width: "42px" }}>
                            {item?.receive_id[0]}
                          </div>
                          <div className='' style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                            <div style={{ display: "flex" }}>
                              <div style={{}}>
                                <div style={{ alignSelf: "center", paddingTop: "4px" }}>
                                  <div className='' style={{ textAlign: "left" }}>
                                    <a style={{ marginRight: "5px" }}>{item?.receive_id}</a>
                                    <p style={{ color: "#8a8a8a", fontSize: "12px", wordBreak: "keep-all", margin: "5px 0 0 0" }}>개발 CMP</p>
                                  </div>
                                </div>
                                {/* <div className='call-time-mb' style={{ fontSize: "12px", color: "rgb(138, 138, 138)" }}>
                                      {item?.meeting_start_time}
                                    </div> */}
                              </div>
                            </div>

                            <div style={{ color: "#8a8a8a", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              {
                                item?.gubun == "inComming" ?
                                  <>
                                    <svg style={{ color: "#8a8a8a", marginTop: "6px", display: "flex", marginRight: "10px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-inbound" viewBox="0 0 16 16">
                                      <path d="M15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0zm-12.2 1.182a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                    </svg>
                                    <span style={{ marginLeft: "8px", alignSelf: "center", fontSize: "13px" }}> 오후 12:00</span>
                                  </>
                                  :
                                  <>
                                    <svg style={{ width: "14px", height: "14px", marginTop: "4px", marginRight: "10px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-outbound" viewBox="0 0 16 16">
                                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                                    <div>
                                      <span style={{ marginLeft: "8px", alignSelf: "center", fontSize: "13px" }}> 오전 12:00</span>
                                      <div className='call-num'><span>2</span></div>
                                    </div>
                                  </>
                              }

                            </div>
                          </div>
                        </div>
                      </td>
                      {/* <td>
                              모바일 통화 모달
                              <a onClick={() => {
                                showcallModal()
                                setcurrentUsername(item?.receive_id)
                                setcurrentUserTeams(item?.group_id)
                              }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "17px", marginLeft: "5px", marginTop: "4px" }} className="h-5.5 w-5.5 fold-chat-width" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5"><path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg></a>
                            </td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: "center" }}>
                      조회된 데이터가 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        : null}

      {/* left modal */}
      {(!(leftmodalvisible === '') && (leftmodalvisible === selectmenu)) ?
        <div className='left_modal fold-chat-left group-left'>
          <div className='flex_style left_modal_title'>
            <h3>{selectmenu}</h3>
            <CloseOutlined onClick={() => {
              setleftmodalvisible('')
              setselectmenu('')
            }} />
          </div>
          {renderComponent(selectmenu)}
        </div>
        : null}
      {/*기존 내용 출력renderComponent(selectmenu)*/}

      {/* 생성 모달  */}
      {/* <Modal className='create-modal' title="" open={createModalOpen} onOk={handleCreateOk} onCancel={handleCreateCancel}>
        <div>

        </div>
      </Modal> */}
    </div>

  );
};

export default Call;