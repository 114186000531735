import React from 'react';
import { HomeOutlined, FolderOutlined, TeamOutlined, DownloadOutlined } from '@ant-design/icons';


const Filemodal = () => {
  return (
    <div>
      <div style={{ width: "100%" }}>
        <div className="flex px-4">
          <label className="relative mr-1.5 flex" style={{ flex: "2" }}>
            <input className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900" placeholder="Search" type="text" style={{ paddingLeft: "34px" }} />
            <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 transition-colors duration-200" fill="currentColor" viewBox="0 0 24 24">
                <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z">
                </path>
              </svg>
            </span>
          </label>

          <button className="btn -mr-2 h-8 w-8 shrink-0 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25" style={{ border: "none", }}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M22 6.5h-9.5M6 6.5H2M9 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM22 17.5h-6M9.5 17.5H2M13 20a2.5 2.5 0 100-5 2.5 2.5 0 000 5z">
              </path>
            </svg>
          </button>
        </div>

        <div className='is-scrollbar-hidden mt-3 flex grow flex-col overflow-y-auto call-scroll' style={{ height: "calc(100vh - 110px)" }}>
          {/* Call 페이지의 기능 및 HTML 태그 */}
          {/* <h3>call</h3> */}
          <div className='filesharing-svg-wrap' style={{ width: "100%" }}>
            <div className="menu flex cursor-pointer items-center space-x-2.5 px-4 py-2 font-inter hover:bg-slate-150 dark:hover:bg-navy-600">
              <div className="avatar-file">
                <HomeOutlined />
              </div>
              <div className="flex flex-1 flex-col">
                <div className="flex items-baseline justify-between space-x-1.5">
                  <p className="text-xs+ font-medium text-slate-700 line-clamp-1 dark:text-navy-100">
                    홈
                  </p>
                </div>
              </div>
            </div>
            <div className="menu flex cursor-pointer items-center space-x-2.5 px-4 py-2.5 font-inter hover:bg-slate-150 dark:hover:bg-navy-600">
              <div className="avatar-file">
                <FolderOutlined />
              </div>
              <div className="flex flex-1 flex-col">
                <div className="flex items-baseline justify-between space-x-1.5">
                  <p className="text-xs+ font-medium text-slate-700 line-clamp-1 dark:text-navy-100">
                    내 파일
                  </p>
                </div>
              </div>
            </div>
            <div className="menu flex cursor-pointer items-center space-x-2.5 px-4 py-2.5 font-inter hover:bg-slate-150 dark:hover:bg-navy-600">
              <div className="avatar-file">
              <TeamOutlined />
              </div>
              <div className="flex flex-1 flex-col">
                <div className="flex items-baseline justify-between space-x-1.5">
                  <p className="text-xs+ font-medium text-slate-700 line-clamp-1 dark:text-navy-100">
                    공유
                  </p>
                </div>
              </div>
            </div>
            <div className="menu flex cursor-pointer items-center space-x-2.5 px-4 py-2.5 font-inter hover:bg-slate-150 dark:hover:bg-navy-600">
              <div className="avatar-file">
              <DownloadOutlined />
              </div>
              <div className="flex flex-1 flex-col">
                <div className="flex items-baseline justify-between space-x-1.5">
                  <p className="text-xs+ font-medium text-slate-700 line-clamp-1 dark:text-navy-100">
                    다운로드
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filemodal;