import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import { RouteComponentProps, useLocation, Link, useHistory, } from 'react-router-dom';
import './alarm.scss';
import './call.scss';
import { IconFont } from '../../../component/icon-font';
import logoImage from '../../../component/svgs/circlelogo.png';
import Contact from '../components/contact';
//import File from '../components/file';
import Call from '../components/call'
import Chatting from '../components/chatting';
import Group from '../components/group';
import Filemodal from '../components/filemodal';
import Groupmodal from '../components/groupmodal';
import classNames from 'classnames';
import { Tree, Modal, Steps, Form, Input, Button, Pagination } from 'antd';
import { BellOutlined, CloseOutlined, DownOutlined, FileDoneOutlined, SettingOutlined, UserAddOutlined, UsergroupAddOutlined, WhatsAppOutlined, PlusOutlined, LogoutOutlined } from '@ant-design/icons';
interface HomeProps extends RouteComponentProps {
  //subsession에서 에러안나도록 ?처리
  status?: string;
  onLeaveOrJoinSession?: () => void;
}

const Alarm: React.FunctionComponent<RouteComponentProps & HomeProps> = (props) => {
  const { history, status, onLeaveOrJoinSession } = props;
  const [selectmenu, setselectmenu] = useState('Feed')
  const [leftmodalvisible, setleftmodalvisible] = useState('Feed')
  const [siderbar, setsiderbar] = useState(true)

  const [isMobile, setIsMobile] = useState(false);
  // 모바일 여부 확인
  useEffect(() => {
    const checkIsMobile = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      setIsMobile(/mobile|android|iphone|ipad|webos|blackberry|windows phone/i.test(userAgent));
    };

    checkIsMobile(); // 최초 렌더링 시 기기 확인

    // 리사이징 시에도 기기 확인
    window.addEventListener('resize', checkIsMobile);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  const renderComponent = (type: string) => {
    switch (type) {
      case 'Feed':
        return (
          <div>
            {/* <h3>alarm Content</h3> */}
            {/* Meeting 페이지의 기능 및 HTML 태그 */}

            {/*검색창 */}
            <div className="flex px-4">
              <label className="relative mr-1.5 flex" style={{ flex: "2" }}>
                <input className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900" placeholder="Search" type="text" style={{ paddingLeft: "34px" }} />
                <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 transition-colors duration-200" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z">
                    </path>
                  </svg>
                </span>
              </label>

              {/* <button className="btn -mr-2 h-8 w-8 shrink-0 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25" style={{ border: "none", }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M22 6.5h-9.5M6 6.5H2M9 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM22 17.5h-6M9.5 17.5H2M13 20a2.5 2.5 0 100-5 2.5 2.5 0 000 5z">
                  </path>
                </svg>
              </button> */}
            </div>

            {/* 본문 */}
            <div className='is-scrollbar-hidden flex grow flex-col overflow-y-auto call-scroll' style={{ height: "calc(100vh - 182px)" }}>
              {/* 1 */}
              <div className="alarm-first alarm-hover fs-13 flex cursor-pointer">
                {/* 이름 및 이모티콘 */}
                <div className="name-circle flex h-8 w-8 items-center justify-center rounded-full bg-primary text-white dark:bg-accent alarm-name">장
                  <div className='alarm-emoji alarm-heart'><IconFont type="heart-fill" /></div>
                </div>

                {/* 내용 */}
                <div className="flex text-left margin-left15 flex-2">
                  <div className='flex-2'>
                    <p className='fs-14'>장보고님 외 1명이 반응했습니다.</p>
                    <p className='alarm-color'>안녕하세요. 확인부탁드립니다.</p>
                    <div className="flex items-center justify-between flex-none">
                      <p className='alarm-color'>제너레이터</p>
                      <p className="fs-12">오전 10:30</p>
                    </div>

                  </div>
                </div>
              </div>

              {/* 2 */}
              <div className="padding alarm-hover fs-13 flex cursor-pointer">
                {/* 이름 및 이모티콘 */}
                <div className="name-circle flex h-8 w-8 items-center justify-center rounded-full bg-primary text-white dark:bg-accent alarm-name">강
                  <div className='alarm-emoji alarm-handsup'><IconFont type="hand-up" /></div>
                </div>

                {/* 내용 */}
                <div className="flex text-left margin-left15 flex-2">
                  <div className='flex-2'>
                    <p className='fs-14'>강감찬님이 반응했습니다.</p>
                    <p className='alarm-color'>감사합니다.</p>
                    <div className="flex items-center justify-between flex-none">
                      <p className='alarm-color'>강감찬님과의 채팅</p>
                      <p className="fs-12">06-29</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* 3 */}
              <div className="padding alarm-hover fs-13 flex cursor-pointer">
                {/* 이름 및 이모티콘 */}
                <div className="name-circle flex h-8 w-8 items-center justify-center rounded-full bg-primary text-white dark:bg-accent alarm-name">이
                  <div className='alarm-emoji alarm-at'><IconFont type="at" /></div>
                </div>

                {/* 내용 */}
                <div className="flex text-left margin-left15 flex-2">
                  <div className='flex-2'>
                    <p className='fs-14'>이순신님이 귀하를 멘션했습니다.</p>
                    <p className='alarm-color'>유관순 확인부탁드립니다.</p>
                    <div className="flex items-center justify-between flex-none">
                      <p className='alarm-color'>이순신님과의 채팅</p>
                      <p className="fs-12">05-05</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 생성버튼 */}
            <div className='chat-createBtn' onClick={() => showCreateModal()}>
              <PlusOutlined />
            </div>


          </div>
        );
      case 'Contact':
        return <Contact history={props.history} location={props.location} match={props.match} />;
      case 'Chatting':
        return <Chatting />;
      case 'Group':
        return <Group history={props.history} location={props.location} match={props.match} />;
      case 'Call':
        return <Call history={props.history} location={props.location} match={props.match} />;
      case 'File':
        return //<File history={props.history} location={props.location} match={props.match} />;
      default:
        return null;
    }
  }


  //유저 정보 모달
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };
  const handleCreateOk = () => {
    setCreateModalOpen(false);
  };
  const handleCreateCancel = () => {
    setCreateModalOpen(false);
  };

  return (
    <div>
      {siderbar ?
        <div className='main-sidebar ts-dropdown-content accordionfix video_style1'>
          {/* 로고 */}
          <div className='left_logo'>
            <a href="#" style={{ marginTop: "0" }}>
              <img src={logoImage} style={{ width: "30px", height: "30px" }} onClick={() => history.push(`/${location.search}`)} />
            </a>
          </div>
          <div className='side-icon' style={{ marginTop: "80px" }}>
            {/* lfet icon */}
            <div className={selectmenu === 'Feed' ? 'selectedmenu' : ''} onClick={() => {
              setselectmenu('Feed')
              setleftmodalvisible('Feed')
              history.push(`/alarm${location.search}`)
              console.log(selectmenu)
            }}>
              <BellOutlined />
              <p className='icon_fs'>활동</p>
            </div>

            <div className={selectmenu === 'Chatting' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Chatting')
              //setleftmodalvisible('Chatting')
              history.push(`/chat${location.search}`)
              console.log(selectmenu)
            }}>
              <IconFont type="icon-chat" />
              <p className='icon_fs'>채팅</p>
            </div>

            <div className={selectmenu === 'Contact' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Contact')
              //setleftmodalvisible('Contact')
              history.push(`/contact${location.search}`)
              console.log(selectmenu)
            }}>
              <UserAddOutlined />
              <p className='icon_fs'>연락처</p>
            </div>
            <div className={selectmenu === 'Group' ? 'selectedmenu' : ''} onClick={() => {
              setselectmenu('Group')
              setleftmodalvisible('Group')
              history.push(`/group${location.search}`)
              //console.log(selectmenu)
            }}>
              <UsergroupAddOutlined />
              <p className='icon_fs'>조직도</p>
            </div>

            <div className={selectmenu === 'Call' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Call')
              //setleftmodalvisible('Call')
              history.push(`/call${location.search}`)
              console.log(selectmenu)
            }}>
              <WhatsAppOutlined />
              <p className='icon_fs'>통화</p>
            </div>

            <div className={selectmenu === 'File' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('File')
              //setleftmodalvisible('File')
              history.push(`/file${location.search}`)
              console.log(selectmenu)
            }}>
              <FileDoneOutlined />
              <p className='icon_fs'>파일</p>
            </div>

          </div>

          <div className='side-setting-icon'>
            <div className='whitemode'>
              <a><IconFont type="white-mode" /></a>
              {/* <p className='icon_fs'>화이트모드</p> */}
            </div>

            <div className='setline' onClick={() => { history.push(`/setting${location.search}`) }}>
              <SettingOutlined />
              {/* <p className='icon_fs'>설정</p> */}
            </div>

            <div>
              <a href="/"><LogoutOutlined className='logout_btn' /></a>
              {/* <p className='icon_fs'>로그아웃</p> */}
            </div>
          </div>
        </div>
        : null
      }

      <div>
        <div x-data="{expanded:true}" className={classNames('alarm-tree-content', { 'alarm-tree-hidden': !leftmodalvisible, 'left_modal_hidden10 left_modal-alarm': (!leftmodalvisible && !siderbar) })}>
          {/* 헤더 */}
          <div className='alarm-header-nav'>
            <div className='alarm-header-name'>
              <h3 style={{ fontSize: "16px" }}>Alarm</h3>
            </div>
            <div className='alarm-header-btn'>
              {/* <button className="btn h-9 w-9 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z">
                  </path>
                </svg>
              </button> */}
              {/* <button className="btn hidden h-9 w-9 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:flex text-slate-500 dark:text-navy-200 text-primary dark:text-accent-light">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9.25 21.167h5.5c4.584 0 6.417-1.834 6.417-6.417v-5.5c0-4.583-1.834-6.417-6.417-6.417h-5.5c-4.583 0-6.417 1.834-6.417 6.417v5.5c0 4.583 1.834 6.417 6.417 6.417ZM13.834 2.833v18.334">
                  </path>
                </svg>
              </button> */}
              {/* <button x-ref="popperRef" className="btn h-9 w-9 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 text-slate-500">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z">
                  </path>
                </svg>
              </button> */}

              <button onClick={() => {
                //siderbar 닫기
                setsiderbar(!siderbar)
                setleftmodalvisible('')
                setselectmenu('')
              }} className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5 text-slate-500 dark:text-navy-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                </svg>
              </button>
            </div>

          </div>
          <div className='alarm-table-wrap'>

            {
              //모바일 시작
              isMobile ?
                <div style={{ width: "calc(100% - 20px)", margin: "0px" }}>
                  {/* 검색창 */}
                  <label className="relative mr-1.5 flex" style={{ flex: "2", marginBottom: "15px", marginRight: "0px" }}>
                    <input className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900" placeholder="Search" type="text" style={{ paddingLeft: "34px" }} />
                    <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 transition-colors duration-200" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z">
                        </path>
                      </svg>
                    </span>
                  </label>
                  {/* 알람 타임라인 */}
                  {/* <h6 style={{ textAlign: "left" }}>오늘 7월 25 화</h6> */}

                  <ul className='ant-list-items'>
                    <li className="ant-list-item" style={{ background: "#FAFAFA", borderRadius: "5px" }}>
                      <div className="ant-list-item-meta">
                        <div className="ant-list-item-meta-avatar">
                          <span className="ant-avatar ant-avatar-circle">
                            <span className="ant-avatar-string" style={{ transform: "scale(1) translateX(-50%);" }}>
                            </span></span></div>
                        <div className="ant-list-item-meta-content">
                          <h4 className="ant-list-item-meta-title">
                            <a href="" style={{ color: "#1677ff" }}>오늘 7월 25일 화</a></h4>
                          <div className="ant-list-item-meta-description">
                            [ETECH ZOOM] 개발 주간보고 웹엑스 미팅에 초대합니다.</div>
                        </div>
                      </div>
                    </li>
                    <li className="ant-list-item">
                      <div className="ant-list-item-meta">
                        <div className="ant-list-item-meta-avatar">
                          <span className="ant-avatar ant-avatar-circle">
                            <span className="ant-avatar-string" style={{ transform: "scale(1) translateX(-50%);" }}>
                            </span></span></div>
                        <div className="ant-list-item-meta-content">
                          <p className="ant-list-item-meta-title">
                            <a href="">7월 24일 월</a></p>
                          <div className="ant-list-item-meta-description">
                            [ETECH ZOOM] 개발 주간보고 웹엑스 미팅에 초대합니다.</div>
                        </div>
                      </div>
                    </li>
                    <li className="ant-list-item">
                      <div className="ant-list-item-meta">
                        <div className="ant-list-item-meta-avatar">
                          <span className="ant-avatar ant-avatar-circle">
                            <span className="ant-avatar-string" style={{ transform: "scale(1) translateX(-50%);" }}>
                            </span></span></div>
                        <div className="ant-list-item-meta-content">
                          <p className="ant-list-item-meta-title">
                            <a href="">7월 21일 금</a></p>
                          <div className="ant-list-item-meta-description">
                            [ETECH ZOOM] 개발 주간보고 웹엑스 미팅에 초대합니다.</div>
                        </div>
                      </div>
                    </li>
                  </ul>




                </div>
                :
                //웹 시작
                <div>
                  {/* 웹 검색 */}
                  <div className="flex" style={{ width: "100%", display: "flex", justifyContent: "flex-end", padding: "0 28px 0px 0" }}>
                    <label className="relative mr-1.5 flex" style={{ width: "30%", margin: "10px 0 0 0" }}>
                      <input className="form-input peer h-8 w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900" placeholder="Search" type="text" value="" style={{ paddingLeft: "34px" }} />
                      <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent" style={{ position: "absolute" }}>
                        <span role="img" className="anticon">
                          <svg xmlns="http:// www.w3.org/2000/svg" className="" fill="currentColor" viewBox="0 0 24 24" width="1em" height="1em" aria-hidden="true" focusable="false">
                            <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z"></path>
                          </svg>
                        </span>
                      </span>
                    </label>
                  </div>
                  <div className="flex items-center table-btn" style={{ justifyContent: "flex-end" }}>
                    <div className="flex">
                      <div className="flex file-head-icon">
                        <div className="table-search-wrapper flex items-center">
                          {/* <button className="table-search-toggle btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="#3f4a5b">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                            </svg>
                          </button> */}
                        </div>
                        <div id="dropdown-folders-table" className="inline-flex">
                          {/* <button className="popper-ref btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="#3f4a5b">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z">
                              </path>
                            </svg>
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <table className='alarm-table'>
                    <thead>
                      <th className='pl-import'>이름</th>
                      <th>날짜</th>
                      <th>멤버</th>
                      <th></th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <span>taesung</span>
                          </div>
                        </td>
                        <td>a month ago</td>
                        <td>
                          <div className="file-member-icon is-initial rounded-full bg-info text-xs+ uppercase text-white ring ring-white dark:ring-navy-700">
                            SM
                          </div>
                        </td>
                        <td>
                          {/* <button className="btn file-more-btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z">
                              </path>
                            </svg>
                          </button> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <span>admin</span>
                          </div>
                        </td>
                        <td>2 day ago</td>
                        <td>
                          <div className="file-member-icon is-initial rounded-full bg-info text-xs+ uppercase text-white ring ring-white dark:ring-navy-700">
                            UH
                          </div>
                        </td>
                        <td>
                          {/* <button className="btn file-more-btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z">
                              </path>
                            </svg>
                          </button> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <span>관리자</span>
                          </div>
                        </td>
                        <td>42 minute ago</td>
                        <td>
                          <div className="file-member-icon is-initial rounded-full bg-info text-xs+ uppercase text-white ring ring-white dark:ring-navy-700">
                            ND
                          </div>
                        </td>
                        <td>
                          {/* <button className="btn file-more-btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z">
                              </path>
                            </svg>
                          </button> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <span>한소정</span>
                          </div>
                        </td>
                        <td>2 day ago</td>
                        <td>
                          <div className="file-member-icon is-initial rounded-full bg-info text-xs+ uppercase text-white ring ring-white dark:ring-navy-700">
                            AD
                          </div>
                        </td>
                        <td>
                          {/* <button className="btn file-more-btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z">
                              </path>
                            </svg>
                          </button> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <span>김수아</span>
                          </div>
                        </td>
                        <td>12 day ago</td>
                        <td>
                          <div className="file-member-icon is-initial rounded-full bg-info text-xs+ uppercase text-white ring ring-white dark:ring-navy-700">
                            HD
                          </div>
                        </td>
                        <td>
                          {/* <button className="btn file-more-btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z">
                              </path>
                            </svg>
                          </button> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <span>최혜지</span>
                          </div>
                        </td>
                        <td>a month ago</td>
                        <td>
                          <div className="file-member-icon is-initial rounded-full bg-info text-xs+ uppercase text-white ring ring-white dark:ring-navy-700">
                            SM
                          </div>
                        </td>
                        <td>
                          {/* <button className="btn file-more-btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z">
                              </path>
                            </svg>
                          </button> */}
                        </td>
                      </tr>

                    </tbody>
                  </table>
                  {/* 페이지네이션 */}
                  <div className='pagination-web'>
                    <div>1</div>
                  </div>
                </div>
            }

          </div>
        </div >
      </div>

      {/* left modal */}
      {(!(leftmodalvisible === '') && (leftmodalvisible === selectmenu)) ?
        <div className='left_modal group-left'>
          <div className='flex_style left_modal_title'>
            <h3>{selectmenu}</h3>
            <CloseOutlined onClick={() => {
              setleftmodalvisible('')
              setselectmenu('')
            }} />
          </div>
          {renderComponent(selectmenu)}
        </div>
        : null}

      {/* 생성 모달  */}
      <Modal className='alarm-create-modal' title="" open={createModalOpen} onOk={handleCreateOk} onCancel={handleCreateCancel}>
        <Form>
          <Form.Item label="모든 게시물">
            <Input placeholder="모든 게시물" />
          </Form.Item>
          <Form.Item label="채널 멘션">
            <Input placeholder="채널 멘션" />
          </Form.Item>
          <Form.Item label="모임 및 통화">
            <Input placeholder="모임 및 통화" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" className='filesendbtn' style={{ float: "right" }}>등록</Button>
          </Form.Item>
        </Form>
      </Modal>

    </div>
  );
};

export default Alarm;