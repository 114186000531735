import { CaretRightOutlined, DownloadOutlined, SettingOutlined, SmileOutlined } from '@ant-design/icons';
import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { RouteComponentProps, useLocation, Link, useHistory } from 'react-router-dom';
import ZoomContext from '../../context/zoom-context';
import ZoomMediaContext from '../../context/media-context';
import Avatar from './components/avatar';
import VideoFooter from './components/video-footer';
import Pagination from './components/pagination';
import { useCanvasDimension } from './hooks/useCanvasDimension';
import { useGalleryLayout } from './hooks/useGalleryLayout';
import { usePagination } from './hooks/usePagination';
import { useActiveVideo } from './hooks/useAvtiveVideo';
import { useShare } from './hooks/useShare';
import { useLocalVolume } from './hooks/useLocalVolume';
import './video.scss';
import { isSupportWebCodecs } from '../../utils/platform';
import { isShallowEqual } from '../../utils/util';
import { useSizeCallback } from '../../hooks/useSizeCallback';
import { useAdvancedFeatureSwitch } from './hooks/useAdvancedFeatureSwith';
import RemoteControlPanel, { RemoteControlIndication } from './components/remote-control';
import { useCameraControl } from './hooks/useCameraControl';
import { useNetworkQuality } from './hooks/useNetworkQuality';
import { IconFont } from '../../component/icon-font';
import { UserAddOutlined, WhatsAppOutlined, FileDoneOutlined, UsergroupAddOutlined, RightOutlined, SendOutlined, LeftOutlined, CloseOutlined, BellOutlined, PaperClipOutlined, InfoCircleOutlined , LogoutOutlined} from '@ant-design/icons';
import logoImage from '../../component/svgs/circlelogo.png';
import { Button, Form, Modal, Radio, Tabs, TabsProps } from 'antd';


// 채팅 이모지 기능 추가 컴포넌트

import EmojiPicker from '../../component/EmojiPicker';
//채팅 기능 관련 import
import ChatContainer from '../chat/chat';
import produce, { current } from 'immer';
import { Input } from 'antd';
import { ChatPrivilege } from '@zoom/videosdk';
import { ChatReceiver, ChatRecord } from '../chat/chat-types';
import { useParticipantsChange } from './hooks/useParticipantsChange';
import ChatContext from '../../context/chat-context';
import ChatMessageItem from '../chat/component/chat-message-item';
import ChatReceiverContainer from '../chat/component/chat-receiver';
import { useMount } from '../../hooks';
import '../chat/chat.scss';
//모달창 관련 import
import Group from './components/group';
import Alarm from './components/alarm';
import Contact from './components/contact';
import Filemodal from './components/filemodal';
import Groupmodal from './components/groupmodal';
import File from './components/file';
import Call from './components/call'
import Chatting from './components/chatting';


//chatinfo 모달창 샘플 이미지
import Object2 from '../../../src/component/img/object-2.jpg'
import Object4 from '../../../src/component/img/object-4.jpg'
import Object5 from '../../../src/component/img/object-5.jpg'
import Object8 from '../../../src/component/img/object-8.jpg'
import CallModal from '../../component/callmodal'
import Preview from '../preview/preview'
import axios from 'axios';

type LayoutType = Parameters<typeof Form>[0]['layout'];

const { TextArea } = Input;
const VideoContainer: React.FunctionComponent<RouteComponentProps> = (props) => {
  // 채팅 페이지 보여주기, 숨기기
  const [chatvisible, setchatvisible] = useState(true);

  // chatvisible 변수 값 변경시 실시간 적용
  useEffect(() => {
    console.log(chatvisible)
  }, [chatvisible])

  // 채팅 인포 보여주기, 숨기기
  const [chatinfovisible, setchatinfovisible] = useState(true)
  // chatinfovisible 변수 값 변경시 실시간 적용
  useEffect(() => {
    console.log(chatinfovisible)
  }, [chatinfovisible])

  //side_icon메뉴 선택 저장변수
  const [selectmenu, setselectmenu] = useState('')
  const [leftmodalvisible, setleftmodalvisible] = useState('')
  //컴포넌트출력함수
  const renderComponent = (type: string) => {
    switch (type) {
      case 'Feed':
        return <Alarm history={props.history} location={props.location} match={props.match} />;
      case 'Contact':
        return <Contact history={props.history} location={props.location} match={props.match} />;
      case 'Chatting':
        return <Chatting />;
      case 'Group':
        return <Groupmodal />;
      case 'Call':
        return <Call history={props.history} location={props.location} match={props.match} />;
      case 'File':
        return <Filemodal />;
      default:
        return null;
    }
  };

  // 파일모달 보여주기, 숨기기
  const [filevisible, setfilevisible] = useState(false)

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState<LayoutType>('vertical');

  const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
    setFormLayout(layout);
  };

  const formItemLayout =
    formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;

  const buttonItemLayout =
    formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

  const history = useHistory();
  const location = useLocation();
  //video-footer로 부터 값 전달받기
  const chatvisiblehandleCallback = useCallback(() => {//usecallback는 함수 두번실행을 방지함.
    setchatvisible(!chatvisible);
  }, [chatvisible]);
  const chatinfovisiblehandleCallback = useCallback(() => {//usecallback는 함수 두번실행을 방지함.
    setchatinfovisible(!chatinfovisible);
  }, [chatinfovisible]);

  // useEffect(() => {
  //   getCallList(); // 컴포넌트가 마운트되었을 때 getCallList 함수 호출
  // }, []);


  const zmClient = useContext(ZoomContext);
  const {
    mediaStream,
    video: { decode: isVideoDecodeReady }
  } = useContext(ZoomMediaContext);
  const videoRef = useRef<HTMLCanvasElement | null>(null);
  const shareRef = useRef<HTMLCanvasElement | null>(null);
  const selfShareRef = useRef<(HTMLCanvasElement & HTMLVideoElement) | null>(null);
  const shareContainerRef = useRef<HTMLDivElement | null>(null);
  const [containerDimension, setContainerDimension] = useState({
    width: 0,
    height: 0
  });
  const [shareViewDimension, setShareViewDimension] = useState({
    width: 0,
    height: 0
  });
  const canvasDimension = useCanvasDimension(mediaStream, videoRef);
  const activeVideo = useActiveVideo(zmClient);
  const { page, pageSize, totalPage, totalSize, setPage } = usePagination(zmClient, canvasDimension);
  const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
    zmClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    {
      page,
      pageSize,
      totalPage,
      totalSize
    }
  );

  const { isRecieveSharing, isStartedShare, sharedContentDimension } = useShare(zmClient, mediaStream, shareRef);

  const { userVolumeList, setLocalVolume } = useLocalVolume();
  const {
    isControllingFarEnd,
    currentControlledUser,
    isInControl,
    giveUpControl,
    stopControl,
    turnDown,
    turnRight,
    turnLeft,
    turnUp,
    zoomIn,
    zoomOut,
    switchCamera
  } = useCameraControl(zmClient, mediaStream);

  const { advancedSwitch, toggleAdjustVolume, toggleFarEndCameraControl } = useAdvancedFeatureSwitch(
    zmClient,
    mediaStream,
    visibleParticipants
  );
  const networkQuality = useNetworkQuality(zmClient);

  const isSharing = isRecieveSharing || isStartedShare;
  
  // const [originalData, setOriginalData] = useState([])

  useEffect(() => {
    if (isSharing && shareContainerRef.current) {
      const { width, height } = sharedContentDimension;
      const { width: containerWidth, height: containerHeight } = containerDimension;
      const ratio = Math.min(containerWidth / width, containerHeight / height, 1);
      
      console.log(width,"::sharedContentDimension")
      console.log(height,"::sharedContentDimension")
      
      console.log(containerWidth,"::containerDimension")
      
      console.log(containerHeight,"::containerDimension")
      
      
      setShareViewDimension({
        width: Math.floor(width * ratio),
        height: Math.floor(height * ratio)
      });
    }
  }, [isSharing, sharedContentDimension, containerDimension]);

  const onShareContainerResize = useCallback(({ width, height }) => {
    _.throttle(() => {
      console.log(width, height,":: width, height")
      setContainerDimension({ width, height });
    }, 50)();
  }, []);
  
  useSizeCallback(shareContainerRef.current, onShareContainerResize);
  console.log(visibleParticipants,":: visibleParticipants")
  useEffect(() => {
    if (!isShallowEqual(shareViewDimension, sharedContentDimension)) {
      console.log(sharedContentDimension,": sharedContentDimension")
      mediaStream?.updateSharingCanvasDimension(shareViewDimension.width, shareViewDimension.height);
    }
  }, [mediaStream, sharedContentDimension, shareViewDimension]);
  const onAdvancedFeatureToggle = useCallback(
    (userId: number, key: string) => {
      if (key === 'volume') {
        toggleAdjustVolume(userId);
      } else if (key === 'farend') {
        if (isControllingFarEnd) {
          giveUpControl();
        } else {
          mediaStream?.requestFarEndCameraControl(userId);
        }
        // toggleFarEndCameraControl(userId);
      }
    },
    [toggleAdjustVolume, giveUpControl, mediaStream, isControllingFarEnd]
  );
  const chatClient = useContext(ChatContext);
  const [chatRecords, setChatRecords] = useState<ChatRecord[]>([]);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [chatReceivers, setChatReceivers] = useState<ChatReceiver[]>([]);
  const [chatPrivilege, setChatPrivilege] = useState<ChatPrivilege>(ChatPrivilege.All);
  const [chatUser, setChatUser] = useState<ChatReceiver | null>(null);
  const [isHost, setIsHost] = useState<boolean>(false);
  const [isManager, setIsManager] = useState<boolean>(false);
  const [chatDraft, setChatDraft] = useState<string>('');
  const chatWrapRef = useRef<HTMLDivElement | null>(null);
  const onChatMessage = useCallback(
    (payload: ChatRecord) => {
      setChatRecords(
        produce((records: ChatRecord[]) => {
          const { length } = records;
          if (length > 0) {
            const lastRecord = records[length - 1];
            console.log( payload,": records")
            if (
              payload.sender.userId === lastRecord.sender.userId &&
              payload.receiver.userId === lastRecord.receiver.userId &&
              payload.timestamp - lastRecord.timestamp < 1000 * 60 * 5
            ) {
              if (Array.isArray(lastRecord.message)) {
                lastRecord.message.push(payload.message as string);
              } else {
                lastRecord.message = [lastRecord.message, payload.message as string];
              }
            } else {
              records.push(payload);
            }
          } else {
            records.push(payload);
          }
        })
      );
      if (chatWrapRef.current) {
        chatWrapRef.current.scrollTo(0, chatWrapRef.current.scrollHeight);
      }
    },
    [chatWrapRef]
  );
  const onChatPrivilegeChange = useCallback(
    (payload) => {
      console.log(payload,":: payload")
      setChatPrivilege(payload.chatPrivilege);
      if (chatClient) {
        setChatReceivers(chatClient.getReceivers());
      }
    },
    [chatClient]
  );
  const onChatInput = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChatDraft(event.target.value);
  }, []);
  useEffect(() => {
    zmClient.on('chat-on-message', onChatMessage);
    return () => {
      zmClient.off('chat-on-message', onChatMessage);
    };
  }, [zmClient, onChatMessage]);
  useEffect(() => {
    zmClient.on('chat-privilege-change', onChatPrivilegeChange);
    return () => {
      zmClient.off('chat-privilege-change', onChatPrivilegeChange);
    };
  }, [zmClient, onChatPrivilegeChange]);
  useParticipantsChange(zmClient, () => {
    if (chatClient) {
      setChatReceivers(chatClient.getReceivers());
    }
    setIsHost(zmClient.isHost());
    // setIsManager(zmClient.isManager());
  });
  useEffect(() => {
    if (chatUser) {
      const index = chatReceivers.findIndex((user) => user.userId === chatUser.userId);
     
      if (index === -1) {
       
        setChatUser(chatReceivers[0]);
      }
    } else {
      if (chatReceivers.length > 0) {
        setChatUser(chatReceivers[0]);
      }
    }
  }, [chatReceivers, chatUser]);

  
  // 채팅 페이지 로드 시 채팅 가져오도록 수정
  useEffect(() => {  
    const urlStr = window.location.href;
        const url = new URL(urlStr);
        const urlParams = url.searchParams;
        
        const name = urlParams.get('name');
        
        const topic = urlParams.get('topic');
        
        var data = {
          meetingId: topic,
          userId:  name,
          content:chatDraft
        }
        axios.post('/feedback/chatlog/chatlog/select', data)
      .then(response => {
        console.log(chatClient,": chatClient");
        var chatData = response.data.chatData;
        for(var i = 0; i<chatData.length;i++){
          console.log(chatData[i])
          var date = new Date(chatData[i].frst_regist_pnttm);
          
          // getTime() 메서드를 사용하여 해당 날짜의 타임스탬프를 얻습니다.
          const timestamp = date.getTime();

          setChatRecords(
            produce((records: ChatRecord[]) => {
              var data =  {id :  "",
              message  :   chatData[i].content,
              receiver  :  {name: 'Everyone', userId: 0},
              sender : 
               {avatar :  undefined,
              name :   chatData[i].user_id,
              userId  :  chatData[i].user_id},
              timestamp : timestamp
            };

              records.push(data);
            })
          );
       
          //chatClient?.send(chatData[i], chatData[i].user_id);
        }
       
      })
      .catch(error => {
        // alert("조직도 조회 중 에러가 발생하였습니다.")
        console.log("채팅 로그 기록 중에 문제가 발생하였습니다.")
      })
  }, [])

  const setChatUserId = useCallback(
    (userId) => {
      const user = chatReceivers.find((u) => u.userId === userId);
      if (user) {
        setChatUser(user);
      }
    },
    [chatReceivers]
  );
  const sendMessage = useCallback(
    //event 변수가 마우스와 키보드 이벤트를 모두 처리할 수 있도록 변경.

    // 외부 라이브러리 적용 시 에러가 있어서 주석 처리함 SHM 2023-07-07

    // (event: React.MouseEvent<HTMLDivElement>|React.KeyboardEvent<HTMLTextAreaElement>) => {
    //   event.preventDefault();
    //   if (chatUser && chatDraft) {
    //     chatClient?.send(chatDraft, chatUser?.userId);
    //     setChatDraft('');
    //   }
    // },
    () => {
      if (chatUser && chatDraft) {
        chatClient?.send(chatDraft, chatUser?.userId);
        const urlStr = window.location.href;
        const url = new URL(urlStr);
        const urlParams = url.searchParams;
        
        const name = urlParams.get('name');
        
        const topic = urlParams.get('topic');
        
        var data = {
          meetingId: topic,
          userId:  name,
          content:chatDraft
        }
        axios.post('/feedback/chatlog/chatlog/insert', data)
      .then(response => {
       
        setChatDraft('');
      })
      .catch(error => {
        // alert("조직도 조회 중 에러가 발생하였습니다.")
        console.log("채팅 로그 기록 중에 문제가 발생하였습니다.")
      })
      }
    },
    [chatClient, chatDraft, chatUser]
  );
  //쉬프트+엔터 개행 기능
  const onShiftEnter = useCallback(
    (event) => {
      if (event.key === 'Enter' && event.shiftKey) {
        setChatDraft((prevDraft) => prevDraft + '\n');
        event.preventDefault(); // 기본 엔터 동작을 방지합니다.
      }
    },
    []
  );
  //chat info모달
  const [callModalOpen, setcallModalOpen] = useState(false);
  const [prevwModalOpen, previewModalOpen] = useState(false);
  const [TargetTopic, setTargetTopic] = useState('')
  const [currentUserinfo, setcurrentUserinfo] = useState('')
  //chat info모달창
  useMount(() => {
    setCurrentUserId(zmClient.getSessionInfo().userId);
    if (chatClient) {
      setChatPrivilege(chatClient.getPrivilege());
    }
  })
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };
  const handleCreateOk = () => {
    setCreateModalOpen(false);
  };
  const handleCreateCancel = () => {
    setCreateModalOpen(false);
  };

  //통화 모달
  const showcallModal = () => {
    setcallModalOpen(true);
  };
  const showPrevwModal = () => {
    previewModalOpen(true);
  };

  const handleOk = () => {
    setcallModalOpen(false);
  };
  const handlePrevwOk = () => {
    previewModalOpen(false);
  };

  const handleCancel = () => {
    setcallModalOpen(false);
  };
  const handlePrevwCancel = () => {
    previewModalOpen(false);
  };

  //파일이미지유저탭
  const onChange = (key: string) => {
    console.log(zmClient.getAllUser())
    console.log('is host : ', zmClient.getSessionHost())
    //console.log(key);
  };
  const [isMobile, setIsMobile] = useState(false);
  // 모바일 여부 확인
  useEffect(() => {
    const checkIsMobile = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      setIsMobile(/mobile|android|iphone|ipad|webos|blackberry|windows phone/i.test(userAgent));
    };

    checkIsMobile(); // 최초 렌더링 시 기기 확인

    // 리사이징 시에도 기기 확인
    window.addEventListener('resize', checkIsMobile);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);


  
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `User`,
      children: (
        //현재 세션에 있는 사용자 목록 출력
        <div className='tab-total tab-User'>
          <div>
            {zmClient.getAllUser().map((user) => (
              <div key={user.userId} className='user-info' onClick={() => { setcurrentUserinfo(user.displayName) }}>{user.displayName}
                <div>
                  <button onClick={() => {
                    showPrevwModal()
                    setTargetTopic(user.displayName)
                  }} className="btn btn-video-info p-0 text-slate-600 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z">
                      </path>
                    </svg>
                  </button>
                  <button onClick={showcallModal} className="btn btn-call-info p-0 text-slate-600 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z">
                      </path>
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      key: '2',
      label: `Image`,
      children: (
        <ul className='tab-total tab-image' style={{ padding: "10px" }}>
          <div className='grid grid-cols-4 gap-2'>
            <img src={Object2} alt="" className='aspect-square rounded-lg object-cover object-center' />
            <img src={Object4} alt="" className='aspect-square rounded-lg object-cover object-center' />
            <img src={Object5} alt="" className='aspect-square rounded-lg object-cover object-center' />
            <img src={Object8} alt="" className='aspect-square rounded-lg object-cover object-center' />
          </div>
        </ul>
      ),
    },
    {
      key: '3',
      label: `File`,
      children: (
        <ul className='tab-total tab-file'>
          <li>
            <div className="flex items-center space-x-3">
              <div className="mask is-squircle flex h-11 w-11 items-center justify-center bg-secondary text-white">
                <DownloadOutlined className='filedown-info' />
              </div>
              <div>
                <p className="text-slate-700 dark:text-navy-100">
                  Final.fig
                </p>
                <div className="flex text-xs text-slate-400 dark:text-navy-300">
                  <span>8.32 MB</span>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="flex items-center space-x-3">
              <div className="mask is-squircle flex h-11 w-11 items-center justify-center bg-secondary text-white">
                <DownloadOutlined className='filedown-info' />
              </div>
              <div>
                <p className="text-slate-700 dark:text-navy-100">
                  TheBook.pdf
                </p>
                <div className="flex text-xs text-slate-400 dark:text-navy-300">
                  <span>14 MB</span>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="flex items-center space-x-3">
              <div className="mask is-squircle flex h-11 w-11 items-center justify-center bg-secondary text-white">
                <DownloadOutlined className='filedown-info' />
              </div>
              <div>
                <p className="text-slate-700 dark:text-navy-100">
                  Report.docx
                </p>
                <div className="flex text-xs text-slate-400 dark:text-navy-300">
                  <span>3.5 MB</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      ),
    },
  ];

  return (
    <div className='flex_style'>
      <div className='flex_style'>
        <div className='main-sidebar ts-dropdown-content accordionfix video_style1'>

          {/* 로고 */}
          <div className='left_logo'>
            <a href="#" style={{ marginTop: "0" }}>
              <img src={logoImage} style={{ width: "30px", height: "30px" }} onClick={() => history.push(`/${location.search}`)} />
            </a>
          </div>
          <div className='side-icon' style={{ marginTop: "80px" }}>
            {/* lfet icon */}
            <div className={selectmenu === 'Feed' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Feed')
              //setleftmodalvisible('Feed')
              history.push(`/alarm${location.search}`)
              console.log(selectmenu)
            }}>
              <BellOutlined />
              <p className='icon_fs'>활동</p>
            </div>

            <div className={selectmenu === 'Chatting' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Chatting')
              //setleftmodalvisible('Chatting')
              history.push(`/chat${location.search}`)
              console.log(selectmenu)
            }}>
              <IconFont type="icon-chat" />
              <p className='icon_fs'>채팅</p>
            </div>

            <div className={selectmenu === 'Contact' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Contact')
              //setleftmodalvisible('Contact')
              history.push(`/contact${location.search}`)
              console.log(selectmenu)
            }}>
              <UserAddOutlined />
              <p className='icon_fs'>연락처</p>
            </div>
            <div className={selectmenu === 'Group' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Group')
              //setleftmodalvisible('Group')
              history.push(`/group${location.search}`)
              //console.log(selectmenu)
            }}>
              <UsergroupAddOutlined />
              <p className='icon_fs'>조직도</p>
            </div>

            <div className={selectmenu === 'Call' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('Call')
              //setleftmodalvisible('Call')
              history.push(`/call${location.search}`)
              console.log(selectmenu)
            }}>
              <WhatsAppOutlined />
              <p className='icon_fs'>통화</p>
            </div>

            <div className={selectmenu === 'File' ? 'selectedmenu' : ''} onClick={() => {
              //setselectmenu('File')
              //setleftmodalvisible('File')
              history.push(`/file${location.search}`)
              console.log(selectmenu)
            }}>
              <FileDoneOutlined />
              <p className='icon_fs'>파일</p>
            </div>

          </div>

          <div className='side-setting-icon'>
            <div>
              <IconFont type="white-mode" />
              {/* <p className='icon_fs'>화이트모드</p> */}
            </div>
            <div className='setline' onClick={()=>{history.push(`/setting${location.search}`)}}>
              <SettingOutlined />
              {/* <p className='icon_fs'>설정</p> */}
            </div>
            <div>
              <LogoutOutlined className='logout_btn'/>
            </div>
          </div>
        </div>

      </div>



      {/** chatisvisible이 false일 때 viewport-chat-hidden css적용*/}
      {/* <div className={classnames('viewport', { 'viewport-chat-hidden': !(chatvisible || chatinfovisible) })}> */}
      {/* <div
          className={classnames('viewport', {
            'mobile-viewport': isSharing
          })}> */}
          {/* <div
          className={classnames(!isSharing ? 'viewport' : 'viewport mobile-viewport')}> */}
          <div className={classnames('viewport')}>

            

      {/* fill screen */}
      <div className='full-screen-icon' >
          <IconFont type='full-screen' style={{cursor: "pointer"}}/> 
      </div>
        {/* left modal */}
        {(!(leftmodalvisible === '') && (leftmodalvisible === selectmenu)) ?
          <div className='left_modal'>
            <div className='flex_style left_modal_title'>
              <h3>{selectmenu}</h3>
              <CloseOutlined onClick={() => {
                setleftmodalvisible('')
                setselectmenu('')
              }} />
            </div>
            {renderComponent(selectmenu)}
          </div>
          : null}

        <div
          className={classnames('share-container', {
            'in-sharing': isSharing
          })}
          ref={shareContainerRef}
        >
          
          <div
            className="share-container-viewport"
            style={{
              width: `${shareViewDimension.width}px`,
              height: `${shareViewDimension.height}px`
            }}
          >
            <canvas className={classnames('share-canvas', { hidden: isStartedShare })}  ref={shareRef} />
            {mediaStream?.isStartShareScreenWithVideoElement() ? (
              <video
                className={classnames('share-canvas', {
                  hidden: isRecieveSharing
                })}
                ref={selfShareRef}
              />
            ) : (
              <canvas
                className={classnames('share-canvas', {
                  hidden: isRecieveSharing
                })}
                ref={selfShareRef}
              />
            )}
          </div>
        </div>


        <div
          className={classnames('video-container', {
            'in-sharing': isSharing
          })}
        >
            
          
          <canvas className="video-canvas" id="video-canvas" width="800" height="600" ref={videoRef} />
          <ul className="avatar-list">
            {
            isSharing && isMobile ?
            ""
            :
            visibleParticipants.map((user, index) => {
              if (index > videoLayout.length - 1) {
                return null;
              }
              const dimension = videoLayout[index];
              const { width, height, x, y } = dimension;
              const { height: canvasHeight } = canvasDimension;
              return (
                <Avatar
                  participant={user}
                  key={user.userId}
                  isActive={activeVideo === user.userId}
                  volume={userVolumeList.find((u) => u.userId === user.userId)?.volume}
                  setLocalVolume={setLocalVolume}
                  advancedFeature={advancedSwitch[`${user.userId}`]}
                  onAdvancedFeatureToggle={onAdvancedFeatureToggle}
                  isUserCameraControlled={isControllingFarEnd}
                  networkQuality={networkQuality[`${user.userId}`]}
                  style={{
                    width: `${width - 10}px`,
                    height: `${height - 1}px`,
                    top: `${canvasHeight - y - height}px`,
                    left: `${x + 5}px`
                  }}
                />
              );
            })
          }
          </ul>
        </div>
        <VideoFooter className="video-operations" sharing shareRef={selfShareRef} chatvisibleCallback={chatvisiblehandleCallback} chatinfovisibleCallback={chatinfovisiblehandleCallback} />

        {isControllingFarEnd && (
          <RemoteControlPanel
            turnDown={turnDown}
            turnLeft={turnLeft}
            turnRight={turnRight}
            turnUp={turnUp}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            switchCamera={switchCamera}
            controlledName={currentControlledUser.displayName}
          />
        )}
        {isInControl && <RemoteControlIndication stopCameraControl={stopControl} />}
        {totalPage > 1 && <Pagination page={page} totalPage={totalPage} setPage={setPage} inSharing={isSharing} />}
      </div>


      {/* 채팅 */}
      {/* 채팅 열고 */}
      {chatvisible ? (
        <div className='chat-sidebar ts-dropdown-content accordionfix chat-wrap video_style2' >
          {/* 제목 */}
          {/* <div className='chat_title'>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconFont type="icon-chat" style={{ fontSize: "18px", color: "#6571ff" }} />
              <span style={{ fontSize: "18px", margin: "0 0 0 6px", color: "#64748b", fontWeight: "500" }}> Chat </span>
            </div> */}

          {/* 채팅 닫기 */}
          {/* <div className='chat-close' onClick={() => {
              setchatvisible(!chatvisible)
            }}>
              <RightOutlined />
            </div>
          </div> */}


          {/* 제목 */}
          <div className="chat-header relative z-10 flex w-full shrink-0 items-center justify-between border-b border-slate-150 bg-white px-[calc(var(--margin-x)-.5rem)] shadow-sm transition-[padding,width] duration-[.25s] dark:border-navy-700 dark:bg-navy-800 chat-head-icon">
            <div className="flex items-center space-x-5" style={{ marginLeft: "15px" }}>

              {/* <!-- 메뉴로 가는 버튼 --> */}
              {/* <div className="ml-1 mr-2 h-7 w-7 sidebar_button">
              <button className="menu-toggle ml-0.5 flex h-7 w-7 flex-col justify-center space-y-1.5 text-primary outline-none focus:outline-none dark:text-accent-light/80">
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div> */}

              <div className="flex items-center space-x-4 font-inter">
                {/* <div className="avatar2">
                <div className='fs-17 flex h-8 w-8 items-center justify-center rounded-full text-white dark:bg-accent alarm-name pl-10' style={{ background: "#0dbd8b" }}>송</div>
              </div>
              <div style={{ textAlign: "left" }}>
                <p className="font-medium text-slate-700 line-clamp-1 dark:text-navy-100" x-text="activeChat.name">송대한</p>
                <p className="mt-0.5 text-xs">Last seen recently</p>
              </div> */}
                <IconFont type="icon-chat" style={{ fontSize: "18px", color: "#6571ff" }} />
                <span style={{ fontSize: "18px", margin: "0 0 0 6px", color: "#64748b", fontWeight: "500" }}> Chat</span>
              </div>
            </div>
            <div className="-mr-1 flex items-center">
              {/* <button className="btn hidden h-9 w-9 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:flex">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z">
                </path>
              </svg>
            </button>
            <button className="btn hidden h-9 w-9 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:flex">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z">
                </path>
              </svg>
            </button> */}
              <button className="btn h-9 w-9 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z">
                  </path>
                </svg>
              </button>

              {/* <button className="btn hidden h-9 w-9 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:flex text-slate-500 dark:text-navy-200 text-primary dark:text-accent-light">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9.25 21.167h5.5c4.584 0 6.417-1.834 6.417-6.417v-5.5c0-4.583-1.834-6.417-6.417-6.417h-5.5c-4.583 0-6.417 1.834-6.417 6.417v5.5c0 4.583 1.834 6.417 6.417 6.417ZM13.834 2.833v18.334">
                </path>
              </svg>
            </button> */}

              {/* 채팅 닫기 */}
              <div className='chat-close' onClick={() => {
                setchatvisible(!chatvisible)
              }}>
                <RightOutlined />
              </div>

              <div x-data="usePopper({placement:'bottom-end',offset:4})" className="inline-flex">
                <button x-ref="popperRef" className="btn h-9 w-9 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 text-slate-500">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z">
                    </path>
                  </svg>
                </button>

                {/* 서브메뉴 */}
                {/* <div x-ref="popperRoot" className="popper-root" style={{ position: "fixed", inset: "0px 0px auto auto", margin: "0px", transform: "translate(0px, 52px)" }} data-popper-placement="bottom-end">
                <div className="popper-box rounded-md border border-slate-150 bg-white py-1.5 font-inter dark:border-navy-500 dark:bg-navy-700">
                  <ul>
                    <li>
                      <a href="#" className="flex h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100">
                        <svg xmlns="http://www.w3.org/2000/svg" className="mt-px h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" clip-rule="evenodd"></path>
                          <path stroke-linecap="round" stroke-linejoin="round" d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2">
                          </path>
                        </svg>
                        <span>Unmute</span></a>
                    </li>
                    <li>
                      <a href="#" className="flex h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100">
                        <svg xmlns="http://www.w3.org/2000/svg" className="mt-px h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z">
                          </path>
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z">
                          </path>
                        </svg>
                        <span>Chat Setting</span></a>
                    </li>
                    <li>
                      <a href="#" className="flex h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100">
                        <svg xmlns="http://www.w3.org/2000/svg" className="mt-px h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636">
                          </path>
                        </svg>
                        <span>Block User</span></a>
                    </li>
                    <li>
                      <a href="#" className="flex h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide text-error outline-none transition-all hover:bg-error/20 focus:bg-error/20">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16">
                          </path>
                        </svg>
                        <span> Delete chat</span></a>
                    </li>
                  </ul>
                </div>
              </div> */}
              </div>
            </div>
          </div>

          {/* 채팅본문 */}
          {/*<ChatContainer></ChatContainer>*/}
          <div className='ts-dropdown-content' style={{ height: "93%" }} ref={chatWrapRef}>
            {chatRecords.map((record) => (
              <ChatMessageItem
                record={record}
                currentUserId={currentUserId}
                setChatUser={setChatUserId}
                key={record.timestamp}
              />
            ))}
          </div>


          {/* 채팅입력 */}
          <div className='chat-message-input'>
            {ChatPrivilege.NoOne !== chatPrivilege || isHost || isManager ? (
              <>
                {/*}
              <div style={{ display: 'flex', justifyContent: "space-between" }}>
                <ChatReceiverContainer
                  chatUsers={chatReceivers}
                  selectedChatUser={chatUser}
                  isHostOrManager={isHost || isManager}
                  chatPrivilege={chatPrivilege}
                  setChatUser={setChatUserId}
                />
          
              </div>
              */}
                <div className="chat-message-box" style={{ display: "flex", height: "55px" }}>
                  <div className='chat-icon' onClick={() => {
                    setfilevisible(!filevisible)
                  }}>
                    <PaperClipOutlined />
                  </div>

                  {/* 파일첨부 모달 영역 */}
                  <div className={classnames('file-modal', { 'file-modal-none': !filevisible, 'chatfile-modal': true })}>
                    <Form
                      {...formItemLayout}
                      layout={formLayout}
                      form={form}
                      initialValues={{ layout: formLayout }}
                      onValuesChange={onFormLayoutChange}
                      style={{ maxWidth: formLayout === 'inline' ? 'none' : 600 }}
                    >
                      {/* <Form.Item label="Form Layout" name="layout">
                    <Radio.Group value={formLayout}>
                      <Radio.Button value="horizontal">Horizontal</Radio.Button>
                      <Radio.Button value="vertical">Vertical</Radio.Button>
                      <Radio.Button value="inline">Inline</Radio.Button>
                    </Radio.Group>
                  </Form.Item> */}

                      <Form.Item label="File A">
                        <Input placeholder="input placeholder" />
                      </Form.Item>
                      <Form.Item label="File B">
                        <Input placeholder="input placeholder" />
                      </Form.Item>
                      <Form.Item {...buttonItemLayout}>
                        <Button type="primary" className='filesendbtn'>Submit</Button>
                      </Form.Item>
                    </Form>
                  </div>



                  {/* 채팅 Area 주석 */}
                  {/* <TextArea
                  onKeyPress={onShiftEnter}
                  onPressEnter={sendMessage}
                  onChange={onChatInput}
                  value={chatDraft}
                  placeholder="Type message here ..."
                  className='textareaStyle'
                /> */}

                  <div className="videoInputContainer">
                    <EmojiPicker
                      onEnterData={sendMessage}
                      chgChatData={setChatDraft}
                      chatData={chatDraft}
                    />
                  </div>

                  {/* <div className='chat-icon'>
                  <SmileOutlined /> 
                  <div className='emogi-modal'>
                  </div>
                </div> */}


                  <div className='sendBtn' onClick={sendMessage}><SendOutlined /></div>
                </div>
              </>
            ) : (
              <div className="chat-disabled">Chat disabled</div>
            )}
          </div>

          {/* <div style={{ position: "sticky", bottom: "0", left: "0"}}>
            <div style={{ display: 'flex', justifyContent: "flex-end", background: "#fff"}}> */}
          {/* <ChatReceiverContainer
                  chatUsers={chatReceivers}
                  selectedChatUser={chatUser}
                  isHostOrManager={isHost || isManager}
                  chatPrivilege={chatPrivilege}
                  setChatUser={setChatUserId}
                /> */}
          {/* <div className='chat-icon' style={{ paddingBottom: "5px" }}>
                <SmileOutlined />
                <SettingOutlined />
              </div>
            </div>
            <div className='textarea-wrap' style={{ borderTop: "1px solid rgb(233, 238, 245)", display: "flex"}}>
              <TextArea
                onPressEnter={sendMessage}
                onChange={onChatInput}
                value={chatDraft}
                placeholder="Type message here ..."
                className='text_style'
              />
              <div className="sendoutline" onClick={sendMessage} ><SendOutlined /></div>
            </div>
          </div> */}
        </div>
      ) : null
        /*열기버튼
        <div style={{ cursor: "pointer", padding: "5px", fontSize: "15px" }} onClick={() => {
          setchatvisible(!chatvisible)
        }}>
          <LeftOutlined />
        </div>
        */
      }
      {/* 프리뷰,통화모달 */}
      <CallModal isOpen={callModalOpen} onOk={handleOk} onCancel={handleCancel} currentUsername={currentUserinfo}>
      </CallModal>
    
      <Modal className='preview-modal' title="" open={prevwModalOpen} onOk={handlePrevwOk} onCancel={handlePrevwCancel}>
        <div className="text-center p-4" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
          <Preview history={props.history} location={props.location} match={props.match} TargetTopic={TargetTopic} ></Preview>
        </div>
      </Modal>

      {/* 채팅info 모달영역*/}
      {chatinfovisible ?
        <div className='chatinfo-sidebar ts-dropdown-content accordionfix chat-wrap video_style2' >
          {/* 제목 */}
          <div className='chatinfo_title'>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ fontSize: "18px", margin: "0 0 0 6px", color: "#3f4a5b", fontWeight: "500" }}> Chat Info </span>
            </div>

            <div className='chat-head-icon' style={{ display: "flex" }}>
              <button className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5 text-slate-500 dark:text-navy-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ color: "#3f4a5b" }}>
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z">
                  </path>
                </svg>
              </button>
              {/* 채팅 닫기 */}
              <div style={{ cursor: "pointer", padding: "5px", fontSize: "15px" }} onClick={() => {
                setchatinfovisible(!chatinfovisible)
                console.log(chatinfovisible)

              }}>
                <RightOutlined />
              </div>
            </div>


          </div>

          {/* 채팅 인포 */}
          {/*<ChatContainer></ChatContainer>*/}
          <div className='ts-dropdown-content' style={{ height: "80%" }} ref={chatWrapRef}>
            {currentUserinfo ?
              <div className="flex flex-col items-center" style={{ marginTop: "2.25rem" }}>
                <div className="avatar-chat h-20 w-20">
                  {/* <img className="rounded-full" src={initialImage} alt="avatar" /> */}
                  <div className='fs-17 flex h-8 w-8 items-center justify-center rounded-full text-white dark:bg-accent chatinfo-name' style={{ background: "#0dbd8b" }}>{currentUserinfo[0]}</div>
                </div>
                <h3 x-text="activeChat.name" className="mt-2 text-lg font-medium text-slate-700 dark:text-navy-100">{currentUserinfo}</h3>
                <p>Frontend Developer</p>
                <div className="mt-2 flex space-x-1.5 chat-info-btn">
                  <button onClick={showPrevwModal} className="btn h-10 w-10 rounded-full p-0 text-slate-600 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z">
                      </path>
                    </svg>
                  </button>
                  <button onClick={showcallModal} className="btn h-10 w-10 rounded-full p-0 text-slate-600 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z">
                      </path>
                    </svg>
                  </button>
                  <button className="btn h-10 w-10 rounded-full p-0 text-slate-600 hover:bg-slate-300/20 hover:text-primary focus:bg-slate-300/20 focus:text-primary active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20 dark:hover:text-accent dark:focus:bg-navy-300/20 dark:focus:text-accent dark:active:bg-navy-300/25">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z">
                      </path>
                    </svg>
                  </button>
                </div>
              </div>
              : null}


            {/* 이미지 파일 유저리스트 탭 */}
            <div>
              <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>
          </div>
        </div> : null}
    </div>


  )
};

export default VideoContainer;
