import React from 'react';

const formatDate = (dateStr: string) => {
  const now = new Date();
  const date = new Date(dateStr);

  const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: '2-digit', hour12: true };
  const timeStr = date.toLocaleTimeString('ko-KR', timeOptions);

  const diffInDays = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

  const dateOptions: Intl.DateTimeFormatOptions = { year: '2-digit', month: '2-digit', day: '2-digit' };
  const dateStrFormatted = date.toLocaleDateString('ko-KR', dateOptions);

  if (diffInDays === 0) {
    const dayOptions: Intl.DateTimeFormatOptions = { weekday: 'long' };
    const dayStr = date.toLocaleDateString('ko-KR', dayOptions);
    return `${timeStr}`;
  } else if (diffInDays === 1) {
    const dayOptions: Intl.DateTimeFormatOptions = { weekday: 'long' };
    const dayStr = date.toLocaleDateString('ko-KR', dayOptions);
    return `어제`;
  } else if (diffInDays >= 2) {
    const dayOptions: Intl.DateTimeFormatOptions = { weekday: 'long' };
    const dayStr = date.toLocaleDateString('ko-KR', dayOptions);
    return `${dayStr}`;
  } else {
    return dateStrFormatted;
  }
};

interface FormattedDateProps {
  dateStr: string;
}

function FormattedDate({ dateStr }: FormattedDateProps) {
  const formattedDate = formatDate(dateStr);

  return (
    <div>
      <p>{formattedDate}</p>
    </div>
  );
}

export default FormattedDate;