import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Tree } from 'antd';
import type { DataNode, TreeProps } from 'antd/es/tree';
import { RouteComponentProps, useLocation, Link, useHistory, } from 'react-router-dom';
import { UserAddOutlined, WhatsAppOutlined, FileDoneOutlined, UsergroupAddOutlined, RightOutlined, SendOutlined, LeftOutlined, CloseOutlined, BellOutlined, PaperClipOutlined } from '@ant-design/icons';
import axios from 'axios';
const treeData: DataNode[] = [
    {
        title: '부서',
        key: '0-0',
        children: [
            {
                title: '부서1',
                key: '0-0-0',
                children: [
                    {
                        title: '홍길동',
                        key: '0-0-0-0',
                    },
                    {
                        title: '홍길동',
                        key: '0-0-0-1',
                    },
                    {
                        title: '홍길동',
                        key: '0-0-0-2',
                    },
                ],
            },
            {
                title: '부서2',
                key: '0-0-1',
                children: [
                    {
                        title: '홍길동',
                        key: '0-0-1-0',
                    },
                ],
            },
            {
                title: '부서3',
                key: '0-0-2',
                children: [
                    {
                        title: '홍길동',
                        key: '0-0-2-0',
                    },
                    {
                        title: '홍길동',
                        key: '0-0-2-1',
                    },
                ],
            },
        ],
    },
];
console.log('페이지 이동됨')
const Groupmodal = () => {


    return (
        <div></div>
    );
};
export default Groupmodal;