import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import { RouteComponentProps, useLocation, Link, useHistory, } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { UserAddOutlined, WhatsAppOutlined, FileDoneOutlined, UsergroupAddOutlined, SettingOutlined, RightOutlined, SendOutlined, LeftOutlined, CloseOutlined, BellOutlined, PaperClipOutlined, TeamOutlined, DownloadOutlined, FolderOutlined, PlusOutlined, LogoutOutlined } from '@ant-design/icons';

import Alarm from '../components/alarm';
import Contact from '../components/contact';
import File from '../components/file';
import Call from '../components/call'
import Chatting from '../components/chatting';
import Group from '../components/group';
import classNames from 'classnames';
import './setting.scss';

import { IconFont } from '../../../component/icon-font';
import logoImage from '../../../component/svgs/circlelogo.png';
import { Button, Form, Input, InputNumber, Tree } from 'antd';
import type { DataNode, DirectoryTreeProps } from 'antd/es/tree';
import { Modal } from 'antd';
const { DirectoryTree } = Tree;


const treeData: DataNode[] = [
    {
        title: '내 파일',
        key: '0-0',
        children: [
            {
                title: (
                    <div className='tree-fill-wrap'>
                        <span style={{ marginRight: '8px' }}>내 파일 1</span>
                        <DownloadOutlined className='file-downicon' />
                    </div>), key: '0-0-0', isLeaf: true
            },
            {
                title: (
                    <div className='tree-fill-wrap'>
                        <span style={{ marginRight: '8px' }}>내 파일 2</span>
                        <DownloadOutlined className='file-downicon' />
                    </div>), key: '0-0-1', isLeaf: true
            },
        ],
        icon: <FolderOutlined style={{ fontSize: '19px', color: '#3f4a5b' }} />,
    },
    {
        title: '공유',
        key: '0-1',
        children: [
            {
                title: (
                    <div className='tree-fill-wrap'>
                        <span style={{ marginRight: '8px' }}>공유 파일 1</span>
                        <DownloadOutlined className='file-downicon' />
                    </div>), key: '0-1-0', isLeaf: true
            },
            {
                title: (
                    <div className='tree-fill-wrap'>
                        <span style={{ marginRight: '8px' }}>공유 파일 2</span>
                        <DownloadOutlined className='file-downicon' />
                    </div>), key: '0-1-1', isLeaf: true
            },
        ],
        icon: <TeamOutlined style={{ fontSize: '19px', color: '#3f4a5b' }} />
    },

];
interface HomeProps extends RouteComponentProps {
    //subsession에서 에러안나도록 ?처리
    status?: string;
    onLeaveOrJoinSession?: () => void;
}
const Setting: React.FunctionComponent<RouteComponentProps & HomeProps> = (props) => {
    const history = useHistory();
    const location = useLocation();
    const [selectmenu, setselectmenu] = useState('')
    const [leftmodalvisible, setleftmodalvisible] = useState('');

    // siderbar 출력
    const [siderbar, setsiderbar] = useState(true)

    const [isMobile, setIsMobile] = useState(false);
    // 모바일 여부 확인
    useEffect(() => {
        const checkIsMobile = () => {
            const userAgent = navigator.userAgent.toLowerCase();
            setIsMobile(/mobile|android|iphone|ipad|webos|blackberry|windows phone/i.test(userAgent));
        };

        checkIsMobile(); // 최초 렌더링 시 기기 확인

        // 리사이징 시에도 기기 확인
        window.addEventListener('resize', checkIsMobile);

        // 컴포넌트 언마운트 시 리스너 제거
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);
    //컴포넌트출력함수
    const renderComponent = (type: string) => {
        switch (type) {
            case 'Feed':
                return <Alarm history={props.history} location={props.location} match={props.match} />;
            case 'Contact':
                return <Contact history={props.history} location={props.location} match={props.match} />;
            case 'Chatting':
                return <Chatting />;
            case 'Group':
                return <Group history={props.history} location={props.location} match={props.match} />;
            case 'Call':
                return <Call history={props.history} location={props.location} match={props.match} />;
            case 'File':
                return <File history={props.history} location={props.location} match={props.match} />;
                ;
            default:
                return null;
        }
    };

    const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
        console.log('Trigger Select', keys, info);
    };

    const onExpand: DirectoryTreeProps['onExpand'] = (keys, info) => {
        console.log('Trigger Expand', keys, info);
    };

    //유저 정보 모달
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const showCreateModal = () => {
        setCreateModalOpen(true);
    };
    const handleCreateOk = () => {
        setCreateModalOpen(false);
    };
    const handleCreateCancel = () => {
        setCreateModalOpen(false);
    };
    const onFinish = (values: any) => {
        console.log(values);
    };
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        // required: '${label} is required!',
        required: '이름을 입력해주세요',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    return (
        <div>
            {siderbar ?
                <div className='main-sidebar ts-dropdown-content accordionfix video_style1'>

                    {/* 로고 */}
                    <div className='left_logo'>
                        <a href="#" style={{ marginTop: "0" }}>
                            <img src={logoImage} style={{ width: "30px", height: "30px" }} onClick={() => history.push(`/${location.search}`)} />
                        </a>
                    </div>
                    <div className='side-icon' style={{ marginTop: "80px" }}>
                        {/* lfet icon */}
                        <div className={selectmenu === 'Feed' ? 'selectedmenu' : ''} onClick={() => {
                            //setselectmenu('Feed')
                            //setleftmodalvisible('Feed')
                            history.push(`/alarm${location.search}`)
                            console.log(selectmenu)
                        }}>
                            <BellOutlined />
                            <p className='icon_fs'>활동</p>
                        </div>

                        <div className={selectmenu === 'Chatting' ? 'selectedmenu' : ''} onClick={() => {
                            //setselectmenu('Chatting')
                            //setleftmodalvisible('Chatting')
                            history.push(`/chat${location.search}`)
                            console.log(selectmenu)
                        }}>
                            <IconFont type="icon-chat" />
                            <p className='icon_fs'>채팅</p>
                        </div>

                        <div className={selectmenu === 'Contact' ? 'selectedmenu' : ''} onClick={() => {
                            //setselectmenu('Contact')
                            //setleftmodalvisible('Contact')
                            history.push(`/contact${location.search}`)
                            console.log(selectmenu)
                        }}>
                            <UserAddOutlined />
                            <p className='icon_fs'>연락처</p>
                        </div>
                        <div className={selectmenu === 'Group' ? 'selectedmenu' : ''} onClick={() => {
                            //setselectmenu('Group')
                            //setleftmodalvisible('Group')
                            history.push(`/group${location.search}`)
                            //console.log(selectmenu)
                        }}>
                            <UsergroupAddOutlined />
                            <p className='icon_fs'>조직도</p>
                        </div>

                        <div className={selectmenu === 'Call' ? 'selectedmenu' : ''} onClick={() => {
                            //setselectmenu('Call')
                            //setleftmodalvisible('Call')
                            history.push(`/call${location.search}`)
                            console.log(selectmenu)
                        }}>
                            <WhatsAppOutlined />
                            <p className='icon_fs'>통화</p>
                        </div>

                        <div className={selectmenu === 'File' ? 'selectedmenu' : ''} onClick={() => {
                            setselectmenu('File')
                            setleftmodalvisible('File')
                            history.push(`/file${location.search}`)
                            console.log(selectmenu)
                        }}>
                            <FileDoneOutlined />
                            <p className='icon_fs'>파일</p>
                        </div>

                    </div>

                    <div className='side-setting-icon'>
                        <div className='whitemode'>
                            <a><IconFont type="white-mode" /></a>
                            {/* <p className='icon_fs'>화이트모드</p> */}
                        </div>

                        <div className='setline' onClick={() => { history.push(`/setting${location.search}`) }}>
                            <SettingOutlined />
                            {/* <p className='icon_fs'>설정</p> */}
                        </div>

                        <div>
                            <a href="/"><LogoutOutlined className='logout_btn'/></a>
                            {/* <p className='icon_fs'>로그아웃</p> */}
                        </div>
                    </div>
                </div> : null}

            <div>
                <div x-data="{expanded:true}" style={
                    siderbar ? { position: "relative" } : { position: "relative", marginLeft: "10px" }
                } className={classNames('file-tree-content', { 'file-tree-hidden': !leftmodalvisible })}>
                    {/* 헤더 */}
                    <div className='header-nav'>
                        <div className='header-name'>
                            <h3 style={{ fontSize: "16px" }}>Setting</h3>
                        </div>
                        <div className='file-header-btn'>
                            {/* <button className="btn h-9 w-9 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z">
                                    </path>
                                </svg>
                            </button> */}
                            {/* <button className="btn hidden h-9 w-9 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 sm:flex text-slate-500 dark:text-navy-200 text-primary dark:text-accent-light">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.25 21.167h5.5c4.584 0 6.417-1.834 6.417-6.417v-5.5c0-4.583-1.834-6.417-6.417-6.417h-5.5c-4.583 0-6.417 1.834-6.417 6.417v5.5c0 4.583 1.834 6.417 6.417 6.417ZM13.834 2.833v18.334">
                                    </path>
                                </svg>
                            </button> */}
                            {/* <button x-ref="popperRef" className="btn h-9 w-9 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 text-slate-500">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z">
                                    </path>
                                </svg>
                            </button> */}
                            <button onClick={() => {
                                //siderbar 닫기
                                setsiderbar(!siderbar)
                                setleftmodalvisible('')
                                setselectmenu('')
                            }} className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5.5 w-5.5 text-slate-500 dark:text-navy-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                                </svg>
                            </button>
                        </div>

                    </div>
                    <div className='setting-wrap'>
                        {
                            isMobile ?
                                <div style={{ width: "calc(100% - 30px)", marginTop: "50px" }}>
                                    <Form
                                        {...layout}
                                        name="nest-messages"
                                        onFinish={onFinish}
                                        style={{ maxWidth: 600 }}
                                        validateMessages={validateMessages}
                                    >
                                        <Form.Item name={[`${sessionStorage.getItem('name')}`, 'name']} label="이름 " 
                                        // rules={[{ required: true }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={[`${sessionStorage.getItem('name')}`, 'companyname']} label="회사 ">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={[`${sessionStorage.getItem('name')}`, 'partname']} label="부서 ">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={[`${sessionStorage.getItem('name')}`, 'email']} label="이메일 " rules={[{ type: 'email' }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={[`${sessionStorage.getItem('name')}`, "phone"]} label="전화번호 "
                                            // rules={[
                                            //     {
                                            //         required: true,
                                            //         // message: 'Please input your phone number!',
                                            //         message: '전화번호를 입력해주세요',
                                            //     },
                                            // ]}
                                        >
                                            <Input style={{ width: '100%', }} />
                                        </Form.Item>
                                        <Form.Item name={[`${sessionStorage.getItem('name')}`, 'description']} label="직책 ">
                                            {/* <Input.TextArea /> */}
                                            <Input />
                                        </Form.Item>
                                        <div className='setting-btn' style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                                <Button htmlType="submit" className='ant-btn ant-btn-default' style={{ marginRight: "5px", marginTop: "15px" }}>
                                                    취소
                                                </Button>
                                            </Form.Item>
                                            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                                <Button type="primary" htmlType="submit" className='ant-btn ant-btn-primary filesendbtn'>
                                                    업데이트
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </div>

                                :
                                <Form
                                    {...layout}
                                    name="nest-messages"
                                    onFinish={onFinish}
                                    style={{ width: "600px" }}
                                    validateMessages={validateMessages}
                                >
                                    <Form.Item name={[`${sessionStorage.getItem('name')}`, 'name']} label="이름 " 
                                    // rules={[{ required: true }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name={[`${sessionStorage.getItem('name')}`, 'companyname']} label="회사 ">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name={[`${sessionStorage.getItem('name')}`, 'partname']} label="부서 ">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name={[`${sessionStorage.getItem('name')}`, 'email']} label="이메일 " rules={[{ type: 'email' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name={[`${sessionStorage.getItem('name')}`, "phone"]} label="전화번호 "
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         // message: 'Please input your phone number!',
                                        //         message: '전화번호를 입력해주세요',
                                        //     },
                                        // ]}
                                    >
                                        <Input style={{ width: '100%', }} />
                                    </Form.Item>
                                    <Form.Item name={[`${sessionStorage.getItem('name')}`, 'description']} label="직책 ">
                                        {/* <Input.TextArea /> */}
                                        <Input />
                                    </Form.Item>
                                    <div className='setting-btn' style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                            <Button htmlType="submit" className='ant-btn ant-btn-default' style={{ marginRight: "5px", marginTop: "15px" }}>
                                                취소
                                            </Button>
                                        </Form.Item>
                                        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                            <Button type="primary" htmlType="submit" className='ant-btn ant-btn-primary filesendbtn'>
                                                업데이트
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Form>



                        }
                    </div>

                </div >

            </div>
            {/* left modal  */}
            {(!(leftmodalvisible === '') && (leftmodalvisible === selectmenu)) ?
                <div className='left_modal group-left'>
                    <div className='flex_style left_modal_title'>
                        <h3>{selectmenu}</h3>
                        <CloseOutlined onClick={() => {
                            setleftmodalvisible('')
                            setselectmenu('')
                        }} />
                    </div>
                    {renderComponent(selectmenu)}
                </div>
                : null}

            {/* 생성 모달  */}
            <Modal className='file-create-modal' title="" open={createModalOpen} onOk={handleCreateOk} onCancel={handleCreateCancel}>
                <div>

                </div>
            </Modal>
        </div>
    );
};

export default Setting;